import VideoItem from './VideoItem'
import { VideoSummaryResponseItem } from '../api/types'
import { Link } from 'react-router-dom'
import SpinnerBlock from './SpinnerWithBox'
import { Card, Grid, Typography } from '@mui/joy'

interface Props {
  showChannelAvatar: boolean
  videos: VideoSummaryResponseItem[] | undefined
  onClickVideo?: (e: React.UIEvent, videoId: number) => void
}

const VideoPlaylist = ({ showChannelAvatar, videos, onClickVideo }: Props) => {
  if (!videos) {
    return <SpinnerBlock height={'200px'} />
  }
  if (videos.length === 0) {
    return <Typography level="body-md">영상이 존재하지 않습니다.</Typography>
  }

  return (
    <Grid container spacing={2}>
      {videos.map(item => {
        return (
          <Grid key={item.videoId} sm={12} md={4} lg={3}>
            <VideoItem
              video={item}
              showChannelAvatar={showChannelAvatar}
              onClickVideo={onClickVideo}
            />
          </Grid>
        )
      })}
    </Grid>
  )
}

export default VideoPlaylist
