import {useEffect} from 'react'
import TagManager from 'react-gtm-module'

const GoogleTagManager = () => {
  useEffect(() => {
    if (!process.env.REACT_APP_GTM_ID) {
      return
    }

    TagManager.initialize({
      gtmId: process.env.REACT_APP_GTM_ID,
    })
  }, [])

  return <></>
}

export default GoogleTagManager
