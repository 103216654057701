import { Link } from 'react-router-dom'
import { Dot } from '../component/VideoItem'
import { Box, Stack, Typography } from '@mui/joy'

interface Props {
  style?: React.CSSProperties
}

function HomeFooter({ style }: Props) {
  return (
    <footer
      style={{
        width: '100%',
        maxWidth: 'var(--layout-width-wide)',
        margin: '4rem auto 0',
        padding: '3rem var(--grid-margin) 5rem',
        borderTop: 'var(--border)',
        ...style,
      }}
    >
      <div style={{ display: 'flex', gap: '1rem', margin: '0 0 2rem' }}>
        <Link to="/terms-of-service" style={{ color: 'var(--color-text)' }}>
          이용약관
        </Link>
        <Link to="/privacy-policy" style={{ color: 'var(--color-text)' }}>
          {/* 개인정보처리방침만 강조해야 함. */}
          <strong>개인정보처리방침</strong>
        </Link>
      </div>
      <Stack direction={'row'}>
        <Typography level={'body-sm'}>푸릇푸릇</Typography>
        <Typography level={'body-sm'}>
          <Dot />
        </Typography>
        <Typography level={'body-sm'}>대표 이승환</Typography>
        <Typography level={'body-sm'}>
          <Dot />
        </Typography>
        <Typography level={'body-sm'}>경기 수원시 영통구 도청로18번길 26, 2049호</Typography>
      </Stack>
      <Stack direction={'row'}>
        <Stack direction={'row'} gap={1}>
          <Typography level={'body-sm'}>사업자등록번호 278-24-01710</Typography>
          <Typography level={'body-sm'}>
            <a href={'https://www.ftc.go.kr/bizCommPop.do?wrkr_no=2782401710'} target="_blank">
              사업자정보확인
            </a>
          </Typography>
        </Stack>
      </Stack>
      <Typography level={'body-sm'}>통신판매업신고번호 2024-수원영통-1029</Typography>
      <Box mt={'2rem'}>
        <small>© 2024 COLLABLE. All rights reserved.</small>
      </Box>
    </footer>
  )
}

export default HomeFooter
