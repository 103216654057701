const ChannelIconLabelSvg = ({
  x,
  y,
  width,
  thumbnailUrl,
}: {
  x: number
  y: number
  width: number
  thumbnailUrl: string
}) => {
  const radius = 12
  return (
    <g transform={`translate(${x + width / 2},${y - radius})`}>
      <defs>
        <clipPath id="circleView">
          <circle cx={0} cy={radius} r={radius} />
        </clipPath>
      </defs>
      <image
        href={thumbnailUrl}
        x={-radius}
        y={0}
        height={radius * 2}
        width={radius * 2}
        clipPath="url(#circleView)"
      />
    </g>
  )
}

export default ChannelIconLabelSvg
