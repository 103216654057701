import { EuiHeader, EuiHeaderSectionItem, EuiShowFor } from '@elastic/eui'
import { Link, useNavigate } from 'react-router-dom'
import Logo from 'component/Logo'
import LoginAuthHelper from '../util/LoginAuthHelper'
import Button from 'component/button/Button'
import { useEffect, useState } from 'react'
import { IconMoonStars, IconSun, IconUserCircle } from '@tabler/icons-react'
import Group from 'component/Group'
import { useSignModalContext } from '../context/SignModalContext'
import { useLocalStorage } from '../hooks/useLocalStorage'
import MobileHeaderRightSide from '../component/MobileHeaderRightSide'

export const headerMenus = [
  { label: '인기PPL', href: '/videos' },
  { label: '채널', href: '/channels' },
  { label: '채널 비교', href: '/channel-compare' },
  // { label: '샤라웃', href: '/shoutout' },
  { label: '브랜드', href: '/brands' },
  { label: '컬렉션', href: '/collections' },
]

const HomeHeader = () => {
  const navigate = useNavigate()
  const [scrolled, setScrolled] = useState(window.scrollY > 0)

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 0)
    }

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  const role = LoginAuthHelper.getRoles()
  const isYoutuber = role === 'INFLUENCER'

  return (
    <div
      style={{
        position: 'sticky',
        top: '0',
        background: scrolled ? 'var(--color-background)' : 'transparent',
        transition: '.1s ease background',
        zIndex: 9,
      }}
    >
      <EuiHeader
        style={{
          width: '100%',
          maxWidth: 'var(--layout-width-wide)',
          height: 'var(--navbar-height)',
          margin: '0 auto',
          padding: '0 var(--grid-margin)',
          boxShadow: 'none',
          borderBottom: '0',
          background: 'transparent',
        }}
      >
        <EuiHeaderSectionItem>
          <Link to="/">
            <Logo />
          </Link>
          <EuiShowFor sizes={['l', 'xl']}>
            <Group spacing="4" style={{ marginLeft: '2rem' }}>
              {isYoutuber && (
                <Button
                  variant="primary"
                  onClick={() => {
                    navigate('/channels/manage')
                  }}
                >
                  내 채널 관리
                </Button>
              )}
              {headerMenus.map(menu => (
                <Button
                  variant="text"
                  onClick={() => {
                    navigate(menu.href)
                  }}
                >
                  {menu.label}
                </Button>
              ))}
            </Group>
          </EuiShowFor>
        </EuiHeaderSectionItem>
        <EuiHeaderSectionItem>
          <EuiShowFor sizes={['l', 'xl']}>
            <DesktopHeaderRightSide />
          </EuiShowFor>
          <EuiShowFor sizes={['xs', 's', 'm']}>
            <MobileHeaderRightSide />
          </EuiShowFor>
        </EuiHeaderSectionItem>
      </EuiHeader>
    </div>
  )
}

const DesktopHeaderRightSide = () => {
  const { actions } = useSignModalContext()
  const navigate = useNavigate()

  const role = LoginAuthHelper.getRoles()
  const isGuest = role === 'GUEST'

  // theme
  const { value, setItem, getItem } = useLocalStorage()
  useEffect(() => {
    document.body.dataset.theme = `theme-${getItem('theme')}`
  }, [value])

  const handleThemeClick = () => {
    setItem('theme', getItem('theme') === 'dark' ? 'light' : 'dark')
  }

  const mypageUri = isGuest ? '/signup-oauth2' : '/mypage'

  return (
    <Group style={{ gap: '1.25rem' }}>
      <Link
        to={`https://collable.featurebase.app/kr`}
        target={'_blank'}
        style={{ color: 'inherit' }}
      >
        <Button variant="text" size="s">
          어떤 기능을 추가할까요?
        </Button>
      </Link>
      <Button variant="text" size="s" onClick={handleThemeClick}>
        {value !== 'dark' && <IconMoonStars size="18px" />}
        {value === 'dark' && <IconSun size="18px" />}
      </Button>
      {!!role && (
        <Button
          variant="text"
          size="s"
          onClick={() => {
            navigate(mypageUri)
          }}
        >
          <IconUserCircle size="18px" />
        </Button>
      )}
      {!role && (
        <Button
          variant="secondary"
          size="s"
          onClick={() => {
            actions.openSignIn('OTHER')
          }}
        >
          로그인
        </Button>
      )}
    </Group>
  )
}

export default HomeHeader
