import CustomizedPageBody from '../../component/CustomizedPageBody'
import { useLocation } from 'react-router-dom'
import { getTerms } from './TermsData'
import './Terms.scss'
import { TermsRenderer } from './TermsRenderer'

const Terms = () => {
  const location = useLocation()

  const path = location.pathname
  const type = path.replace('/', '')

  const contents = getTerms(type, undefined)

  return (
    <CustomizedPageBody>
      <div className="terms-contents">
        <TermsRenderer contents={contents} />
      </div>
    </CustomizedPageBody>
  )
}

export default Terms
