interface GroupProps {
  // variant?: 'smallButton' | undefined
  spacing?: '0' | '1' | '2' | '3' | '4' | '5' | '6'
  children: React.ReactNode
  direction?: 'row' | 'column'
  style?: React.CSSProperties
}

const spacingStyles = {
  '0': '0',
  '1': 'var(--space-1)',
  '2': 'var(--space-2)',
  '3': 'var(--space-3)',
  '4': 'var(--space-4)',
  '5': 'var(--space-5)',
  '6': 'var(--space-6)',
}

function Group({ direction, spacing, children, style }: GroupProps) {
  return (
    <div
      role="group"
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: direction,
        gap: spacing ? spacingStyles[spacing] : spacingStyles['2'],
        ...style,
      }}
    >
      {children}
    </div>
  )
}

export default Group
