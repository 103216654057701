import { categoryAssets } from '../util/consts'
import { CategoryButton } from 'component/CategoryButton'
import Modal from 'component/Modal'
import { Stack } from '@mui/joy'

interface Props {
  onSelect: (categoryCode: string) => void
  closeModal: VoidFunction
}

const CategorySelectModal = ({ onSelect, closeModal }: Props) => {
  return (
    <Modal title="카테고리 선택" onClose={closeModal}>
      <Stack spacing={1}>
        {categoryAssets.map(category => {
          return (
            <CategoryButton
              key={category.categoryCode}
              category={category}
              selected={false}
              onClick={() => onSelect(category.categoryCode)}
              style={{
                flex: 1,
                gridColumn: category.categoryCode === '' ? 'span 2' : undefined,
              }}
            />
          )
        })}
      </Stack>
    </Modal>
  )
}

export default CategorySelectModal
