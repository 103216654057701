import { EuiBasicTable, EuiBasicTableColumn, EuiFieldSearch, formatDate } from '@elastic/eui'
import React, { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Criteria } from '@elastic/eui/src/components/basic_table/basic_table'
import { VideoSummaryResponseItem } from '../../../api/types'
import { parseCount, parseSeconds } from '../../../util/utils'
import { apiSearchVideo, SearchVideoParams } from '../../../api/apis'
import VideoManageModal from './VideoManageModal'
import Button from 'component/button/Button'
import { useQuery } from '@tanstack/react-query'
import queryKeys from '../../../api/queryKeys'
import CustomizedPageBody from '../../../component/CustomizedPageBody'
import { Box, Chip } from '@mui/joy'

interface Props {
  channelId: number | undefined
}

const ContentsManage = ({ channelId }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const page: number = Number(searchParams.get('page') || '1')
  const keyword = searchParams.get('keyword')
  const sort = searchParams.get('sort') || 'latest'

  const params = {
    sort,
    channelId: channelId,
    page: page - 1,
    keyword: keyword,
  } as SearchVideoParams

  const {
    data: videos,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: queryKeys.videoSearch(params),
    queryFn: () => apiSearchVideo(params),
    enabled: !!channelId,
  })

  const [editingVideo, setEditingVideo] = useState<VideoSummaryResponseItem>()

  const pagination = {
    pageIndex: page - 1,
    pageSize: 20 || 0, // 페이지당 몇개
    totalItemCount: videos?.totalCount || 0, // 전체 갯수 몇개
    showPerPageOptions: false,
  }

  const onTableChange = ({ page, sort }: Criteria<any>) => {
    if (page) {
      const { index: pageIndex } = page
      setSearchParams({ page: `${pageIndex + 1}` })
    }
    // if (sort) {
    //   const { field: sortField, direction: sortDirection } = sort;
    //   nextParams.sort = sortField as string|undefined
    // }
  }
  const columns: Array<EuiBasicTableColumn<VideoSummaryResponseItem>> = [
    {
      field: 'thumbnailUrl',
      name: '',
      sortable: false,
      truncateText: false,
      width: '110px',
      render: (_, video: VideoSummaryResponseItem) => (
        <a
          href={`https://www.youtube.com/watch?v=${video.videoKey}`}
          rel="noreferrer"
          target="_blank"
          style={{ color: 'inherit' }}
        >
          <img src={video.thumbnailUrl} style={{ width: '100px' }} alt="" />
        </a>
      ),
    },
    {
      field: 'title',
      name: '제목',
      sortable: true,
      width: '400px',
      truncateText: true,
      render: (_, video: VideoSummaryResponseItem) => (
        <div
          style={{
            padding: '0 1rem',
            overflow: 'hidden',
            lineHeight: '1.1rem',
            maxHeight: '2.2rem',
            wordWrap: 'break-word',
            whiteSpace: 'normal',
          }}
        >
          {video.title}
        </div>
      ),
    },
    {
      field: 'viewCount',
      name: '조회수',
      width: '80px',
      render: (_, video) => `${parseCount(video.viewCount || 0)}`,
    },
    {
      field: 'highlight',
      name: '하이라이트',
      width: '80px',
      render: (_, video) => `${video.highlight ? parseSeconds(video.highlight) : ''}`,
    },
    {
      field: 'publishedAt',
      name: '게시일',
      width: '120px',
      render: (_, video: VideoSummaryResponseItem) => formatDate(video.publishedAt, 'YYYY-MM-DD'),
    },
    {
      field: 'adType',
      name: '유료광고',
      width: '50px',
      render: (_, video: VideoSummaryResponseItem) => video.adType && <Chip>포함</Chip>,
    },
    {
      field: 'productName',
      name: '유료광고상품',
      render: (_, video: VideoSummaryResponseItem) => {
        return (
          <Box display={{ flex: 'display', gap: '1rem' }}>
            <Box sx={{ width: '32px' }}>
              {video?.brandLogoUrl && (
                <img src={video?.brandLogoUrl} alt="" style={{ width: '32px', height: '32px' }} />
              )}
            </Box>
            <Box>
              <b>{video?.brandName}</b>
              <span>{video.productName}</span>
            </Box>
          </Box>
        )
      },
      truncateText: true,
      textOnly: true,
    },
    {
      field: 'publishedAt',
      name: '',
      width: '60px',
      render: (_, video: VideoSummaryResponseItem) => (
        <Button
          variant="tertiary"
          size="s"
          onClick={() => {
            setEditingVideo(video)
          }}
        >
          편집
        </Button>
      ),
    },
  ]

  const handleOnSearch = (search: string) => {
    setSearchParams({ keyword: search })
  }

  const onSubmitVideoSettings = () => {
    refetch()
  }

  return (
    <>
      <CustomizedPageBody>
        <div style={{ maxWidth: '1200px', margin: '0 auto' }}>
          <EuiFieldSearch
            placeholder={'검색'}
            onSearch={handleOnSearch}
            style={{
              height: '32px',
              marginTop: 'var(--space-3)',
              boxShadow: 'none',
              background: 'var(--color-background-weak)',
              borderRadius: 'var(--radius)',
            }}
            width={'100%'}
          />
          <EuiBasicTable
            items={videos?.videos || []}
            columns={columns}
            pagination={pagination}
            // sorting={sorting}
            onChange={onTableChange}
            loading={isLoading}
            compressed
            width={'100%'}
          />
        </div>
      </CustomizedPageBody>
      {editingVideo && (
        <VideoManageModal
          video={editingVideo}
          closeModal={() => {
            setEditingVideo(undefined)
          }}
          onSubmitVideoSetting={onSubmitVideoSettings}
        />
      )}
    </>
  )
}

export default ContentsManage
