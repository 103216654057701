import { Helmet } from 'react-helmet-async'

const defaultTitle = '유튜브 마케팅 협업 플랫폼 · 콜라블'
const defaultDescription = '유튜브 유튜버 마케팅 채널 성과 비교 · 인기 PPL 영상'
const defaultKeyword =
  '유튜브마케팅,유튜버마케팅,유튜버섭외,인플루언서마케팅,PPL,유료광고,간접광고,유튜브채널비교'
const defaultImage = 'https://collable.co/image/og-img.png'

interface Props {
  title?: string
  description?: string
  keywords?: string
  image?: string
}

export const MetaTags = ({ title: t, description: d, keywords: k, image: i }: Props) => {
  const title = t || defaultTitle
  const description = d || defaultDescription
  const keywords = combineKeywords(k)
  const image = i || defaultImage

  const url = window.location.origin + window.location.pathname

  const ld = {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    name: title,
    description: description,
  }

  return (
    <Helmet>
      <meta charSet="UTF-8" />
      <title>{title}</title>

      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <link rel="canonical" href={url} />

      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:site_name" content={'콜라블 Collable'} />
      <meta property="og:image" content={image} />
      <meta property="og:url" content={url} />
      <meta property="og:type" content="website" />

      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />

      <meta name="robots" content="index, follow" />
      <script type="application/ld+json">{`${JSON.stringify(ld)}`}</script>
    </Helmet>
  )
}

const combineKeywords = (pageKeywords: string | undefined) => {
  if (!pageKeywords) {
    return defaultKeyword
  }

  if (pageKeywords.length < 100) {
    return `${pageKeywords},${defaultKeyword}`
  } else {
    return pageKeywords
  }
}
