export const TermsRenderer = ({ contents }: { contents: string }) => {
  return (
    <div>
      {contents.split('\n').map((line: string, i) => {
        return <TermsLine line={line} />
      })}
    </div>
  )
}

const TermsLine = ({ line }: { line: string }) => {
  if (line.startsWith('## ')) {
    return <h2>{line.substring(3)}</h2>
  }
  if (line.startsWith('### ')) {
    return <h3>{line.substring(4)}</h3>
  }
  if (line.startsWith('#### ')) {
    return <h4>{line.substring(5)}</h4>
  }
  if (line.startsWith('    ')) {
    return <p style={{ marginLeft: '1rem' }}>{line}</p>
  }
  return <p>{line}</p>
}
