import { VideoSummaryResponseItem } from '../../../api/types'
import _ from 'lodash'

export const makeBlockItems = (videos: VideoSummaryResponseItem[]) => {
  const viewCountTop3 = _.chain(videos)
    .filter(v => !!v.viewCount)
    .sortBy('viewCount')
    .reverse()
    .take(3)
    .value()
  const likeCountTop3 = _.chain(videos)
    .filter(v => !!v.likeCount)
    .sortBy('likeCount')
    .reverse()
    .take(3)
    .value()
  const commentCountTop3 = _.chain(videos)
    .filter(v => !!v.commentCount)
    .sortBy('commentCount')
    .reverse()
    .take(3)
    .value()
  const viewRateTop3 = _.chain(videos)
    .filter(v => !!v.viewRatio)
    .sortBy('viewRatio')
    .reverse()
    .take(3)
    .value()
  const likeRateTop3 = _.chain(videos)
    .filter(v => !!v.likeRatio)
    .sortBy('likeRatio')
    .reverse()
    .take(3)
    .value()
  const commentRateTop3 = _.chain(videos)
    .filter(v => !!v.commentRatio)
    .sortBy('commentRatio')
    .reverse()
    .take(3)
    .value()

  return {
    viewCountTop3,
    likeCountTop3,
    commentCountTop3,
    viewRateTop3,
    likeRateTop3,
    commentRateTop3,
  }
}
