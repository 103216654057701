import { useState } from 'react'
import * as jose from 'jose'

const KEY = 'authorization'

const useLoginAuth = () => {
  const [token, setTokenState] = useState<string | null>(localStorage.getItem(KEY))

  const signIn = (token: string) => {
    setToken(token)
  }

  const signOut = () => {
    setToken(null)
  }

  const setToken = (value: string | null) => {
    if (value) {
      localStorage.setItem(KEY, value)
    } else {
      localStorage.removeItem(KEY)
    }
    setTokenState(value)

    window.location.reload()
  }

  const getRole = () => {
    if (!token) {
      return undefined
    }

    const decoded = jose.decodeJwt(token)
    return decoded.roles as 'GUEST' | 'ADVERTISER' | 'INFLUENCER'
  }

  const isLogined = !!token
  return { token, signIn, signOut, role: getRole(), isLogined }
}

export default useLoginAuth
