import { useNavigate } from 'react-router-dom'
import { useSignModalContext } from '../context/SignModalContext'
import { useEffect, useState } from 'react'
import {
  EuiFlyout,
  EuiFlyoutBody,
  EuiHeaderSectionItemButton,
  EuiModal,
  EuiModalBody,
  EuiPortal,
  useGeneratedHtmlId,
} from '@elastic/eui'
import { useLocalStorage } from '../hooks/useLocalStorage'
import LoginAuthHelper from '../util/LoginAuthHelper'
import axios from 'axios'
import Group from './Group'
import Button from './button/Button'
import { IconMoonStars, IconUserCircle } from '@tabler/icons-react'
import MobileHeaderSearchInput from './MobileHeaderSearchInput'
import { headerMenus } from '../layout/HomeHeader'
import { Menu, Search } from '@mui/icons-material'

const MobileHeaderRightSide = () => {
  const [isFlyoutVisible, setIsFlyoutVisible] = useState(false)
  const [isSearchVisible, setIsSearchVisible] = useState(false)

  const role = LoginAuthHelper.getRoles()
  const isYoutuber = role === 'INFLUENCER'

  return (
    <>
      {!isYoutuber && (
        <EuiHeaderSectionItemButton aria-label="search" onClick={() => setIsSearchVisible(true)}>
          <Search />
        </EuiHeaderSectionItemButton>
      )}
      <EuiHeaderSectionItemButton aria-label="app menu" onClick={() => setIsFlyoutVisible(true)}>
        <Menu />
      </EuiHeaderSectionItemButton>
      {isFlyoutVisible && <MobileMenuFlyout setVisible={setIsFlyoutVisible} />}
      {isSearchVisible && <SearchModal setVisible={setIsSearchVisible} />}
    </>
  )
}

const MobileMenuFlyout = ({ setVisible }: { setVisible: (visible: boolean) => void }) => {
  const navigate = useNavigate()
  const { actions } = useSignModalContext()
  const flyoutId = useGeneratedHtmlId({ prefix: 'mobileMenu' })

  // theme
  const { value, setItem, getItem } = useLocalStorage()
  const handleThemeClick = () => {
    setItem('theme', getItem('theme') === 'dark' ? 'light' : 'dark')
  }

  useEffect(() => {
    document.body.dataset.theme = `theme-${getItem('theme')}`
  }, [value])

  const role = LoginAuthHelper.getRoles()
  const isGuest = role === 'GUEST'
  const isYoutuber = role === 'INFLUENCER'
  const mypageUri = isGuest ? '/signup-oauth2' : '/mypage'

  const hasAuth = !!axios.defaults.headers.Authorization

  return (
    <EuiPortal>
      <EuiFlyout
        onClose={() => setVisible(false)}
        size="s"
        id={flyoutId}
        style={{
          background: 'var(--color-background)',
        }}
        hideCloseButton
      >
        <EuiFlyoutBody>
          <Group direction="column">
            {isYoutuber && (
              <Button
                variant="primary"
                onClick={() => {
                  navigate('/manage/channels')
                }}
              >
                <b>내 채널 관리</b>
              </Button>
            )}
            {headerMenus.map(menu => (
              <Button
                variant="text"
                onClick={() => {
                  setVisible(false)
                  navigate(menu.href)
                }}
              >
                <b>{menu.label}</b>
              </Button>
            ))}
          </Group>
          <Group
            direction="column"
            style={{
              marginTop: '4rem',
            }}
          >
            <Button variant="text" size="s" onClick={handleThemeClick}>
              <IconMoonStars size="18px" />
            </Button>
            {hasAuth && (
              <Button variant="tertiary" size="s" href={mypageUri}>
                <IconUserCircle size="18px" />
              </Button>
            )}
            {!hasAuth && (
              <Button
                variant="secondary"
                size="s"
                onClick={() => {
                  setVisible(false)
                  actions.openSignIn('OTHER')
                }}
              >
                로그인
              </Button>
            )}
          </Group>
        </EuiFlyoutBody>
      </EuiFlyout>
    </EuiPortal>
  )
}

const SearchModal = ({ setVisible }: { setVisible: (visible: boolean) => void }) => {
  return (
    <EuiModal
      onClose={() => setVisible(false)}
      autoFocus={true}
      initialFocus={'.global-search-input'}
    >
      <EuiModalBody>
        <MobileHeaderSearchInput onSearch={() => setVisible(false)} />
      </EuiModalBody>
    </EuiModal>
  )
}

export default MobileHeaderRightSide
