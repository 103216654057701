import { Bar, BarChart, LabelList, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { VideoSummaryResponseItem } from '../../../api/types'
import dayjs from 'dayjs'
import _ from 'lodash'
import '../../../component/chart/VideoTooltip.scss'
import VideoTooltip from '../../../component/chart/VideoTooltip'
import { formatCount, formatTimestampToDate } from '../../../util/chartUtils'
import ChannelIconLabelSvg from '../../../component/chart/ChannelIconLabelSvg'

interface ChartData {
  name: string
  videoId: number
  thumbnailUrl: string
  viewCount: number
  date: number
}

const BrandPPLCompareChart = ({ videos }: { videos: VideoSummaryResponseItem[] | undefined }) => {
  if (!videos) return null

  const data = makeChartData(videos)

  const CustomTick = (props: any) => {
    const { index, x, y, width } = props
    const currData = data[index]
    const { thumbnailUrl } = currData

    return <ChannelIconLabelSvg x={x} y={y} width={width} thumbnailUrl={thumbnailUrl} />
  }

  const CustomTooltip = (props: any) => {
    const { active, payload } = props
    if (!active || !payload || !payload.length) {
      return null
    }
    const barProps = payload[0]
    const data = barProps.payload as ChartData
    const video = videos.find(v => v.videoId === data.videoId)

    if (!video) return null

    return <VideoTooltip video={video} />
  }

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 15,
          right: 30,
          left: 0,
          bottom: 0,
        }}
      >
        <XAxis dataKey="date" tickFormatter={formatTimestampToDate} />
        <YAxis tickFormatter={formatCount} />
        <Tooltip
          content={<CustomTooltip />}
          isAnimationActive={false}
          cursor={{ fill: '#f6f6f6' }}
        />
        <Bar dataKey="viewCount" fill="#82ca9d" barSize="16">
          <LabelList content={<CustomTick />} />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  )
}

const makeChartData = (videos: VideoSummaryResponseItem[] | undefined) => {
  if (!videos?.length) return []

  const data = videos.map(video => {
    return {
      name: video.channelName,
      videoId: video.videoId,
      thumbnailUrl: video.channelThumbnail,
      viewCount: video.viewCount,
      date: dayjs(video.publishedAt).valueOf(),
    }
  }) as ChartData[]

  const minDate = dayjs().subtract(1, 'year').valueOf()

  return _.chain(data)
    .sortBy('date')
    .filter(d => d.date >= minDate)
    .value()
}

export default BrandPPLCompareChart
