import Box from '@mui/joy/Box'
import Breadcrumbs from '@mui/joy/Breadcrumbs'
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded'
import Link from '@mui/joy/Link'
import HomeRoundedIcon from '@mui/icons-material/HomeRounded'
import Typography from '@mui/joy/Typography'
import * as React from 'react'
import { Link as RouterLink } from 'react-router-dom'

export interface BreadcrumbsItem {
  link?: string
  label: string
}

interface Props {
  title?: string | React.ReactNode
  breadcrumbs?: BreadcrumbsItem[]
  children: React.ReactNode
}

const DashboardPageBody = ({ title, breadcrumbs, children }: Props) => {
  return (
    <Box
      component="main"
      className="MainContent"
      sx={{
        px: { xs: 2, md: 6 },
        pt: {
          xs: 'calc(12px + var(--HomeHeader-height))',
          sm: 'calc(12px + var(--HomeHeader-height))',
          md: 3,
        },
        pb: { xs: 2, sm: 2, md: 3 },
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        minWidth: 0,
        height: '100dvh',
        gap: 1,
      }}
    >
      {breadcrumbs && (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Breadcrumbs
            size="sm"
            aria-label="breadcrumbs"
            separator={<ChevronRightRoundedIcon fontSize="small" />}
            sx={{ pl: 0 }}
          >
            <Link underline="none" color="neutral" aria-label="Home" href={`/dashboard`}>
              <HomeRoundedIcon />
            </Link>
            {breadcrumbs.map((item, index) => (
              <>
                {item.link && (
                  <RouterLink to={item.link}>
                    <Typography
                      color={index === breadcrumbs.length - 1 ? 'primary' : 'neutral'}
                      sx={{ fontWeight: 500, fontSize: 12 }}
                    >
                      {item.label}
                    </Typography>
                  </RouterLink>
                )}
                {!item.link && (
                  <Typography
                    color={index === breadcrumbs.length - 1 ? 'primary' : 'neutral'}
                    sx={{ fontWeight: 500, fontSize: 12 }}
                  >
                    {item.label}
                  </Typography>
                )}
              </>
            ))}
          </Breadcrumbs>
        </Box>
      )}
      {title && (
        <Box
          sx={{
            display: 'flex',
            mb: 1,
            gap: 1,
            flexDirection: { xs: 'column', sm: 'row' },
            alignItems: { xs: 'start', sm: 'center' },
            flexWrap: 'wrap',
            justifyContent: 'space-between',
          }}
        >
          {typeof title === 'string' && (
            <Typography level="h2" component="h1">
              {title}
            </Typography>
          )}
          {typeof title !== 'string' && title}
        </Box>
      )}
      <Box>{children}</Box>
    </Box>
  )
}

export default DashboardPageBody
