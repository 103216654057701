import { createBrowserHistory } from 'history'
import ReactDOM from 'react-dom/client'
import './index.scss'
import App from './App'
import { EuiProvider } from '@elastic/eui'
import axios from 'axios'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import LoginAuthHelper from './util/LoginAuthHelper'
import { SignModalProvider } from './context/SignModalContext'
import { HelmetProvider } from 'react-helmet-async'

const initAxios = () => {
  axios.defaults.baseURL = `${process.env.REACT_APP_SERVER}`
  axios.defaults.withCredentials = true

  const authorization = LoginAuthHelper.getToken()
  if (authorization) {
    axios.defaults.headers.Authorization = `${authorization}`
  }

  axios.interceptors.response.use(
    async res => {
      const authorization = res.headers.authorization
      if (authorization) {
        axios.defaults.headers.Authorization = `${authorization}`
        LoginAuthHelper.setToken(authorization)
      }

      const location = res.headers.location
      if (location) {
        // TODO global로 useNavigate 사용할 수 있으면 수정
        window.location.replace(location)
      }

      return res
    },
    error => {
      const status = error.response?.status
      if (status === 401 || status === 403) {
        axios.defaults.headers.Authorization = null
        LoginAuthHelper.removeToken()
        // window.location.reload()
      }

      if (error.response?.data) {
        const message = error.response?.data?.message
        if (message) {
          window.alert(message)
        }
      }
      return Promise.reject(error)
    },
  )
}

initAxios()

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
    },
  },
})

export const history = createBrowserHistory()

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <EuiProvider colorMode="light">
    <QueryClientProvider client={queryClient}>
      <SignModalProvider>
        <HelmetProvider>
          <App />
        </HelmetProvider>
      </SignModalProvider>
    </QueryClientProvider>
  </EuiProvider>,
)
