import { apiGetBookmarkGroup, usePutBookmark } from '../../../api/apis'
import { EuiSelectable, EuiSelectableOption } from '@elastic/eui'
import { useEffect, useState } from 'react'
import loginAuthHelper from '../../../util/LoginAuthHelper'
import BookmarkGroupCreateModal from '../../../modal/BookmarkGroupCreateModal'
import { BookmarkGroupItem } from '../../../api/types'
import Button from 'component/button/Button'
import Modal from 'component/Modal'
import { useSignModalContext } from '../../../context/SignModalContext'
import { useQuery } from '@tanstack/react-query'
import queryKeys from '../../../api/queryKeys'
import { Box, Typography } from '@mui/joy'

interface Props {
  channelId: number
  closeModal: VoidFunction
}

const BookmarkManageModal = ({ channelId, closeModal }: Props) => {
  const { actions } = useSignModalContext()
  const [options, setOptions] = useState<Array<EuiSelectableOption<any>>>([])
  const { data: bookmarkGroups } = useQuery({
    queryKey: queryKeys.getBookmarkGroups({ channelId: channelId }),
    queryFn: () => apiGetBookmarkGroup({ channelId: channelId }),
  })

  const { mutate: putBookmark } = usePutBookmark()
  const [showBookmarkGroupCreateModal, setShowBookmarkGroupCreateModal] = useState(false)

  useEffect(() => {
    if (!bookmarkGroups) {
      return
    }

    const options = (bookmarkGroups?.privateGroups || []).map(
      (group): EuiSelectableOption<any> => ({
        label: `${group.name} (${group.count})`,
        checked: group.bookmarked ? 'on' : undefined,
        data: group,
      }),
    )

    setOptions(options)
  }, [bookmarkGroups])

  const isLogined = loginAuthHelper.isLogined()

  const onClickPrimary = () => {
    if (!isLogined) {
      closeModal()
      actions.openSignIn('OTHER')
      return
    }

    const selectedItems = options.filter(item => item.checked === 'on')
    const selectedGroupIds = selectedItems.map(item => {
      const groupItem = item.data as BookmarkGroupItem
      return groupItem.bookmarkGroupId
    })
    const payload = { channelId: channelId, groupIds: selectedGroupIds }

    putBookmark(payload, {
      onSuccess: () => {
        closeModal()
      },
    })
  }

  return (
    <>
      <Modal
        title="컬렉션 추가"
        onClose={closeModal}
        primaryAction={{ label: isLogined ? '저장' : '로그인', onClick: onClickPrimary }}
        secondaryAction={{ label: '닫기', onClick: closeModal }}
      >
        {isLogined && (
          <>
            <Box sx={{ mt: '2rem' }}>
              <Typography level="title-md">컬렉션을 선택해주세요</Typography>
              <Box>
                <Button
                  variant="text"
                  size="s"
                  onClick={() => {
                    setShowBookmarkGroupCreateModal(true)
                  }}
                >
                  새 컬렉션 만들기
                </Button>
              </Box>
            </Box>
            <div style={{ height: '.5rem' }} />
            <EuiSelectable options={options} onChange={options => setOptions(options)} height={240}>
              {list => <>{list}</>}
            </EuiSelectable>
          </>
        )}
        {!isLogined && (
          <Box sx={{ mt: '2rem' }}>
            <Typography level="title-md">컬렉션을 선택해주세요</Typography>
          </Box>
        )}
      </Modal>
      {showBookmarkGroupCreateModal && (
        <BookmarkGroupCreateModal
          closeModal={() => {
            setShowBookmarkGroupCreateModal(false)
          }}
        />
      )}
    </>
  )
}

export default BookmarkManageModal
