import Box from '@mui/joy/Box'
import Breadcrumbs from '@mui/joy/Breadcrumbs'
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded'
import Link from '@mui/joy/Link'
import HomeRoundedIcon from '@mui/icons-material/HomeRounded'
import Typography from '@mui/joy/Typography'
import * as React from 'react'
import VideoPlaylist from '../../../component/VideoPlaylist'
import { EuiPagination } from '@elastic/eui'
import Group from '../../../component/Group'
import CustomizedSelect from '../../../component/CustomizedSelect'
import { videoSorts } from '../../../util/consts'
import { useSearchParams } from 'react-router-dom'
import { getPublishedAt } from '../../../util/utils'
import { apiSearchVideo, SearchVideoParams } from '../../../api/apis'
import { useQuery } from '@tanstack/react-query'
import CategoryFilter from '../../../component/CategoryFilter'
import Button from '@mui/joy/Button'
import { ShareRounded } from '@mui/icons-material'
import DashboardPageBody from '../../../layout/DashboardPageBody'
import { MetaTags } from '../../../util/MetaTags'

const VideoExplore = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const page = Number(searchParams.get('page') || 1)
  const categoryCode = searchParams.get('categoryCode') || ''
  const period = searchParams.get('period') || (categoryCode ? '3M' : '1M')
  const sort = searchParams.get('sort') || 'view'
  const adOnly = true

  const { startPublishedAt, endPublishedAt } = getPublishedAt(period)

  const searchVideoQueryParams: SearchVideoParams = {
    categoryCode,
    startPublishedAt,
    endPublishedAt,
    adOnly,
    sort,
    page: page - 1,
  }

  const { data: videos } = useQuery({
    queryKey: ['/v1/videos', searchVideoQueryParams],
    queryFn: () => apiSearchVideo(searchVideoQueryParams),
  })

  const updateQuery = (key: keyof SearchVideoParams | 'period', value: string) => {
    searchParams.set(key, value)
    setSearchParams(searchParams)
  }

  return (
    <DashboardPageBody title="유료광고 트렌드" breadcrumbs={[{ label: 'Videos' }]}>
      <MetaTags
        title={`유료광고 트렌드 · 콜라블`}
        description={description()}
        keywords={keywords()}
      />
      <Box>
        <Box marginBottom="2rem">
          <CategoryFilter
            selected={categoryCode}
            actionType="updateQuery"
            updateQuery={updateQuery}
          />
        </Box>
        <Box marginBottom="1rem">
          <Group>
            <CustomizedSelect
              options={[
                { value: '1W', text: '최근 1주일' },
                { value: '1M', text: '최근 1달' },
                { value: '3M', text: '최근 3달' },
              ]}
              value={period}
              onChange={e => updateQuery('period', e.target.value)}
            />
            <CustomizedSelect
              options={videoSorts}
              value={sort}
              onChange={e => updateQuery('sort', e.target.value)}
            />
          </Group>
        </Box>
        <Box>
          <div style={{ width: '100%', maxWidth: 'var(--layout-width-wide)' }}>
            <VideoPlaylist videos={videos?.videos} showChannelAvatar />
            <EuiPagination
              aria-label="page"
              pageCount={videos?.totalPage || 1}
              activePage={page - 1}
              onPageClick={nextPage => setSearchParams({ period, page: `${nextPage + 1}` })}
            />
          </div>
        </Box>
      </Box>
    </DashboardPageBody>
  )
}

const description = () => {
  return `유튜브 유료광고(PPL) 트렌드 분석 플랫폼. 다양한 카테고리의 광고를 탐색할 수 있습니다. 업종별 광고 성과를 비교해보세요. 최신 유료광고 트렌드를 파악하고 효과적인 마케팅 전략을 수립해보세요.`
}

const keywords = () => {
  return `유튜브 유료광고, PPL 트렌드, 인플루언서 마케팅, 광고 성과 분석, 카테고리별 광고, 뷰티 광고, 패션 광고, 식품 광고, 음료 광고, 식당 광고, 프렌차이즈 광고, 앱서비스 광고, 게임 광고, 테크 광고, 리빙 광고, 문화취미 광고, 건강운동 광고, 교육 광고, 기술 광고, 여행 광고, 반려동물 광고, 금융 광고, 재테크 광고, 자동차 광고, 유튜브 마케팅 전략, 브랜드 협업, 콘텐츠 마케팅`
}
export default VideoExplore
