import { ArrowRight } from '@mui/icons-material'
import { Link } from 'react-router-dom'

interface Props {
  to: string
}

// TODO marginLeft auto는 컴포넌트 외부에서 콘트롤할 수 있도록

function ViewAllButton({ to }: Props) {
  return (
    <Link
      to={to}
      style={{
        padding: '.5rem',
        color: 'var(--color-primary)',
      }}
    >
      <div style={{ display: 'flex', gap: '.25rem', alignItems: 'center' }}>
        전체보기
        <ArrowRight />
      </div>
    </Link>
  )
}

export default ViewAllButton
