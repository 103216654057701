import { parseCount, parseRatio } from '../../../util/utils'
import { Typography } from '@mui/joy'

export const ValueCell = ({
  count,
  rate,
  unit,
}: {
  count: number
  rate?: number
  unit: string
}) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '0.125rem' }}>
      <Typography level="title-sm">
        {parseCount(count)}
        {unit}
      </Typography>
      {rate && <Typography level="body-sm">{parseRatio(rate)}</Typography>}
    </div>
  )
}
