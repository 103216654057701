import Tile from './Tile'
import Title from './texts/Text'

export const VideoStatisticsItem = ({
  label,
  value,
  description,
}: {
  label: string
  value: string
  description?: string
}) => {
  return (
    <Tile variant="subtle" size="large">
      <div
        style={{
          marginBottom: 'var(--space-1)',
          fontWeight: '500',
          color: 'var(--color-text-light)',
        }}
      >
        {label}
      </div>
      <Title level="2">{value}</Title>
      {!!description && <div style={{ marginTop: '1rem' }}>{description}</div>}
    </Tile>
  )
}
