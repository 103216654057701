import { useState, useCallback } from 'react'

// import styles from './Tab.module.css'

type Option = {
  value: string
  label: string
}

interface TabListProps {
  tabs: Option[]
  selected: string
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void
}

function Tabs(props: TabListProps) {
  return (
    <div
      style={{
        display: 'flex',
        gap: '1.5rem',
        borderBottom: 'var(--border)',
      }}
    >
      {/* <div className={styles.tablist} data-type={props.type}> */}
      {props.tabs.map(tab => {
        const isSelected = props.selected === tab.value
        return (
          <button
            key={tab.value}
            value={tab.value}
            data-active={props.selected === tab.value}
            onClick={props.onClick}
            style={{
              padding: 'var(--space-6) 0 var(--space-4)',
              borderBottom: isSelected ? '3px solid #07C' : '3px solid transparent',
              borderColor: isSelected ? 'var(--color-primary)' : 'transparent',
              color: isSelected ? 'var(--color-primary)' : 'var(--color-text-light)',
              fontSize: '1.25rem',
              fontWeight: 'bold',
            }}
          >
            {tab.label}
            {/* {props.badgeLabel && <span className={styles.badge}>{props.badgeLabel}</span>} */}
          </button>
        )
      })}
    </div>
  )
}

export default Tabs

export const TabPanel = ({
  children,
  value,
  selected,
}: {
  children: React.ReactNode
  value: string
  selected: string
}) => {
  return <div style={{ display: value === selected ? 'block' : 'none' }}>{children}</div>
}

export const useTab = (
  initialSelectedTab: string,
): [string, (event: React.MouseEvent<HTMLButtonElement>) => void] => {
  const [selectedTab, setSelectedTab] = useState(initialSelectedTab)

  const handleTabClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    const { value } = event.currentTarget as HTMLButtonElement
    setSelectedTab(value)
  }, [])
  return [selectedTab, handleTabClick]
}
