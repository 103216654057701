import dayjs from 'dayjs'
import { parseCount } from '../util/utils'
import { Link, useNavigate } from 'react-router-dom'
import Stat from './Stat'
import { Box, Sheet, Stack, Typography, Avatar, Card } from '@mui/joy'

interface VideoItemProps {
  videoId: number
  channelId: number
  channelName?: string
  channelThumbnail?: string
  title: string
  viewCount: number
  likeCount: number
  commentCount?: number
  thumbnailUrl: string
  publishedAt?: Date
  brandId?: number
  brandLogoUrl?: string
  brandName?: string
  productName?: string
}

interface Props {
  video: VideoItemProps
  showChannelAvatar?: boolean
  showPPL?: boolean
  alignment?: 'vertical' | 'horizontal'
  onClickVideo?: (e: React.UIEvent, videoId: number) => void
}

const VideoItem = ({
  video,
  showChannelAvatar,
  showPPL,
  alignment = 'vertical',
  onClickVideo,
}: Props) => {
  const navigate = useNavigate()

  const isHorizontal = alignment === 'horizontal'

  return (
    <Card style={{ display: 'block', minWidth: '0', color: 'inherit' }}>
      <div
        style={{
          display: isHorizontal ? 'flex' : 'block',
          gap: isHorizontal ? 'var(--space-4)' : '0',
          alignItems: 'flex-start',
        }}
      >
        <Link
          to={`/videos/${video.videoId}`}
          onClick={onClickVideo ? e => onClickVideo(e, video.videoId) : undefined}
        >
          <img
            src={video.thumbnailUrl}
            style={{
              width: isHorizontal ? '169px' : '100%',
              borderRadius: 'var(--radius)',
            }}
            alt={`'${video.title}' video thumbnail`}
          />
        </Link>
        <div
          style={{
            width: isHorizontal ? 'calc(100% - 169px)' : '',
            marginTop: isHorizontal ? '-.5rem' : '.25rem',
          }}
        >
          {showChannelAvatar && (
            <Link to={`/channels/${video.channelId}`} style={{ color: 'inherit' }}>
              <Box display="flex" gap={1}>
                <Avatar alt={`'${video.channelName}' channel logo`} src={video.channelThumbnail} />
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Typography level="title-md">{video.channelName}</Typography>
                  <Typography level="body-sm">{dayjs(video.publishedAt).fromNow()}</Typography>
                </Box>
              </Box>
            </Link>
          )}
          <Link
            to={`/videos/${video.videoId}`}
            onClick={onClickVideo ? e => onClickVideo(e, video.videoId) : undefined}
          >
            <Typography
              level="title-md"
              noWrap
              sx={{
                m: 'var(--space-2) 0 0',
              }}
            >
              {video.title}
            </Typography>
          </Link>

          <Stack direction="row" spacing={4} sx={{ mt: 1.5 }}>
            <Stat label="조회" value={parseCount(video.viewCount)} />
            <Stat label="좋아요" value={parseCount(video.likeCount)} />
            <Stat label="댓글" value={parseCount(video.commentCount)} />
            {!showChannelAvatar && (
              <Typography level="body-sm">{dayjs(video.publishedAt).fromNow()}</Typography>
            )}
          </Stack>
          {showPPL !== false && video.productName && (
            <Sheet
              variant="soft"
              sx={{ py: 1, px: 1.75, m: -1.75, mt: 1, borderRadius: 'var(--radius)' }}
            >
              <button
                onClick={e => {
                  e.preventDefault()
                  navigate(`/brands/${video.brandId}`)
                }}
                style={{ display: 'block', textAlign: 'left', width: '100%' }}
              >
                <Box display="flex" gap={1}>
                  <Avatar
                    alt={`'${video.brandName}' brand logo`}
                    // name={video.brandName}
                    // variant="brand"
                    // size="l"
                    src={video.brandLogoUrl}
                    // type="space"
                  />
                  <div>
                    <Typography level="title-md">{video.brandName}</Typography>
                    <Typography level="body-sm">{video.productName}</Typography>
                  </div>
                </Box>
              </button>
            </Sheet>
          )}
        </div>
      </div>
    </Card>
  )
}

export const Dot = () => {
  return <span style={{ margin: '0 4px' }}>·</span>
}

export default VideoItem
