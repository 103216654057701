import Group from '../../../component/Group'
import { Link as RouterLink } from 'react-router-dom'
import Avatar from '../../../component/Avatar'
import dayjs from 'dayjs'
import Tile from '../../../component/Tile'
import VideoItemMinimum from '../../../component/VideoItemMinimum'
import Box from '@mui/joy/Box'
import { ShoutoutResponseItem } from '../../../api/apis'
import { Typography } from '@mui/joy'

const ShoutoutItem = ({ item }: { item: ShoutoutResponseItem }) => {
  return (
    <Box width="100%">
      <Group
        spacing={'1'}
        style={{
          alignItems: 'center',
        }}
      >
        <RouterLink to={`/brands/${item.brandId}`}>
          <Group
            style={{
              width: 'fit-content',
              alignItems: 'center',
              fontWeight: 'bold',
            }}
          >
            <Avatar size="sm" src={item.brandLogoUrl} variant={'brand'} />
            <div>{item.brandName}</div>
          </Group>
        </RouterLink>
        <span>를 향한</span>
        <RouterLink to={`/channels/${item.channelId}`}>
          <Group
            style={{
              width: 'fit-content',
              alignItems: 'center',
              fontWeight: 'bold',
            }}
          >
            <Avatar size="sm" src={item.channelThumbnail} />
            <div
              style={{
                maxWidth: '120px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {item.channelName}
            </div>
          </Group>
        </RouterLink>
        <span>의 샤라웃</span>
        <Box sx={{ marginLeft: 'auto' }}>
          <Typography level="body-sm">{dayjs(item.createdAt).fromNow()}</Typography>
        </Box>
      </Group>
      <Tile size={'large'} style={{ width: 'calc(100% - 2rem)', marginLeft: '2rem' }}>
        <VideoItemMinimum video={item} alignment={'horizontal'} />
      </Tile>
    </Box>
  )
}

export default ShoutoutItem
