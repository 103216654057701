import Typography from '@mui/joy/Typography'
import { IconPlus } from '@tabler/icons-react'
import Tile from '../../../component/Tile'
import Avatar from '../../../component/Avatar'
import ChannelCompareChart, { COLORS } from './ChannelCompareChart'
import Tabs, { TabPanel, useTab } from '../../../component/tab/Tab'
import ChannelCompareChannelInfo from './ChannelCompareChannelInfo'
import ChannelSelectModal from './ChannelSelectModal'
import { Link as RouterLink, useSearchParams } from 'react-router-dom'
import { useModal } from '../../../component/Modal'
import { useQuery } from '@tanstack/react-query'
import queryKeys from '../../../api/queryKeys'
import { apiGetChannelCompareDetails } from '../../../api/apis'
import _ from 'lodash'
import { Option } from '../../../api/types'
import DashboardPageBody from '../../../layout/DashboardPageBody'
import { Close } from '@mui/icons-material'
import { MetaTags } from '../../../util/MetaTags'
import * as React from 'react'

const tabOptions: Option[] = [
  { label: '통계 비교', value: 'all' },
  { label: '콘텐츠 비교', value: 'contents' },
]

const ChannelCompare = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const channelIds = (searchParams.get('channels') || '').split(',').filter(item => !!item)

  const { opened, toggleModal } = useModal(false)
  const [selectedTab, handleTabClick] = useTab(tabOptions[0].value)

  const { data: compareChannels } = useQuery({
    queryKey: queryKeys.getChannelCompareDetails(channelIds),
    queryFn: () => apiGetChannelCompareDetails(channelIds),
    placeholderData: (previousData, _) => previousData,
  })

  const sortedCompareChannels = _.sortBy(compareChannels, channel => {
    return channelIds.indexOf(`${channel.channelId}`)
  })

  const onClickAdd = (channelId: number) => {
    const nextChannelIds = [...channelIds, channelId.toString()].join(',')
    setSearchParams({ channels: nextChannelIds }, { replace: true })
  }

  const onClickRemove = (channelId: number) => {
    const nextChannelIds = channelIds.filter(item => item !== `${channelId}`).join(',')
    setSearchParams({ channels: nextChannelIds }, { replace: true })
  }

  return (
    <DashboardPageBody title={'채널 비교'} breadcrumbs={[{ label: 'Channel Compare' }]}>
      <MetaTags title={`채널 비교 · 콜라블`} description={description()} keywords={keywords()} />
      <div
        style={{
          width: '100%',
          maxWidth: 'var(--layout-width-regular)',
        }}
      >
        <div style={{ marginBottom: 'var(--space-4)' }}>
          {channelIds.length < 4 && (
            <button
              onClick={toggleModal}
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                gap: 'var(--space-3)',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '40px',
                  height: '40px',
                  border: 'var(--border)',
                  background: 'var(--color-background-weak)',
                  borderRadius: '50%',
                  fontWeight: 'bold',
                }}
              >
                <IconPlus size="20px" />
              </div>
              <p style={{ fontSize: '1.25rem', fontWeight: 'bold' }}>
                비교할 채널을 추가해보세요<i style={{ fontStyle: 'italic' }}>!</i>
              </p>
            </button>
          )}
          {channelIds.length === 4 && (
            <Typography level="body-md">
              최대 4개의 채널까지
              <br />
              선택할 수 있습니다
            </Typography>
          )}
        </div>
        <section style={{ display: 'flex', gap: '.75rem' }}>
          {sortedCompareChannels?.map((channel, i) => {
            return (
              <Tile variant="flat" style={{ flex: 'unset', minWidth: 'calc(25% - .5rem)' }}>
                <RouterLink
                  to={`/channels/${channel.channelId}`}
                  target="_blank"
                  style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                >
                  {/* TODO badge component */}
                  <Avatar src={channel.thumbnailUrl} size="lg" />
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginTop: 'var(--space-3)',
                      gap: 'var(--space-2)',
                    }}
                  >
                    <div
                      style={{
                        width: '.75rem',
                        height: '.75rem',
                        borderRadius: '50%',
                        backgroundColor: COLORS[i],
                      }}
                    />
                    <b>{channel.name}</b>
                  </div>
                  <button
                    style={{
                      padding: '0.25rem',
                      position: 'absolute',
                      right: '.25rem',
                      top: '.25rem',
                    }}
                    onClick={e => {
                      e.preventDefault()
                      e.stopPropagation()
                      onClickRemove(channel.channelId)
                    }}
                  >
                    <Close />
                  </button>
                </RouterLink>
              </Tile>
            )
          })}
        </section>
        <div style={{ height: '1.25rem' }} />
        <Tabs tabs={tabOptions} selected={selectedTab} onClick={handleTabClick} />
      </div>
      <div style={{ height: '1.25rem' }} />
      <TabPanel value={tabOptions[0].value} selected={selectedTab}>
        <div
          style={{
            width: '100%',
            maxWidth: 'var(--layout-width-regular)',
          }}
        >
          <ChannelCompareChart channels={sortedCompareChannels || []} />
        </div>
      </TabPanel>
      <TabPanel value={tabOptions[1].value} selected={selectedTab}>
        <div
          style={{
            minWidth: 'var(--layout-width-wide)',
            maxWidth: 'var(--layout-width-wide)',
            margin: '0 auto',
          }}
        >
          <ChannelCompareChannelInfo channels={sortedCompareChannels} adOnly={false} />
        </div>
      </TabPanel>
      {opened && <ChannelSelectModal onChannelSelect={onClickAdd} closeModal={toggleModal} />}
    </DashboardPageBody>
  )
}

const description = () => {
  return `유튜브 채널 비교 분석 툴. 채널들을 선택해 조회수, 좋아요수를 비교해보세요. 최근 인기 영상 비교를 통해 협업 가능성을 탐색할 수 있습니다. 데이터 기반의 인사이트로 최적의 마케팅 파트너를 찾아보세요.`
}

const keywords = () => {
  return `유튜브 채널 비교, 인플루언서 분석, 채널 성과 비교, 협업 파트너 찾기, 인게이지먼트 분석, 조회수 비교, 인기 영상 비교, 유튜브 마케팅, 콘텐츠 크리에이터 분석, 데이터 기반 마케팅`
}

export default ChannelCompare
