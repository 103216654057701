import { createContext, ReactNode, useContext, useMemo, useState } from 'react'

export type EntryType = 'CHANNEL_DETAIL' | 'OTHER'

export const SignModalContext = createContext({
  signInModalOpened: false,
  signInEntryType: 'OTHER' as EntryType,
  signUpModalOpened: false,
  signUpVerificationOpened: false,
  actions: {
    openSignIn: (entryType: EntryType) => {},
    closeSignIn: () => {},
    openSignUp: () => {},
    closeSignUp: () => {},
    openSignUpVerification: () => {},
    closeSignUpVerification: () => {},
  },
})
export const useSignModalContext = () => useContext(SignModalContext)

export interface SignModalContextActions {
  openSignIn: VoidFunction
  closeSignIn: VoidFunction
  openSignUp: VoidFunction
  closeSignUp: VoidFunction
  openSignUpVerification: VoidFunction
  closeSignUpVerification: VoidFunction
}

export const SignModalProvider = ({ children }: { children: ReactNode }) => {
  const [signInModalOpened, setSignInModalOpened] = useState(false)
  const [signUpModalOpened, setSignUpModalOpened] = useState(false)
  const [signUpVerificationOpened, setSignUpVerificationOpened] = useState(false)
  const [signInEntryType, setSignInEntryType] = useState<EntryType>('OTHER')

  const actions = useMemo(
    () =>
      ({
        openSignIn: (entryType: EntryType) => {
          setSignInEntryType(entryType)
          setSignInModalOpened(true)
        },
        closeSignIn: () => {
          setSignInModalOpened(false)
        },
        openSignUp: () => {
          setSignUpModalOpened(true)
        },
        closeSignUp: () => {
          setSignUpModalOpened(false)
        },
        openSignUpVerification: () => {
          setSignUpVerificationOpened(true)
        },
        closeSignUpVerification: () => {
          setSignUpVerificationOpened(false)
        },
      }) as SignModalContextActions,
    [],
  )

  const value = useMemo(
    () => ({
      signInModalOpened,
      signUpModalOpened,
      signUpVerificationOpened,
      signInEntryType,
      actions,
    }),
    [signInModalOpened, signUpModalOpened, signUpVerificationOpened, signInEntryType, actions],
  )

  return <SignModalContext.Provider value={value}>{children}</SignModalContext.Provider>
}
