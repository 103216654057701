import { EuiModalBody, EuiModalHeader, EuiOverlayMask } from '@elastic/eui'
import Title from './texts/Text'
import Button from './button/Button'
import { ModalFooterActionType } from './Modal'
import Group from './Group'
import { IconButton } from '@mui/joy'
import { Close } from '@mui/icons-material'

interface Props {
  title?: string
  maxWidth?: string
  onClose: any // TODO
  children: React.ReactNode
  primaryAction?: ModalFooterActionType
  secondaryAction?: ModalFooterActionType
  fullOnMobile?: boolean // TODO
}

function Modal2({
  title,
  maxWidth,
  onClose,
  primaryAction,
  secondaryAction,
  children,
  fullOnMobile,
}: Props) {
  const marginHorz = fullOnMobile ? '0' : '1.5rem'
  return (
    <EuiOverlayMask>
      <div
        style={{
          width: maxWidth,
          maxWidth: maxWidth || '600px',
          background: 'var(--color-background)',
          marginLeft: marginHorz,
          marginRight: marginHorz,
          marginTop: '10vh',
          marginBottom: '0',
          borderRadius: 'var(--radius)',
          position: 'relative',
          overflowY: 'scroll',
          maxHeight: '75vh',
        }}
      >
        <div
          style={{
            position: 'absolute',
            right: '1rem',
            top: '1rem',
            zIndex: 10,
          }}
        >
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </div>
        {title && (
          <EuiModalHeader>
            <Title level="3">{title}</Title>
          </EuiModalHeader>
        )}
        <EuiModalBody>
          {children}
          <Group style={{ justifyContent: 'flex-end', marginTop: 'var(--space-4)' }}>
            {secondaryAction && (
              <Button variant="tertiary" onClick={secondaryAction.onClick || onClose}>
                {secondaryAction.label}
              </Button>
            )}
            {primaryAction && (
              <Button variant="primary" onClick={primaryAction.onClick}>
                {primaryAction.label}
              </Button>
            )}
          </Group>
        </EuiModalBody>
      </div>
    </EuiOverlayMask>
  )
}

export default Modal2
