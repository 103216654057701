import CustomizedPageBody from '../../component/CustomizedPageBody'

import { useNavigate } from 'react-router-dom'
import { apiHomeVideos } from '../../api/apis'
import { sampleChannelId } from '../../util/consts'

import Title from 'component/texts/Text'
import CustomizedSectionHead from 'component/CustomizedSectionHead'
import VideoPlaylist from '../../component/VideoPlaylist'
import { useQuery } from '@tanstack/react-query'
import FAQs from './FAQs'
import YotubeLogo from '../../component/YotubeLogo'

import './tossface.css'
import Avatar from 'component/Avatar'
import CategoryFilter from 'component/CategoryFilter'
import { Box, Button, Typography } from '@mui/joy'
import { MetaTags } from '../../util/MetaTags'
import * as React from 'react'

const Home = () => {
  const navigate = useNavigate()

  const { data: videos } = useQuery({
    queryKey: ['/v1/home/videos'],
    queryFn: () => apiHomeVideos(),
  })

  return (
    <CustomizedPageBody>
      <MetaTags description={description()} keywords={keywords()} />
      <div
        style={{
          padding: '6rem 2.5rem 0',
          marginTop: 'calc(var(--navbar-height) * -1)',
          marginLeft: '-1.5rem',
          marginRight: '-1.5rem',
          background: 'linear-gradient(var(--home-color-gradient1), var(--home-color-gradient2))',
        }}
      >
        <div
          className="home-hero"
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '2rem 0 6rem',
          }}
        >
          <Title level="1" data-temp="display">
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'center',
                gap: '0.5rem',
                justifyContent: 'center',
                wordBreak: 'keep-all',
              }}
            >
              <div style={{ height: '2rem' }}>
                <YotubeLogo />
              </div>
              <span>채널 성과 비교</span>
              <span
                style={{
                  display: 'inline-block',
                  width: '5px',
                  height: '5px',
                  margin: '0 2px',
                  borderRadius: '50%',
                  background: 'var(--color-background-strong)',
                }}
              ></span>
              <span>PPL 컬렉션</span>
            </div>
          </Title>
          <Typography level="title-lg" sx={{ mt: '1rem' }}>
            인플루언서 마케팅을 위한 데이터 분석 플랫폼
          </Typography>
        </div>
      </div>
      <div style={{ width: '100%', maxWidth: 'var(--layout-width-wide)', margin: '0 auto' }}>
        <CustomizedSectionHead title="PPL 동영상" to="/videos" />
        <CategoryFilter selected={''} actionType="navigate" />
        <Box sx={{ mt: '1rem' }}>
          <VideoPlaylist
            videos={videos?.videos}
            showChannelAvatar
            onClickVideo={(e, videoId) => {
              e.stopPropagation()
              e.preventDefault()
              navigate(`/videos`)
              navigate(`/videos/${videoId}`)
            }}
          />
        </Box>
      </div>

      <section style={{ margin: '8rem 0' }}>
        <FAQs />
      </section>

      <section
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '1.5rem',
          margin: '0 -1.5rem -4rem',
          padding: '6rem 1.5rem 7rem',
          backgroundColor: 'rgba(255, 122, 0, 0.08)',
        }}
      >
        <Title level="1" data-temp="display">
          인게이지먼트와 PPL 성과로 채널을 비교해보세요
        </Title>
        <Button
          size="lg"
          onClick={() => {
            navigate(`/channels/${sampleChannelId}`)
          }}
          sx={{ gap: '.5rem' }}
        >
          <Avatar
            size="sm"
            src={
              'https://yt3.ggpht.com/C7bTHnoo1S_MRbJXn4VwncNpB87C2aioJC_sKvgM-CGw_xgdxwiz0EFEqzj0SRVz6An2h81T4Q=s240-c-k-c0x00ffffff-no-rj'
            }
          />
          침착맨 채널 예시 보기 →
        </Button>
      </section>
    </CustomizedPageBody>
  )
}

const description = () => {
  return `유튜브 마케팅 협업 플랫폼 콜라블입니다. 채널 비교, 브랜드 성과 분석, 유튜버의 브랜드 샤라웃 발견으로 최적의 유튜버를 선택하세요. 브랜드에 딱 맞는 유튜버와 높은 광고효과의 채널을 찾아 효과적인 협업을 실현하세요. 데이터 기반의 인사이트로 더 나은 성과를 내보세요.`
}

const keywords = () => {
  return `유튜브 마케팅, 브랜드 분석, 인플루언서 협업, 채널 비교, 유료광고 성과, 브랜드 샤라웃, 채널 관리, 마케팅 전략 최적화, 유튜브 트렌드, 콘텐츠 크리에이터 분석, 데이터 기반 인사이트, 유튜브 생태계, 브랜드-유튜버 매칭, 광고 효과 측정`
}

export default Home
