import React from 'react'

interface Props {
  // variant?: 'flat' | 'subtle' | 'elevated'
  variant?: 'flat' | 'subtle'
  size?: 'small' | 'medium' | 'large'
  children: React.ReactNode
  style?: React.CSSProperties
  onClick?: VoidFunction
}

const tileVariants = []

const padding = { small: 'var(--space-2)', medium: 'var(--space-3)', large: 'var(--space-4)' }

const variants = {
  flat: {},
  subtle: {
    border: '0',
    backgroundColor: 'var(--color-background-weak)',
  },
  elevated: {
    boxShadow: 'var(--shadow)',
  },
}

function Tile({ variant, size, children, style, onClick }: Props) {
  return (
    <div
      onClick={onClick}
      style={{
        flex: 'auto',
        border: 'var(--border)',
        borderRadius: 'var(--radius)',
        padding: padding[size || 'medium'],
        background: 'var(--color-background)',
        position: 'relative',
        ...variants[variant || 'flat'],
        ...style,
      }}
    >
      {children}
    </div>
  )
}

export default Tile
