import dayjs from 'dayjs'
import { parseCount } from '../util/utils'
import Avatar from './Avatar'
import { Link, useNavigate } from 'react-router-dom'
import Group from './Group'
import Stat from './Stat'
import { Box, Typography } from '@mui/joy'

interface VideoItemProps {
  videoId: number
  videoKey: string
  highlight?: number
  channelId: number
  channelName?: string
  channelThumbnail?: string
  title: string
  viewCount?: number
  likeCount?: number
  commentCount?: number
  thumbnailUrl?: string
  publishedAt?: Date
  brandId?: number
  brandLogoUrl?: string
  brandName?: string
  productName?: string
}

interface Props {
  video: VideoItemProps
  showChannelAvatar?: boolean
  alignment?: 'vertical' | 'horizontal'
}

const VideoItemMinimum = ({ video, showChannelAvatar, alignment = 'vertical' }: Props) => {
  const isHorizontal = alignment === 'horizontal'
  return (
    <div
      style={{
        display: isHorizontal ? 'flex' : 'block',
        // width: '100%',
        gap: isHorizontal ? 'var(--space-4)' : '0',
        alignItems: 'flex-start',
      }}
    >
      <Link
        to={`/videos/${video.videoId}`}
        style={{ display: 'block', minWidth: '0', color: 'inherit' }}
      >
        <img
          src={video.thumbnailUrl}
          alt={`'${video.title}' video thumbnail`}
          style={{
            width: '140px',
            maxWidth: '30vw',
            borderRadius: 'var(--radius)',
          }}
        />
      </Link>
      <div
        style={{
          marginTop: isHorizontal ? '-.5rem' : '0',
          overflow: 'hidden',
        }}
      >
        {showChannelAvatar && (
          <Link to={`/channels/${video.channelId}`} style={{ color: 'inherit' }}>
            <Box display="flex" gap={1}>
              <Avatar size="sm" src={video.channelThumbnail} />
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Typography level="title-md">{video.channelName}</Typography>
                <Typography level="body-sm">{dayjs(video.publishedAt).fromNow()}</Typography>
              </Box>
            </Box>
          </Link>
        )}
        <Link
          to={`/videos/${video.videoId}`}
          style={{ display: 'block', minWidth: '0', color: 'inherit' }}
        >
          <Typography
            level="title-md"
            noWrap
            sx={{
              m: 'var(--space-2) 0 0',
            }}
          >
            {video.title}
          </Typography>
        </Link>
        <Group
          style={{
            gap: '2rem',
            marginTop: '.5rem',
          }}
        >
          <Stat label="조회" value={parseCount(video.viewCount)} />
          <Stat label="좋아요" value={parseCount(video.likeCount)} />
          <Stat label="댓글" value={parseCount(video.commentCount)} />
        </Group>
      </div>
    </div>
  )
}

export default VideoItemMinimum
