import { Box } from '@mui/joy'

interface Props {
  children: React.ReactNode
}

// TODO : minHeight 100vh 를 주면 내부 flex 요소로 인해 간격 문제

function CustomizedPageBody({ children }: Props) {
  return <Box paddingX={2}>{children}</Box>
}

export default CustomizedPageBody
