import Box from '@mui/joy/Box'
import * as React from 'react'
import Tile from '../../../component/Tile'
import Button from '../../../component/button/Button'
import ManagingChannelItem from './ManagingChannelItem'
import ChannelOwnershipRequestModal from './ChannelOwnershipRequestModal'
import { useMyChannels } from '../../../api/apis'
import { useNavigate } from 'react-router-dom'
import DashboardPageBody from '../../../layout/DashboardPageBody'
import { MetaTags } from '../../../util/MetaTags'
import { Alert } from '@mui/joy'

const ChannelManageList = () => {
  const { data: myChannels } = useMyChannels()
  const navigate = useNavigate()

  const [showRequestModal, setShowRequestModal] = React.useState(false)
  const isWaitingAccept = myChannels?.some(item => item.status === 'VERIFIED')

  return (
    <DashboardPageBody title="내 채널" breadcrumbs={[{ label: 'My Channels' }]}>
      <MetaTags title={`내 채널 · 콜라블`} description={description()} keywords={keywords()} />
      <Box>
        <div style={{ maxWidth: 'var(--layout-width-regular)' }}>
          <Tile style={{ padding: '2rem' }}>
            <Button variant="textAccent" size="s" onClick={() => setShowRequestModal(true)}>
              소유권 등록
            </Button>
            {isWaitingAccept && (
              <Alert sx={{ marginTop: '.5rem' }}>
                채널 소유권을 확인중입니다. 확인 후 메일로 결과를 알려드리겠습니다.
              </Alert>
            )}
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '0.5rem',
                marginTop: '.5rem',
              }}
            >
              {myChannels?.map((item, index) => {
                const { channelId, status } = item
                const activated = status === 'ACTIVATED'
                const onClick = activated
                  ? () => navigate(`/channels/manage/${channelId}`)
                  : undefined
                return (
                  <ManagingChannelItem
                    key={index}
                    ownership={item}
                    showBadge={true}
                    onClick={onClick}
                  />
                )
              })}
              {!!myChannels && !myChannels.length && <div>채널 소유권을 등록해주세요</div>}
            </div>
          </Tile>
          {showRequestModal && (
            <ChannelOwnershipRequestModal closeModal={() => setShowRequestModal(false)} />
          )}
        </div>
      </Box>
    </DashboardPageBody>
  )
}

const description = () => {
  return ``
}

const keywords = () => {
  return ``
}

export default ChannelManageList
