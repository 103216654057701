import Modal2 from '../component/Modal2'
import { useLocation, useNavigate } from 'react-router-dom'
import Divider from '../component/Divider'
import Button from '../component/button/Button'
import { useSignin } from '../api/apis'
import { useEffect, useRef, useState } from 'react'
import { useSignModalContext } from '../context/SignModalContext'
import { sampleChannelId } from '../util/consts'
import { useQueryClient } from '@tanstack/react-query'
import useLoginAuth from '../hooks/useLoginAuth'
import * as jose from 'jose'
import TextField from 'component/TextField'
import { Stack } from '@mui/joy'

const SignInModal = () => {
  const location = useLocation()
  const { signInModalOpened, signInEntryType, actions } = useSignModalContext()
  const { signIn } = useLoginAuth()
  const queryClient = useQueryClient()

  const navigate = useNavigate()
  const { mutate: signinMutate } = useSignin()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const emailRef = useRef<HTMLInputElement>(null)
  const passwordRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    const handlePopState = () => {
      actions.closeSignIn()
    }

    window.addEventListener('popstate', handlePopState)

    return () => {
      window.removeEventListener('popstate', handlePopState)
    }
  }, [actions])

  if (!signInModalOpened) {
    return null
  }

  const onClickSignin = () => {
    if (!verifySubmitData()) {
      return
    }
    const payload = {
      email,
      password,
    }

    signinMutate(payload, {
      onSuccess: data => {
        actions.closeSignIn()
        signIn(data.token)

        queryClient.clear()

        if (location.pathname === '/') {
          const decoded = jose.decodeJwt(data.token)
          if (decoded.roles === 'INFLUENCER') {
            window.location.href = '/channels/manage'
          } else {
            window.location.href = '/dashboard'
          }
        }
      },
    })
  }

  const verifySubmitData = () => {
    if (!email) {
      window.alert('이메일을 입력해주세요')
      emailRef?.current?.focus()
      return false
    }
    if (!password) {
      window.alert('비밀번호를 입력해주세요')
      passwordRef?.current?.focus()
      return false
    }
    return true
  }

  const onKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      onClickSignin()
      e.preventDefault()
      e.stopPropagation()
    }
  }

  const onClickSignup = () => {
    actions.closeSignIn()
    actions.openSignUp()
  }

  const onClickSignupAsYoutuber = () => {
    actions.closeSignIn()
    navigate(`/signup-youtuber`)
  }

  const secondaryAction =
    signInEntryType === 'CHANNEL_DETAIL'
      ? {
          label: '데모 데이터 보기',
          onClick: () => {
            navigate(`/channels/${sampleChannelId}`)
            actions.closeSignIn()
          },
        }
      : undefined

  return (
    <Modal2
      title="로그인"
      maxWidth="400px"
      onClose={() => {
        actions.closeSignIn()
      }}
      secondaryAction={secondaryAction}
    >
      {/*<Link*/}
      {/*  to={`${process.env.REACT_APP_SERVER}/oauth2/authorization/google`}*/}
      {/*  style={{*/}
      {/*    display: 'flex',*/}
      {/*    alignItems: 'center',*/}
      {/*    justifyContent: 'center',*/}
      {/*    height: '40px',*/}
      {/*    gap: 'var(--space-2)',*/}
      {/*    cursor: 'pointer',*/}
      {/*    border: '1px solid var(--border-color)',*/}
      {/*    borderRadius: '6px',*/}
      {/*    padding: '0 1rem',*/}
      {/*    color: 'var(--color-text)',*/}
      {/*    fontWeight: 'var(--font-weight-bold)',*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <img src="/image/google.png" alt="google logo" style={{ width: '1.5rem' }} />*/}
      {/*  <span>Google로 시작하기</span>*/}
      {/*</Link>*/}
      {/* 로그인은 개인 정보 보호 정책 및 서비스 약관에 동의하는 것을 의미하며, 서비스 이용을 위해 이메일과 이름, 프로필 이미지를 수집합니다. */}
      {signInEntryType === 'CHANNEL_DETAIL' && (
        <div style={{ lineHeight: '1.4rem' }}>
          로그인 하시면 채널의 상세 정보를 확인하실 수 있습니다.
        </div>
      )}
      <Divider marginBlock={20} />
      <form>
        <Stack direction="column" spacing={1}>
          <TextField
            label="이메일(아이디)"
            name="email"
            value={email}
            onChange={(e: any) => {
              setEmail(e.target.value)
            }}
            placeholder="example@gmail.com"
            inputRef={emailRef}
          />

          <TextField
            label="비밀번호"
            name="password"
            value={password}
            onChange={(e: any) => {
              setPassword(e.target.value)
            }}
            type="password"
            placeholder="********"
            inputRef={passwordRef}
            onKeyDown={onKeyDown}
          />
          <Button variant="primary" onClick={onClickSignin} fullWidth>
            로그인
          </Button>
        </Stack>
      </form>
      <div style={{ textAlign: 'center', marginTop: '.5rem' }}>
        <Button variant="text" onClick={onClickSignup}>
          가입하기
        </Button>
      </div>
      <div style={{ textAlign: 'center' }}>
        <Button variant="text" onClick={onClickSignupAsYoutuber}>
          유튜버로 가입하기
        </Button>
      </div>
      {/*<Tile size="large">*/}
      {/*    <span>크리에이터이신가요?</span>*/}
      {/*    <Button variant="textAccent" href="/signup-youtuber">*/}
      {/*      <b>크리에이터로 가입하기</b>*/}
      {/*    </Button>*/}
      {/*</Tile>*/}
    </Modal2>
  )
}

export default SignInModal
