import ViewAllButton from './ViewAllButton'
import Title from './texts/Text'

interface Props {
  title: string
  to?: string
  slot?: React.ReactNode
}

function CustomizedSectionHead({ title, to, slot }: Props) {
  return (
    <header
      style={{ display: 'flex', alignItems: 'center', gap: 'var(--space-4)', marginBottom: '1rem' }}
    >
      <Title level="2">{title}</Title>
      {to && <ViewAllButton to={to} />}
      {slot && slot}
    </header>
  )
}

export default CustomizedSectionHead
