import Box from '@mui/joy/Box'

import { useMyChannels } from '../../../api/apis'
import { useParams } from 'react-router-dom'
import { Link } from '@mui/joy'
import ManagingChannelItem from '../ChannelManageList/ManagingChannelItem'
import Tabs, { TabPanel, useTab } from '../../../component/tab/Tab'
import ContentsManage from './ContentsManage'
import ManageShoutout from './ManageShoutout'
import OfferManage from './OfferManage'
import { Option } from '../../../api/types'
import ChannelManageTab from './ChannelManageTab'
import DashboardPageBody from '../../../layout/DashboardPageBody'
import { MetaTags } from '../../../util/MetaTags'
import * as React from 'react'

const tabOptions: Option[] = [
  { label: '채널 관리', value: 'channel' },
  { label: '콘텐츠 관리', value: 'contents' },
  { label: '샤라웃 관리', value: 'shoutout' },
  { label: '제안 관리', value: 'audience' },
]

const ChannelManage = () => {
  const { channelId } = useParams()
  const { data: myChannels } = useMyChannels()
  const [selectedTab, handleTabClick] = useTab(tabOptions[0].value)

  const selectedChannel = myChannels?.find(channel => channel.channelId === Number(channelId))

  return (
    <DashboardPageBody
      title="채널 관리"
      breadcrumbs={[
        { label: '채널 관리', link: '/channel' },
        { label: selectedChannel?.channelName || '', link: `/channel/${channelId}` },
      ]}
    >
      <MetaTags title={`채널 관리 · 콜라블`} description={description()} keywords={keywords()} />
      <Box>
        <div
          style={{
            maxWidth: 'var(--layout-width-regular)',
            padding: '1rem 1.5rem',
          }}
        >
          <ManagingChannelItem
            ownership={selectedChannel}
            showBadge={false}
            showGoToChannelButton={true}
          />
          <div style={{ height: '.5rem' }} />
          <Tabs tabs={tabOptions} selected={selectedTab} onClick={handleTabClick} />
          <div style={{ height: '1.25rem' }} />
          <TabPanel value={tabOptions[0].value} selected={selectedTab}>
            <ChannelManageTab channelId={Number(channelId)} />
          </TabPanel>
          <TabPanel value={tabOptions[1].value} selected={selectedTab}>
            <ContentsManage channelId={Number(channelId)} />
          </TabPanel>
          <TabPanel value={tabOptions[2].value} selected={selectedTab}>
            <ManageShoutout channelId={Number(channelId)} />
          </TabPanel>
          <TabPanel value={tabOptions[3].value} selected={selectedTab}>
            <OfferManage channelId={Number(channelId)} />
          </TabPanel>
        </div>
      </Box>
    </DashboardPageBody>
  )
}

const description = () => {
  return ``
}

const keywords = () => {
  return ``
}

export default ChannelManage
