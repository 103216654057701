import Box from '@mui/joy/Box'
import Breadcrumbs from '@mui/joy/Breadcrumbs'
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded'
import HomeRoundedIcon from '@mui/icons-material/HomeRounded'
import Typography from '@mui/joy/Typography'
import { useState } from 'react'
import { EuiBasicTable, RIGHT_ALIGNMENT } from '@elastic/eui'
import Button from '../../../component/button/Button'
import BookmarkGroupCreateModal from '../../../modal/BookmarkGroupCreateModal'
import { useGetBookmarkGroupDetail, useGetBookmarks } from '../../../api/apis'
import { Link as RouterLink, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { Link, Stack } from '@mui/joy'
import { EuiTableFieldDataColumnType } from '@elastic/eui/src/components/basic_table/table_types'
import { SearchChannelResponseItem } from '../../../api/types'
import { ChannelItem } from '../../../component/ChannelItem'
import { ValueCell } from 'page/Dashboard/CollectionDetail/ValueCell'
import DashboardPageBody from '../../../layout/DashboardPageBody'
import { MetaTags } from '../../../util/MetaTags'
import * as React from 'react'

const CollectionDetail = () => {
  const navigate = useNavigate()
  const { collectionId } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()

  const sort = searchParams.get('sort') || 'follower'

  const { data: bookmarks, isLoading } = useGetBookmarks(collectionId, sort)
  const { data: bookmarkGroup } = useGetBookmarkGroupDetail(collectionId)

  const [showBookmarkGroupCreateModal, setShowBookmarkGroupCreateModal] = useState(false)

  const handleCompareClick = (channelId: number) => {
    navigate(`/channel-compare?channels=${channelId}`)
  }

  const columns: Array<EuiTableFieldDataColumnType<SearchChannelResponseItem>> = [
    {
      field: 'name',
      name: '채널명',
      width: '240px',
      render: (_, channel: SearchChannelResponseItem) => (
        <RouterLink to={`/channels/${channel.channelId}`} style={{ flex: 1 }}>
          <ChannelItem channel={channel} avatarSize={'sm'} />
        </RouterLink>
      ),
    },
    {
      field: 'recentAdContentCount',
      name: '최근 3개월 PPL',
      width: '100px',
      align: RIGHT_ALIGNMENT,
      render: (_, channel: SearchChannelResponseItem) => (
        <ValueCell count={channel.recentAdContentCount} unit="회" />
      ),
    },
    {
      field: 'avgLikeCount',
      name: '조회수 평균',
      align: RIGHT_ALIGNMENT,
      width: '100px',
      render: (_, channel: SearchChannelResponseItem) => (
        <ValueCell count={channel.avgViewCount} rate={channel.avgViewRate} unit="회" />
      ),
    },
    {
      field: 'avgViewCount',
      name: '좋아요 평균',
      align: RIGHT_ALIGNMENT,
      width: '90px',
      render: (_, channel: SearchChannelResponseItem) => (
        <ValueCell count={channel.avgLikeCount} rate={channel.avgLikeRate} unit="회" />
      ),
    },
    {
      field: 'avgCommentCount',
      name: '댓글 평균',
      align: RIGHT_ALIGNMENT,
      width: '90px',
      render: (_, channel: SearchChannelResponseItem) => (
        <ValueCell count={channel.avgCommentCount} rate={channel.avgCommentRate} unit="회" />
      ),
    },
    {
      field: 'avgCommentCount',
      name: '비교',
      align: RIGHT_ALIGNMENT,
      width: '90px',
      render: (_, channel: SearchChannelResponseItem) => (
        <Button size="s" variant="tertiary" onClick={() => handleCompareClick(channel.channelId)}>
          비교
        </Button>
      ),
    },
  ]

  const channels = bookmarks?.bookmarks.map(bookmark => bookmark.channel)
  const modalDefaultData = bookmarkGroup
    ? {
        bookmarkGroupId: Number(collectionId),
        name: bookmarkGroup.name,
        shareType: bookmarkGroup.shareType,
      }
    : undefined

  return (
    <DashboardPageBody
      title={
        <Stack direction={'row'} gap={1}>
          <Typography level="h2" component="h1">
            {bookmarkGroup?.name}
          </Typography>
          {bookmarkGroup?.owner && (
            <Button
              variant="tertiary"
              size="s"
              onClick={() => {
                setShowBookmarkGroupCreateModal(true)
              }}
            >
              수정
            </Button>
          )}
        </Stack>
      }
      breadcrumbs={[
        { label: '컬렉션', link: '/collections' },
        { label: bookmarkGroup?.name || '', link: `/collections/${collectionId}` },
      ]}
    >
      <MetaTags
        title={`${bookmarkGroup?.name} · 콜라블`}
        description={description()}
        keywords={keywords()}
      />
      <Box maxWidth="var(--layout-width-narrow)" paddingTop="1rem">
        <EuiBasicTable
          tableCaption="채널 데이터"
          items={channels || []}
          columns={columns}
          tableLayout={'fixed'}
          onChange={() => {}}
          loading={isLoading}
          noItemsMessage={<div style={{ minHeight: '500px' }} />}
        />
        {showBookmarkGroupCreateModal && (
          <BookmarkGroupCreateModal
            data={modalDefaultData}
            closeModal={() => {
              setShowBookmarkGroupCreateModal(false)
            }}
          />
        )}
      </Box>
    </DashboardPageBody>
  )
}

const description = () => {
  return ``
}

const keywords = () => {
  return ``
}

export default CollectionDetail
