import * as React from 'react'
import CategoryFilter from '../../../component/CategoryFilter'
import BrandDashboardItem from './BrandDashboardItem'
import { useSearchParams } from 'react-router-dom'
import { apiPPLBrands, GetBrandPPLRequest } from '../../../api/apis'
import { useQuery } from '@tanstack/react-query'
import DashboardPageBody from '../../../layout/DashboardPageBody'
import { MetaTags } from '../../../util/MetaTags'

const BrandExplore = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const search = searchParams.get('search') || ''
  const categoryCode = searchParams.get('categoryCode') || ''
  const page = Number(searchParams.get('page') || 1)

  const searchPayload = {
    search: search,
    categoryCode: categoryCode,
    page: page - 1,
    pageSize: 10,
  } as GetBrandPPLRequest

  const { data: brands } = useQuery({
    queryKey: [`/v1/brands/ppl`, searchPayload],
    queryFn: () => apiPPLBrands(searchPayload),
    placeholderData: (previousData, _) => previousData,
  })

  const updateQuery = (key: keyof GetBrandPPLRequest, value: string) => {
    searchParams.set(key, value)
    setSearchParams(searchParams)
  }

  return (
    <DashboardPageBody title={'브랜드별 유료광고'} breadcrumbs={[{ label: 'Brands' }]}>
      <MetaTags
        title={`브랜드별 유료광고 · 콜라블`}
        description={description()}
        keywords={keywords()}
      />
      <div style={{ width: '100%', maxWidth: 'var(--layout-width-wide)', marginBottom: '1rem' }}>
        <CategoryFilter
          selected={categoryCode}
          actionType="updateQuery"
          updateQuery={updateQuery}
        />
      </div>
      <div style={{ width: '100%', maxWidth: 'var(--layout-width-regular)' }}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem', marginTop: '1.5rem' }}>
          {brands?.map((brand, index) => <BrandDashboardItem brand={brand} rank={index + 1} />)}
        </div>
      </div>
    </DashboardPageBody>
  )
}

const description = () => {
  return `유튜브 브랜드별 유료광고(PPL) 분석 플랫폼. 최근 광고 활동이 활발한 브랜드를 확인하고, 뷰티, 패션, 테크 등 다양한 카테고리의 브랜드 광고를 탐색할 수 있습니다. 특정 브랜드의 광고 전략을 검색하고 분석해보세요. 효과적인 브랜드 마케팅 인사이트를 얻어보세요.`
}

const keywords = () => {
  return `유튜브 브랜드 광고, PPL 분석, 브랜드별 유료광고, 뷰티 브랜드, 패션 브랜드, 테크 브랜드, 식음료 브랜드, 게임 브랜드, 브랜드 광고 전략, 유튜브 마케팅, 인플루언서 콜라보레이션, 브랜드 검색, 광고 트렌드, 마케팅 인사이트, 브랜드 인지도, 유튜브 광고 성과`
}

export default BrandExplore
