import { CSSProperties } from 'react'
import { Stack } from '@mui/joy'

const logoStyles = {
  fontFamily: 'Poetsen One',
  fontWeight: '400',
  // color: '#3c3d47',
  color: 'var(--color-text)',
  fontSize: '20px',
  lineHeight: '20px',
}

const betaStyle = {
  fontFamily: 'Poetsen One',
  fontWeight: '400',
  color: 'var(--color-text-light)',
  fontSize: '12px',
  textAlign: 'right',
  lineHeight: '12px',
} as CSSProperties

function Logo() {
  return (
    <Stack direction={'row'} alignItems={'baseline'} gap={0.5}>
      <div style={logoStyles}>collable</div>
      <div style={betaStyle}>beta</div>
    </Stack>
  )
}

export default Logo
