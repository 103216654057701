import { EuiModal, EuiModalBody, EuiModalFooter, EuiModalHeader } from '@elastic/eui'
import Title from './texts/Text'
import Button from './button/Button'
import { useCallback, useState } from 'react'

export type ModalFooterActionType = {
  label: string
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
}

interface Props {
  title: string
  onClose: any // TODO
  children: React.ReactNode
  primaryAction?: ModalFooterActionType
  secondaryAction?: ModalFooterActionType
}

function Modal({ title, onClose, primaryAction, secondaryAction, children }: Props) {
  return (
    <EuiModal onClose={onClose} maxWidth="600px">
      <EuiModalHeader>
        <Title level="3">{title}</Title>
      </EuiModalHeader>
      <EuiModalBody>{children}</EuiModalBody>
      <EuiModalFooter>
        {secondaryAction && (
          <Button variant="tertiary" onClick={secondaryAction.onClick || onClose}>
            {secondaryAction.label}
          </Button>
        )}
        {primaryAction && (
          <Button variant="primary" onClick={primaryAction.onClick}>
            {primaryAction.label}
          </Button>
        )}
      </EuiModalFooter>
    </EuiModal>
  )
}

export default Modal

export function useModal(initialState = false) {
  const [opened, setOpened] = useState(initialState)
  const toggleModal = useCallback(() => setOpened((prevState: any) => !prevState), [opened])

  return { opened, toggleModal }
}
