import React from 'react'
import { CircularProgress } from '@mui/joy'
import Box from '@mui/joy/Box'

interface Props {
  height?: string
}

const SpinnerWithBox = ({ height }: Props) => {
  const h = height || '100px'
  return (
    <Box width={'100%'} height={h} display={'flex'} alignItems={'center'} justifyContent={'center'}>
      <CircularProgress size={'sm'} />
    </Box>
  )
}
export default SpinnerWithBox
