import { EuiShowFor } from '@elastic/eui'
import lodash from 'lodash'
import Group from 'component/Group'
import { parseCount } from '../../../util/utils'
import BookmarkManageModal from './BookmarkManageModal'
import Avatar from 'component/Avatar'
import { useNavigate } from 'react-router-dom'
import { SearchChannelResponseItem } from '../../../api/types'
import Button from '../../../component/button/Button'
import { useModal } from 'component/Modal'
import { IconSquareRoundedPlus } from '@tabler/icons-react'
import { Box, Chip, Typography } from '@mui/joy'

interface Props {
  // TODO any
  channelData?: SearchChannelResponseItem | any
  showFixedHeader: boolean
}

const ChannelHeader = ({ channelData, showFixedHeader }: Props) => {
  const navigate = useNavigate()
  const { opened, toggleModal } = useModal(false)

  const handleCompareClick = (channelId: number) => {
    navigate(`/channel-compare?channels=${channelId}`)
  }

  return (
    <>
      {/* {showFixedHeader && scrolled && (
        <>
          <header
            style={{
              display: 'flex',
              background: 'var(--color-background)',
              padding: '1rem 5vw',
              borderBottom: '1px solid var(--color-border)',
              position: 'fixed',
              top: '0',
              left: '0',
              right: '0',
              zIndex: '10000',
            }}
          >
            <div style={{ width: 'var(--space-3)' }}></div>
            <ChannelItem channel={channelData} />
            <Group></Group>
          </header>
          <div style={{ height: HEADER_HEIGHT }}></div>
        </>
      )} */}
      {/* {!scrolled && ( */}
      <header
        style={{
          gap: 'var(--space-3)',
          display: 'flex',
          justifyContent: 'space-between',
          // margin: '0 -2rem',
          padding: '1.5rem',
          borderRadius: 'var(--radius)',
          background: 'var(--color-background-weak)',
          position: 'relative',
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: { sm: 'column', md: 'row' },
            justifyContent: 'space-between',
          }}
        >
          <Box>
            <div
              style={{
                gap: 'var(--space-3)',
                display: 'flex',
              }}
            >
              <a
                href={`https://www.youtube.com/channel/${channelData?.channelKey}`}
                target="_blank"
                rel="noreferrer"
              >
                <EuiShowFor sizes={['l', 'xl']}>
                  <Avatar size="lg" src={channelData?.thumbnailUrl} />
                </EuiShowFor>
                <EuiShowFor sizes={['xs', 's', 'm']}>
                  <Avatar size="lg" src={channelData?.thumbnailUrl} />
                </EuiShowFor>
              </a>
              <div>
                <Typography level="title-lg">{channelData?.name}</Typography>
                <EuiShowFor sizes={['l', 'xl']}>
                  {!!channelData && !!channelData.tags && (
                    <Group style={{ margin: 'var(--space-1) 0 var(--space-3)' }}>
                      {lodash
                        .chain(channelData.tags)
                        .split(',')
                        .take(4)
                        .map((tag: string) => (
                          <Chip key={tag} sx={{ px: 0, bgcolor: 'transparent' }}>
                            #{tag}
                          </Chip>
                        ))
                        .value()}
                    </Group>
                  )}
                </EuiShowFor>
                <div>
                  <span>구독자</span> <b>{parseCount(channelData?.followerCount)}명</b>
                </div>
              </div>
            </div>
          </Box>
          <Box>
            <Group spacing="4" style={{ marginLeft: 'auto', justifyContent: 'flex-end' }}>
              <Button variant={'text'} size="s" onClick={toggleModal}>
                <IconSquareRoundedPlus size="18px" />
                <span style={{ marginLeft: 'var(--space-1)' }}>컬렉션 추가</span>
              </Button>
              <Button size="s" onClick={() => handleCompareClick(channelData?.channelId)}>
                채널 비교
              </Button>
            </Group>
          </Box>
        </Box>
      </header>
      {/* <ChannelSummaryStats channelData={channelData} /> */}
      {opened && (
        <BookmarkManageModal channelId={channelData!!.channelId} closeModal={toggleModal} />
      )}
    </>
  )
}

export default ChannelHeader
