import { IconClipboardList } from '@tabler/icons-react'
import { useSignModalContext } from '../../../context/SignModalContext'
import Title from '../../../component/texts/Text'
import Button from '../../../component/button/Button'

export function EmptyState() {
  const { actions } = useSignModalContext()

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        gap: 'var(--space-4)',
        padding: '2rem 0',
      }}
    >
      <IconClipboardList size="40px" />
      <Title level="2">컬렉션을 만들어 채널을 손쉽게 관리하세요.</Title>
      <p>관심있는 채널을 모아서 한 눈에 분석하고 간편하게 비교할 수 있어요.</p>
      <Button
        variant="primary"
        onClick={() => {
          actions.openSignIn('OTHER')
        }}
      >
        시작하기
      </Button>
    </div>
  )
}
