import Modal2 from '../component/Modal2'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { EuiCheckboxGroup } from '@elastic/eui'
import { useSignin, useSignupAdvertiser } from '../api/apis'
import { useEffect, useRef, useState } from 'react'
import Logo from '../component/Logo'
import Title from '../component/texts/Text'
import { useSignModalContext } from '../context/SignModalContext'
import TextField from 'component/TextField'
import { Stack } from '@mui/joy'

const SignUpModal = () => {
  const location = useLocation()
  const { signUpModalOpened, actions } = useSignModalContext()

  const { mutate: signupMutate } = useSignupAdvertiser()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [repassword, setRepassword] = useState('')
  const [corpName, setCorpName] = useState('')
  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')

  const emailRef = useRef<HTMLInputElement>(null)

  const [checkboxIdToSelectedMap, setCheckboxIdToSelectedMap] = useState<{
    [key: string]: boolean
  }>({
    agree1: false,
    agree2: false,
  })

  useEffect(() => {
    actions.closeSignUp()
  }, [location, actions])

  if (!signUpModalOpened) {
    return null
  }
  const onCheckboxChange = (optionId: string) => {
    const newCheckboxIdToSelectedMap = {
      ...checkboxIdToSelectedMap,
      ...{
        [optionId]: !checkboxIdToSelectedMap[optionId],
      },
    }

    setCheckboxIdToSelectedMap(newCheckboxIdToSelectedMap)
  }

  const onClickSubmit = () => {
    if (!verifySubmitData()) {
      return
    }

    const { agree1, agree2 } = checkboxIdToSelectedMap
    const payload = {
      email,
      password,
      repassword,
      corpName,
      name,
      position: '',
      phone,
      agree1,
      agree2,
    }

    signupMutate(payload, {
      onSuccess: data => {
        actions.closeSignUp()
        actions.openSignUpVerification()
      },
    })
  }

  const verifySubmitData = () => {
    if (!email) {
      window.alert('이메일를 입력해주세요')
      emailRef?.current?.focus()
      return false
    }
    if (!password) {
      window.alert('비밀번호를 입력해주세요')
      return false
    }
    if (!repassword) {
      window.alert('비밀번호를 확인을 입력해주세요')
      return false
    }
    if (password !== repassword) {
      window.alert('비밀번호가 일치하지 않습니다')
      return false
    }
    if (!corpName) {
      window.alert('기업명을 입력해주세요')
      return false
    }
    if (!name) {
      window.alert('담당자 이름을 입력해주세요')
      return false
    }
    if (!phone) {
      window.alert('담당자 휴대전화번호를 입력해주세요')
      return false
    }
    if (!checkboxIdToSelectedMap['agree1']) {
      window.alert('서비스 이용약관에 동의해야 가입이 가능합니다')
      return false
    }

    return true
  }

  return (
    <Modal2
      onClose={() => {
        actions.closeSignUp()
      }}
      primaryAction={{
        label: '가입하기',
        onClick: onClickSubmit,
      }}
    >
      <header
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 'var(--space-5)',
          padding: 'var(--space-3) 0',
          marginBottom: '1rem',
        }}
      >
        <Link to="/">
          <Logo />
        </Link>
        <Title level="3">광고주 또는 광고대행사로 가입하기</Title>
      </header>
      {/* TODO TAB? */}
      <Stack direction="column" spacing={1}>
        <TextField
          label="이메일"
          name="email"
          value={email}
          onChange={(e: any) => {
            setEmail(e.target.value)
          }}
          placeholder="회사 이메일을 입력"
          inputRef={emailRef}
        />

        <TextField
          label="비밀번호"
          name="password"
          value={password}
          onChange={(e: any) => {
            setPassword(e.target.value)
          }}
          type="password"
          placeholder="8자 이상 입력"
        />

        <TextField
          label="비밀번호 확인"
          name="repassword"
          value={repassword}
          onChange={(e: any) => {
            setRepassword(e.target.value)
          }}
          type="password"
          placeholder="8자 이상 입력"
        />

        <TextField
          label="기업명"
          name="corpName"
          value={corpName}
          onChange={(e: any) => {
            setCorpName(e.target.value)
          }}
          placeholder="기업명 입력"
        />

        <TextField
          label="담당자 이름"
          name="name"
          value={name}
          onChange={(e: any) => {
            setName(e.target.value)
          }}
          placeholder="이름 또는 닉네임 입력"
        />

        <TextField
          label="담당자 휴대전화 번호"
          name="phone"
          value={phone}
          onChange={(e: any) => {
            setPhone(e.target.value)
          }}
          placeholder="- 없이 입력"
        />

        <EuiCheckboxGroup
          options={checkboxes}
          idToSelectedMap={checkboxIdToSelectedMap}
          onChange={onCheckboxChange}
        />
      </Stack>
    </Modal2>
  )
}

const checkboxes = [
  {
    id: 'agree1',
    label: (
      <div style={{ display: 'flex', gap: '0.5rem', fontSize: '12px' }}>
        <span>(필수) 서비스 이용 약관에 동의합니다.</span>
        <a href="/terms-of-service" target="_blank">
          보기
        </a>
      </div>
    ),
  },
  {
    id: 'agree2',
    label: (
      <div style={{ display: 'flex', gap: '0.5rem', fontSize: '12px' }}>
        <span>(선택) 마케팅 정보 및 혜택 안내 수신에 동의합니다.</span>
        <a href="/privacy-policy" target="_blank">
          보기
        </a>
      </div>
    ),
  },
]

export default SignUpModal
