import Tile from '../../../component/Tile'
import { VideoSummaryResponseItem } from '../../../api/types'
import { parseCount } from '../../../util/utils'
import { makeBlockItems } from './Utils'
import Avatar from 'component/Avatar'
import Title from 'component/texts/Text'
import { Grid, Typography } from '@mui/joy'

const ChannelCompareTop3 = ({ videos }: { videos: VideoSummaryResponseItem[] | undefined }) => {
  if (!videos) return null

  const { viewCountTop3, likeCountTop3, commentCountTop3 } = makeBlockItems(videos)

  const blocks = [
    {
      title: '조회수 TOP3',
      items: viewCountTop3,
      valueFn: (v: VideoSummaryResponseItem) => `${parseCount(v.viewCount)}회`,
    },
    {
      title: '좋아요 TOP3',
      items: likeCountTop3,
      valueFn: (v: VideoSummaryResponseItem) => `${parseCount(v.likeCount)}개`,
    },
    {
      title: '코멘트 TOP3',
      items: commentCountTop3,
      valueFn: (v: VideoSummaryResponseItem) => `${parseCount(v.commentCount)}개`,
    },
  ]

  return (
    <Grid container spacing={1}>
      {blocks.map(block => (
        <Grid xs={12} sm={3} md={4}>
          <Title level="3">{block.title}</Title>
          <div style={{ height: 'var(--space-2)' }} />
          <Tile size="large">
            <div style={{ display: 'flex', flexDirection: 'column', gap: 'var(--space-3)' }}>
              {block.items.map(video => (
                <ChannelItem video={video} valueFn={block.valueFn} />
              ))}
            </div>
          </Tile>
        </Grid>
      ))}
    </Grid>
  )
}

const ChannelItem = ({
  video,
  valueFn,
}: {
  video: VideoSummaryResponseItem
  valueFn: (video: VideoSummaryResponseItem) => string
}) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
      <Avatar src={video.channelThumbnail} size={'sm'} />
      <Typography level="title-md" sx={{ maxHeight: '1.4rem', overflow: 'hidden', flex: 1 }}>
        {video.channelName}
      </Typography>
      <Typography level="body-sm" sx={{ width: '60px', textAlign: 'right' }}>
        {valueFn(video)}
      </Typography>
    </div>
  )
}

export default ChannelCompareTop3
