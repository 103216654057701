import { IconCaretDownFilled } from '@tabler/icons-react'
import { SearchVideoParams } from 'api/apis'

type SelectOptionValue = string
export type SelectOption = {
  value: string
  text: SelectOptionValue
}

// MEMO : 모든 select가 filter와 sort를 위한 것이 아니라서 정리를 해줘야 할 수 있음.

interface SelectProps {
  options: SelectOption[]
  name?: string
  value: SelectOptionValue
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void
  placeholder?: string
}

function CustomizedSelect({ options, name, value, onChange, placeholder }: SelectProps) {
  return (
    <div>
      <select name={name} placeholder={placeholder} onChange={onChange}>
        {options.map((option: SelectOption) => (
          <option key={option.value} value={option.value} selected={option.value === value}>
            {option.text}
          </option>
        ))}
      </select>
      {/* <span
        style={{
          marginTop: '2px',
          position: 'absolute',
          right: 'var(--space-3)',
        }}
      >
        <IconCaretDownFilled size={16} stroke={2} strokeLinejoin="miter" />
      </span> */}
    </div>
  )
}

export default CustomizedSelect
