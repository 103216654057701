import { Link } from 'react-router-dom'
import { EuiTableFieldDataColumnType } from '@elastic/eui/src/components/basic_table/table_types'
import { parseCount } from '../../../util/utils'
import { ChannelItem } from 'component/ChannelItem'
import { SearchChannelResponseItem } from '../../../api/types'
import { Typography } from '@mui/joy'

export const channelListColumns: Array<EuiTableFieldDataColumnType<SearchChannelResponseItem>> = [
  {
    field: 'name',
    name: '채널명',
    width: '400px',
    style: { cursor: 'pointer' },
    render: (_, channel: SearchChannelResponseItem) => (
      <Link to={`/channels/${channel.channelId}`}>
        <ChannelItem channel={channel} />
      </Link>
    ),
  },
  {
    field: 'name',
    name: 'PPL 제작 비율',
    // align: RIGHT_ALIGNMENT,
    render: (_, channel: SearchChannelResponseItem) => (
      <div style={{ width: '100%' }}>
        <style jsx>
          {`
            .meter2 {
              display: flex;
              flex-direction: column;
              gap: var(--space-1);
            }
            .meter2-track {
              height: 0.5rem;
              background-color: var(--color-background-weak);
              border-radius: var(--radius);
              overflow: hidden;
              flex: auto;
            }
            .meter2-thumb {
              display: block;
              width: 90%;
              height: inherit;
              background: skyblue;
            }
            .meter2-text {
              display: block;
              font-size: 12px;
            }
          `}
        </style>
        <div className="meter2">
          <div className="meter2-track">
            <b
              className="meter2-thumb"
              style={{
                width: `${(
                  (channel.recentAdContentCount / channel.recentContentCount) *
                  100
                ).toFixed(2)}%`,
              }}
            />
          </div>
          <div style={{ display: 'flex', gap: 'var(--space-1)' }}>
            <span className="meter2-text">전체 {channel.recentContentCount}개 중</span>
            <b className="meter2-text">{channel.recentAdContentCount}개</b>
          </div>
        </div>
        {/* 
          <b>{((channel.recentAdContentCount / channel.recentContentCount) * 100).toFixed(2)}%</b>
          <p>
            {channel.recentAdContentCount} of {channel.recentContentCount}
          </p> */}
      </div>
    ),
  },
  // {
  //   field: 'name',
  //   name: '최근 광고',
  //   align: RIGHT_ALIGNMENT,
  //   render: (_, channel: SearchChannelResponseItem) => (
  //     <p>{parseCount(channel.totalAdCount)}</p>
  //   ),
  // },
  {
    field: 'name',
    name: '조회수 평균 ',
    render: (_, channel: SearchChannelResponseItem) => (
      <Typography>{parseCount(channel.avgViewCount)}</Typography>
    ),
  },
  {
    field: 'name',
    name: '좋아요수 평균 ',
    render: (_, channel: SearchChannelResponseItem) => (
      <Typography>{parseCount(channel.avgLikeCount)}</Typography>
    ),
  },
  {
    field: 'name',
    name: '댓글수 평균',
    render: (_, channel: SearchChannelResponseItem) => (
      <Typography>{parseCount(channel.avgCommentCount)}</Typography>
    ),
  },
]
