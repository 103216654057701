interface Props {
  level: '1' | '2' | '3'
  children: React.ReactNode | string | number
  style?: React.CSSProperties
}

// 1, 2, 3 순서대로 제목의 크기. 다른 사이즈가 필요하면 heading, p 등의 태그에 인라인 스타일링하기

const styles = {
  1: {
    fontSize: '2.25rem',
    fontWeight: 'bold',
  },
  2: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
  },
  3: {
    fontSize: '1.25rem',
    fontWeight: 'bold',
  },
}

// TODO semantic tag
function Title({ level, style, children }: Props) {
  return <p style={{ ...styles[level], ...style }}>{children}</p>
}

export default Title
