import Box from '@mui/joy/Box'
import Typography from '@mui/joy/Typography'
import * as React from 'react'
import DashboardPageBody from '../../../layout/DashboardPageBody'
import { Stack } from '@mui/joy'
import Grid from '@mui/joy/Grid'
import { getPublishedAt, parseCount, parseRatio, parseSeconds } from '../../../util/utils'
import { VideoStatisticsItem } from '../../../component/VideoStatisticsItem'
import { Link as RouterLink, useParams } from 'react-router-dom'
import { ChannelItem } from '../../../component/ChannelItem'
import dayjs from 'dayjs'
import Card from '@mui/joy/Card'
import Avatar from '../../../component/Avatar'
import { CloseRounded, FormatQuoteRounded } from '@mui/icons-material'
import { apiGetVideo, apiSearchVideo, SearchVideoParams } from '../../../api/apis'
import { useQuery } from '@tanstack/react-query'
import queryKeys from '../../../api/queryKeys'
import { MetaTags } from '../../../util/MetaTags'
import { VideoSummaryResponseItem } from '../../../api/types'

const VideoDetail = () => {
  const { videoId } = useParams()

  const { data: video } = useQuery({
    queryKey: ['/v1/videos', videoId],
    queryFn: () => apiGetVideo(Number(videoId!!)),
    enabled: !!videoId,
  })

  const videoParams = {
    ...getPublishedAt('3M'),
    channelId: Number(video?.channelId),
    sort: 'latest',
    adOnly: true,
    pageSize: 4,
  } as SearchVideoParams

  const { data: otherVideos } = useQuery({
    queryKey: queryKeys.videoSearch(videoParams),
    queryFn: () => apiSearchVideo(videoParams),
    enabled: !!video,
  })

  if (!video) {
    return null
  }

  const filteredOtherVideos = otherVideos?.videos
    .filter(v => v.videoId !== video.videoId)
    .slice(0, 3)

  return (
    <DashboardPageBody
      breadcrumbs={[
        { label: video.channelName || '', link: `/channels/${video.channelId}` },
        { label: video.title, link: `/videos/${videoId}` },
      ]}
    >
      <MetaTags
        title={`영상정보 - ${video.title} · 콜라블`}
        description={description(video)}
        keywords={keyword(video)}
      />
      <Stack gap={2}>
        <Box maxWidth={'var(--layout-width-regular)'}>
          <Box position={'relative'} width={'100%'} maxWidth={'320px'} margin={'0 auto'}>
            <a
              href={`https://www.youtube.com/watch?v=${video.videoKey}`}
              rel="noreferrer"
              target="_blank"
              style={{ color: 'inherit' }}
            >
              <img
                alt={`'${video.title}' video thumbnail`}
                src={video.thumbnailUrl}
                style={{ width: '100%', borderRadius: '0.5rem' }}
              />
            </a>
            {video.duration && (
              <Box
                sx={{
                  background: '#00000077',
                  color: '#fff',
                  padding: '0.25rem 0.5rem',
                  borderRadius: '0.25rem',
                  position: 'absolute',
                  bottom: '0.5rem',
                  right: '0.5rem',
                  fontWeight: 'bold',
                }}
              >
                <Typography level={'body-sm'} sx={{ color: 'inherit', fontWeight: 'bold' }}>
                  {parseSeconds(Number(video.duration))}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
        <Box>
          <Typography level={'body-sm'} color={'neutral'}>
            {dayjs(video.publishedAt).format('YYYY-MM-DD HH:mm')}
          </Typography>
          <Typography level="h3" component="h2">
            {video.title}
          </Typography>
        </Box>
        <Box>
          <RouterLink to={`/channels/${video.channelId}`}>
            <ChannelItem
              channel={{
                name: video.channelName!!,
                thumbnailUrl: video.channelThumbnail!!,
              }}
              avatarSize={'md'}
            />
          </RouterLink>
        </Box>
        <Stack gap={2}>
          <Grid container spacing={2} maxWidth={'var(--layout-width-regular)'}>
            <Grid xs={6} sm={4}>
              <VideoStatisticsItem label={'조회수'} value={`${parseCount(video.viewCount)}개`} />
            </Grid>
            <Grid xs={6} sm={4}>
              <VideoStatisticsItem label={'좋아요수'} value={`${parseCount(video.likeCount)}개`} />
            </Grid>
            <Grid xs={6} sm={4}>
              <VideoStatisticsItem label={'댓글수'} value={`${parseCount(video.commentCount)}개`} />
            </Grid>
          </Grid>
          <Grid container spacing={2} maxWidth={'var(--layout-width-regular)'}>
            <Grid xs={6} sm={4}>
              <VideoStatisticsItem
                label={'시청률'}
                value={`${parseRatio(video.viewRatio)}`}
                description={'시청수 / 구독자수'}
              />
            </Grid>
            <Grid xs={6} sm={4}>
              <VideoStatisticsItem
                label={'좋아요율'}
                value={`${parseRatio(video.likeRatio)}`}
                description={'좋아요수 / 시청수'}
              />
            </Grid>
            <Grid xs={6} sm={4}>
              <VideoStatisticsItem
                label={'댓글율'}
                value={`${parseRatio(video.commentRatio)}`}
                description={'코멘트수 / 시청수'}
              />
            </Grid>
          </Grid>
        </Stack>
        <Grid container spacing={2} maxWidth={'var(--layout-width-regular)'}>
          <Grid xs={6}>
            <Card variant={'plain'}>
              <Typography level={'title-md'}>협업 정보</Typography>
              {video.brandName && (
                <>
                  <Stack direction={'row'} alignItems={'center'} gap={1}>
                    <Avatar src={video.channelThumbnail} size={'sm'} />
                    <CloseRounded />
                    <RouterLink to={`/brands/${video.brandId}`}>
                      <Box display={'flex'} flexDirection={'row'} alignItems={'center'} gap={1}>
                        <Avatar variant="brand" src={video.brandLogoUrl} size="sm" />
                        <Typography level={'title-md'} component="h3">
                          {video.brandName}
                        </Typography>
                      </Box>
                    </RouterLink>
                  </Stack>
                  <Typography level={'h4'} component="h3">
                    {video.productName}
                  </Typography>
                </>
              )}
              {!video.brandName && (
                <Typography level={'body-sm'} color={'neutral'}>
                  협업 브랜드 정보가 없습니다
                </Typography>
              )}
            </Card>
          </Grid>
          <Grid xs={6}>
            <Card variant={'plain'}>
              <Typography level={'title-md'}>샤라웃</Typography>
              {video.shoutoutBrandName && (
                <>
                  <Stack direction={'row'} gap={1}>
                    <Box>
                      <Avatar src={video.channelThumbnail} size={'sm'} />
                    </Box>
                    <Box>
                      <CloseRounded />
                    </Box>
                    <RouterLink to={`/brands/${video.shoutoutBrandId}`}>
                      <Box display={'flex'} flexDirection={'row'} alignItems={'center'} gap={1}>
                        <Avatar variant="brand" src={video.shoutoutBrandLogoUrl} size="sm" />
                        <Typography level={'title-md'} component="h3">
                          {video.shoutoutBrandName}
                        </Typography>
                      </Box>
                    </RouterLink>
                  </Stack>
                  <Stack direction={'row'}>
                    <Box marginRight={1}>
                      <RouterLink
                        to={`https://www.youtube.com/watch?v=${video.videoKey}&t=${video.highlight}`}
                        target="_blank"
                      >
                        {video.highlight && parseSeconds(video.highlight)}
                      </RouterLink>
                    </Box>
                    {video.shoutoutMention && (
                      <>
                        <FormatQuoteRounded />
                        {video.shoutoutMention}
                      </>
                    )}
                  </Stack>
                </>
              )}
              {!video.shoutoutBrandName && (
                <Typography level={'body-sm'} color={'neutral'}>
                  협업 브랜드 정보가 없습니다
                </Typography>
              )}
            </Card>
          </Grid>
        </Grid>

        <Box maxWidth={'var(--layout-width-regular)'}>
          <Card variant={'plain'}>
            <Typography level={'title-md'}>태그</Typography>
            <Grid container>
              {video.tags?.split(',').map((tag, index) => (
                // <RouterLink to={`/tag/${tag}`} key={index}>
                <Box padding={'0.25rem 0.5rem'}>
                  <Typography level={'body-sm'}>#{tag}</Typography>
                </Box>
                // </RouterLink>
              ))}
            </Grid>
          </Card>
        </Box>
        {/*<Card variant={'plain'}>*/}
        {/*  <Typography level={'title-md'}>조회수비교</Typography>*/}
        {/*  <Grid container>*/}
        {/*    <Grid width={'100px'}>현재동영상</Grid>*/}
        {/*    <Grid width={'100px'}>채널평균</Grid>*/}
        {/*    <Grid width={'100px'}>채널광고평균</Grid>*/}
        {/*    <Grid width={'100px'}>유사채널평균</Grid>*/}
        {/*  </Grid>*/}
        {/*</Card>*/}
        <Typography level={'title-lg'}>채널의 다른 광고</Typography>
        <Grid container spacing={2} maxWidth={'var(--layout-width-regular)'}>
          {filteredOtherVideos?.map(video => (
            <Grid xs={12} sm={4}>
              <RouterLink to={`/videos/${video.videoId}`}>
                <Card variant={'outlined'} size={'sm'}>
                  <Stack direction={{ xs: 'row', sm: 'column' }} gap={1}>
                    <Box maxWidth={{ xs: '160px', sm: '100%' }}>
                      <img
                        alt={`'${video.title}' video thumbnail`}
                        src={video.thumbnailUrl}
                        style={{ width: '100%' }}
                      />
                    </Box>
                    <Box>
                      <Typography level={'title-sm'} fontWeight={'bold'}>
                        {video.title}
                      </Typography>
                      <Box mt={2}>
                        <Typography level={'body-sm'}>협업 브랜드</Typography>
                      </Box>
                      <Box display={'flex'} flexDirection={'row'} alignItems={'center'} gap={1}>
                        <Avatar variant="brand" src={video.brandLogoUrl} size="sm" />
                        <Typography level={'title-sm'} component="h3">
                          {video.brandName}
                        </Typography>
                      </Box>
                    </Box>
                  </Stack>
                </Card>
              </RouterLink>
            </Grid>
          ))}
        </Grid>
      </Stack>
    </DashboardPageBody>
  )
}

const description = (video: VideoSummaryResponseItem) => {
  let result = ''
  result += `채널 '${video.channelName || ''}의 ${dayjs(video.publishedAt).format('YYYY년 MM월 DD일')}영상입니다.'`
  result += `조회수 ${parseCount(video.viewCount)}개, 좋아요 ${parseCount(video.likeCount)}개, 댓글 ${parseCount(video.commentCount)}개의 인게이지먼트 수치를 달성했습니다.`

  if (video.brandName) {
    result += `해당 영상은 브랜드 ${video.brandName}과 협업한 영상으로 ${video.productName}를 유료광고 하고있습니다.`
  }

  if (video.shoutoutBrandName) {
    result += `해당 영상은 브랜드 ${video.shoutoutBrandName}를 향한 샤라웃이 포함되어있습니다.`
  }

  if (video.tags?.length) {
    result += `해당 영상의 태그: ${video.tags}`
  }
  return result
}

const keyword = (video: VideoSummaryResponseItem) => {
  const keywords = [
    'PPL',
    '유튜브광고',
    '유료광고',
    '브랜드광고',
    '브랜드협업',
    '브랜드콜라보',
    video.title,
    video.channelName,
    video.brandName,
    video.productName,
    video.shoutoutBrandName,
    '인게이지먼트',
  ]

  return keywords.filter(it => !!it).join(',')
}

export default VideoDetail
