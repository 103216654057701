import Tile from 'component/Tile'
import { SearchChannelResponseItem } from '../../../api/types'
import { Bar, BarChart, CartesianGrid, Cell, LabelList, ResponsiveContainer, XAxis } from 'recharts'
import Title from '../../../component/texts/Text'
import { parseCount, parseRatio } from '../../../util/utils'
import { Grid } from '@mui/joy'

// const COLORS = ['#C40C0C', '#FF6500', '#FF8A08', '#FFC100']
export const COLORS = [
  '#0bb4ff',
  '#ffd51c',
  '#50e991',
  '#ffa300',
  '#9b19f5',
  '#dc0ab4',
  '#b3d4ff',
  '#00bfa0',
]

interface Props {
  channels: SearchChannelResponseItem[]
}

const ChannelCompareChart = ({ channels }: Props) => {
  return (
    <Tile style={{ padding: 0, margin: '0 auto' }}>
      {channels.length === 0 && (
        <div style={{ padding: '2rem', textAlign: 'center' }}>비교할 채널을 추가해주세요</div>
      )}
      {channels.length > 0 && (
        <Grid container style={{ gridGap: '0' }}>
          <Grid sm={3} md={4}>
            <div
              style={{
                borderRight: '1px solid #e9e9e9',
                padding: '1rem',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Title level={'3'}>조회수 평균</Title>
              <div style={{ height: 'var(--space-3)' }}></div>
              <ViewCountChart channels={channels} />
            </div>
          </Grid>
          <Grid sm={3} md={4}>
            <div
              style={{
                borderRight: '1px solid #e9e9e9',
                padding: '1rem',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Title level={'3'}>좋아요 평균</Title>
              <div style={{ height: 'var(--space-3)' }}></div>
              <LikeCountChart channels={channels} />
            </div>
          </Grid>
          <Grid sm={3} md={4}>
            <div
              style={{
                padding: '1rem',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Title level={'3'}>코멘트 평균</Title>
              <div style={{ height: 'var(--space-3)' }}></div>
              <CommentCountChart channels={channels} />
            </div>
          </Grid>
          <Grid sm={3} md={4}>
            <div
              style={{
                borderTop: '1px solid #e9e9e9',
                borderRight: '1px solid #e9e9e9',
                padding: '1rem',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Title level={'3'}>구독자수</Title>
              <div style={{ height: 'var(--space-3)' }}></div>
              <FollowerChart channels={channels} />
            </div>
          </Grid>
          <Grid sm={3} md={4}>
            <div
              style={{
                borderTop: '1px solid #e9e9e9',
                borderRight: '1px solid #e9e9e9',
                padding: '1rem',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Title level={'3'}>좋아요 비율</Title>
              <div style={{ height: 'var(--space-3)' }}></div>
              <LikeRateChart channels={channels} />
            </div>
          </Grid>
          <Grid sm={3} md={4}>
            <div
              style={{
                borderTop: '1px solid #e9e9e9',
                padding: '1rem',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Title level={'3'}>코멘트 비율</Title>
              <div style={{ height: 'var(--space-3)' }}></div>
              <CommentRateChart channels={channels} />
            </div>
          </Grid>
        </Grid>
      )}
    </Tile>
  )
}

const renderCountLabel = (props: any) => {
  const { x, y, width, value } = props

  return (
    <text x={x + width / 2} y={y - 8} fill="#787878" textAnchor="middle" dominantBaseline="middle">
      {parseCount(Number(value))}
    </text>
  )
}
const renderRateLabel = (props: any) => {
  const { x, y, width, value } = props

  return (
    <text x={x + width / 2} y={y - 8} fill="#787878" textAnchor="middle" dominantBaseline="middle">
      {parseRatio(Number(value))}
    </text>
  )
}

const FollowerChart = ({ channels }: Props) => {
  return (
    <div style={{ width: '240px', height: '160px' }}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={240}
          height={120}
          data={
            channels?.map(channel => ({
              name: channel.name,
              value: channel.followerCount,
              thumbnailUrl: channel.thumbnailUrl,
            })) || []
          }
          margin={{
            top: 16,
            right: 0,
            left: 0,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" strokeOpacity={0.5} />
          <XAxis dataKey="thumbnailUrl" tick={<CustomTick />} interval={0} axisLine={false} />
          <Bar dataKey="value" barSize="18" isAnimationActive={false}>
            {channels?.map((entry, index) => {
              return <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            })}
            <LabelList dataKey="value" position="top" content={renderCountLabel} />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
}
const ViewCountChart = ({ channels }: Props) => {
  return (
    <div style={{ width: '240px', height: '160px' }}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={240}
          height={120}
          data={
            channels?.map(channel => ({
              name: channel.name,
              value: channel.avgViewCount,
              thumbnailUrl: channel.thumbnailUrl,
            })) || []
          }
          margin={{
            top: 16,
            right: 0,
            left: 0,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" strokeOpacity={0.5} />
          <XAxis dataKey="thumbnailUrl" tick={<CustomTick />} interval={0} axisLine={false} />
          <Bar dataKey="value" barSize="18" isAnimationActive={false}>
            {channels?.map((entry, index) => {
              return <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            })}
            <LabelList dataKey="value" position="top" content={renderCountLabel} />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
}
const LikeCountChart = ({ channels }: Props) => {
  return (
    <div style={{ width: '240px', height: '160px' }}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={240}
          height={120}
          data={
            channels?.map(channel => ({
              name: channel.name,
              value: channel.avgLikeCount,
              thumbnailUrl: channel.thumbnailUrl,
            })) || []
          }
          margin={{
            top: 16,
            right: 0,
            left: 0,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" strokeOpacity={0.5} />
          <XAxis dataKey="thumbnailUrl" tick={<CustomTick />} interval={0} axisLine={false} />
          <Bar dataKey="value" barSize="18" isAnimationActive={false}>
            {channels?.map((entry, index) => {
              return <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            })}
            <LabelList dataKey="value" position="top" content={renderCountLabel} />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
}
const CommentCountChart = ({ channels }: Props) => {
  return (
    <div style={{ width: '240px', height: '160px' }}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={240}
          height={120}
          data={
            channels?.map(channel => ({
              name: channel.name,
              value: channel.avgCommentCount,
              thumbnailUrl: channel.thumbnailUrl,
            })) || []
          }
          margin={{
            top: 16,
            right: 0,
            left: 0,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" strokeOpacity={0.5} />
          <XAxis dataKey="thumbnailUrl" tick={<CustomTick />} interval={0} axisLine={false} />
          <Bar dataKey="value" barSize="18" isAnimationActive={false}>
            {channels?.map((entry, index) => {
              return <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            })}
            <LabelList dataKey="value" position="top" content={renderCountLabel} />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
}
const LikeRateChart = ({ channels }: Props) => {
  return (
    <div style={{ width: '240px', height: '160px' }}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={240}
          height={120}
          data={
            channels?.map(channel => ({
              name: channel.name,
              value: channel.avgLikeRate,
              thumbnailUrl: channel.thumbnailUrl,
            })) || []
          }
          margin={{
            top: 16,
            right: 0,
            left: 0,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" strokeOpacity={0.5} />
          <XAxis dataKey="thumbnailUrl" tick={<CustomTick />} interval={0} axisLine={false} />
          <Bar dataKey="value" barSize="18" isAnimationActive={false}>
            {channels?.map((entry, index) => {
              return <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            })}
            <LabelList dataKey="value" position="top" content={renderRateLabel} />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
}
const CommentRateChart = ({ channels }: Props) => {
  return (
    <div style={{ width: '240px', height: '160px' }}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={240}
          height={120}
          data={
            channels?.map(channel => ({
              name: channel.name,
              value: channel.avgCommentRate,
              thumbnailUrl: channel.thumbnailUrl,
            })) || []
          }
          margin={{
            top: 16,
            right: 0,
            left: 0,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" strokeOpacity={0.5} />
          <XAxis dataKey="thumbnailUrl" tick={<CustomTick />} interval={0} axisLine={false} />
          <Bar dataKey="value" barSize="18" isAnimationActive={false}>
            {channels?.map((entry, index) => {
              return <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            })}
            <LabelList dataKey="value" position="top" content={renderRateLabel} />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
}

const CustomTick = (props: any) => {
  const { x, y, payload } = props
  const thumbnailUrl = payload.value

  const radius = 12
  return (
    <g transform={`translate(${x},${y})`}>
      <defs>
        <clipPath id="circleView">
          <circle cx={0} cy={radius} r={radius} />
        </clipPath>
      </defs>
      <image
        href={thumbnailUrl}
        x={-radius}
        y={0}
        height={radius * 2}
        width={radius * 2}
        clipPath="url(#circleView)"
      />
    </g>
  )
}
export default ChannelCompareChart
