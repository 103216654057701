import Button from '../component/button/Button'
import React, { KeyboardEventHandler, useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'
import { apiSearchVideo, SearchVideoParams } from '../api/apis'
import Group from '../component/Group'
import SpinnerWithBox from '../component/SpinnerWithBox'
import { useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import Modal from 'component/Modal'
import { Typography } from '@mui/joy'
import TextField from 'component/TextField'

interface Props {
  onSelect: (videoId: number) => void
  closeModal: VoidFunction
}

const VideoSelectModal = ({ onSelect, closeModal }: Props) => {
  const { channelId } = useParams()
  const [value, setValue] = React.useState('')

  const videoQueryParams = {
    sort: 'latest',
    // page?: number
    pageSize: 10,
    channelId: channelId,
    keyword: value,
  } as SearchVideoParams

  useEffect(() => {
    refetch()
  }, [])

  const {
    data: videos,
    refetch,
    isLoading,
  } = useQuery({
    queryKey: ['searchBrand', value],
    queryFn: () => apiSearchVideo(videoQueryParams),
    placeholderData: (previousData, _) => previousData,
    enabled: false,
  })

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = e => {
    if (e.key === 'Enter') {
      if (!value) {
        return
      }

      e.stopPropagation()
      e.preventDefault()

      refetch()
    }
  }

  return (
    <Modal title="비디오 선택하기" onClose={closeModal}>
      <TextField
        label="비디오 제목"
        placeholder="비디오 제목 검색"
        name="value"
        value={value}
        onChange={(e: any) => setValue(e.target.value)}
        onKeyDown={handleKeyDown}
      />
      <Group direction={'column'} spacing={'5'}>
        {isLoading && <SpinnerWithBox />}
        {videos?.videos?.map(video => {
          return (
            <Button
              variant={'text'}
              onClick={() => {
                onSelect(video.videoId)
                closeModal()
              }}
            >
              <Group
                style={{
                  width: '100%',
                  alignItems: 'center',
                  padding: '1rem 0rem',
                  textAlign: 'left',
                }}
                spacing={'3'}
                key={video.videoId}
              >
                <img
                  src={video.thumbnailUrl}
                  style={{
                    width: '100px',
                    borderRadius: 'var(--radius)',
                  }}
                />
                <Typography level="title-sm" sx={{ flex: '1' }}>
                  {video.title}
                </Typography>
                <Typography level="body-sm">
                  {dayjs(video.publishedAt).format('YYYY-MM-DD')}
                </Typography>
              </Group>
            </Button>
          )
        })}
        {!!videos?.videos && videos.videos.length === 0 && (
          <Typography level="body-sm">검색 결과가 없습니다.</Typography>
        )}
      </Group>
    </Modal>
  )
}

export default VideoSelectModal
