import { Typography } from '@mui/joy'

interface Props {
  label: string
  value: string
}

// TODO alignment, size props

function Stat({ label, value }: Props) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column-reverse',
        justifyContent: 'flex-end',
        borderRadius: 'var(--radius)',
      }}
    >
      <Typography level="body-sm">{label}</Typography>
      <Typography
        level="title-md"
        style={{
          fontWeight: 'bold',
        }}
      >
        {value}
      </Typography>
    </div>
  )
}

export default Stat
