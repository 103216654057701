import { useState } from 'react'
import { EuiFieldSearch, EuiListGroup, EuiListGroupItem } from '@elastic/eui'
import Tile from '../../../component/Tile'
import { useQuery } from '@tanstack/react-query'
import { apiSearchChannel, SearchChannelParams } from '../../../api/apis'
import { SearchChannelResponseItem } from '../../../api/types'
import { ChannelItem } from '../../../component/ChannelItem'
import Modal from 'component/Modal'

const ChannelSelectModal = ({
  closeModal,
  onChannelSelect,
}: {
  closeModal: VoidFunction
  onChannelSelect: (channelId: number) => void
}) => {
  const [searchText, setSearchText] = useState('')

  const params = {
    search: searchText,
  } as SearchChannelParams

  const { data: channels, refetch } = useQuery({
    queryKey: ['/v1/channels', params],
    queryFn: () => apiSearchChannel(params),
    enabled: false,
  })

  const onClickItem = (channel: SearchChannelResponseItem) => {
    onChannelSelect(channel.channelId)
    closeModal()
  }

  const handleSearch = () => refetch()

  return (
    <Modal
      title="비교 채널 추가"
      onClose={closeModal}
      primaryAction={{ label: '닫기', onClick: closeModal }}
    >
      <EuiFieldSearch
        placeholder="채널명 검색"
        value={searchText}
        onChange={e => setSearchText(e.target.value)}
        isClearable
        onSearch={handleSearch}
        style={{
          width: '100%',
          height: '48px',
          paddingLeft: '46px',
          fontSize: '1.125rem',
          border: '1px solid var(--border-color)',
        }}
      />
      <div style={{ height: 'var(--space-5)' }}></div>
      {channels && (
        <Tile size="small" style={{ minHeight: '5rem' }}>
          <EuiListGroup>
            {channels?.channels?.map(channel => {
              return (
                <EuiListGroupItem
                  onClick={() => {
                    onClickItem(channel)
                  }}
                  label={<ChannelSearchItem channel={channel} />}
                />
              )
            })}
          </EuiListGroup>
        </Tile>
      )}
    </Modal>
  )
}

const ChannelSearchItem = ({ channel }: { channel: SearchChannelResponseItem }) => {
  return (
    <div style={{ display: 'flex', gap: '0.5rem', padding: '0.25rem' }}>
      <ChannelItem channel={channel} />
    </div>
  )
}

export default ChannelSelectModal
