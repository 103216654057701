import { ChangeEvent, useState } from 'react'
import {
  apiChannelOwnershipRequest,
  apiVerifyChannelOwnership,
  VerifyChannelOwnerRequest,
} from '../../../api/apis'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import Modal from 'component/Modal'
import TextField from 'component/TextField'

interface Props {
  closeModal: VoidFunction
}

interface Step0Form {
  channelUrl: string
  email: string
}

interface Step1Form {
  otp: string
}

const ChannelOwnershipRequestModal = ({ closeModal }: Props) => {
  const queryClient = useQueryClient()
  const [step, setStep] = useState(0)
  const { mutate: requestChannelOwnershipRequest, isPending: isPendingStep0 } = useMutation({
    mutationFn: apiChannelOwnershipRequest,
    onSuccess: response => {
      setOwnershipRequestId(response.ownershipRequestId)
      setStep(1)
    },
  })

  const { mutate: verifyChannelOwnership, isPending: isPendingStep1 } = useMutation({
    mutationFn: (payload: VerifyChannelOwnerRequest) =>
      apiVerifyChannelOwnership(ownershipRequestId!!, payload),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [`/v1/channel-owners`] })
      closeModal()
    },
  })

  const [step0Form, setStep0Form] = useState<Step0Form>({
    channelUrl: '',
    email: '',
  })

  const [step1Form, setStep1Form] = useState<Step1Form>({
    otp: '',
  })

  const [ownershipRequestId, setOwnershipRequestId] = useState<number>()

  const isPending = step === 0 ? isPendingStep0 : isPendingStep1

  const onSubmit = () => {
    if (step === 0) {
      requestChannelOwnershipRequest(step0Form)
    } else if (step === 1) {
      verifyChannelOwnership(step1Form)
    }
  }

  const submitButtonLabel = step === 0 ? '인증 메일 발송' : '소유권 신청'

  return (
    <Modal
      title="채널 소유권 신청"
      onClose={closeModal}
      primaryAction={{ label: submitButtonLabel, onClick: onSubmit }}
      secondaryAction={{ label: '닫기', onClick: closeModal }}
    >
      {step === 0 && <Step0 form={step0Form} setForm={setStep0Form} />}
      {step === 1 && <Step1 form={step1Form} setForm={setStep1Form} />}
      {/* TODO isLoading */}
    </Modal>
  )
}

interface Step0Props {
  form: Step0Form
  setForm: (form: Step0Form) => void
}

const Step0 = ({ form, setForm }: Step0Props) => {
  const onChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const key = e.target.name
    const value = e.target.value
    setForm({ ...form, [key]: value })
  }

  return (
    <div>
      <TextField
        label="유튜브 채널 주소"
        placeholder="https://www.youtube.com/channel/UC3XxJTww5t4rFABCwG286OQ"
        name="channelUrl"
        value={form.channelUrl}
        onChange={onChange}
      />
      <TextField label="이메일" name="email" value={form.email} onChange={onChange} />
      <p>Youtube의 채널 설명란에 기재된 이메일 주소로 이메일 인증을 해주세요</p>
    </div>
  )
}

interface Step1Props {
  form: Step1Form
  setForm: (form: Step1Form) => void
}

const Step1 = ({ form, setForm }: Step1Props) => {
  const onChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const key = e.target.name
    const value = e.target.value
    setForm({ ...form, [key]: value })
  }

  return (
    <TextField
      label="이메일 인증번호"
      placeholder=""
      name="otp"
      value={form.otp}
      onChange={onChange}
    />
  )
}

export default ChannelOwnershipRequestModal
