import { EuiFieldText, EuiForm, EuiFormRow } from '@elastic/eui'
import React, { ChangeEvent, useState } from 'react'
import Button from 'component/button/Button'
import BrandSelectModal from '../../../modal/BrandSelectModal'
import Avatar from '../../../component/Avatar'
import Group from '../../../component/Group'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  apiGetBrand,
  apiGetVideo,
  apiUpdateShoutout,
  ShoutoutResponseItem,
} from '../../../api/apis'
import VideoSelectModal from '../../../modal/VideoSelectModal'
import BrandCreateModal from '../../../modal/BrandCreateModal'
import { useParams } from 'react-router-dom'
import Modal from 'component/Modal'

interface Props {
  shoutout?: ShoutoutResponseItem
  closeModal: VoidFunction
}

interface ShoutoutUpdateForm {
  highlight?: string | undefined
}

const ShoutoutManageModal = ({ shoutout, closeModal }: Props) => {
  const queryClient = useQueryClient()
  const { channelId } = useParams()
  const [showVideoSelectModal, setShowVideoSelectModal] = useState(false)
  const [showBrandSelectModal, setShowBrandSelectModal] = useState(false)
  const [showBrandCreateModal, setShowBrandCreateModal] = useState(false)
  const [videoId, setVideoId] = useState<number>()
  const [brandId, setBrandId] = useState<number>()

  const { data: video } = useQuery({
    queryKey: ['/v1/videos', videoId],
    queryFn: () => apiGetVideo(videoId!!),
    enabled: !!videoId,
  })

  const { data: brand } = useQuery({
    queryKey: ['/v1/brands', brandId],
    queryFn: () => apiGetBrand(brandId!!),
    enabled: !!brandId,
  })

  const [form, setForm] = useState<ShoutoutUpdateForm>({
    highlight: '',
  })

  // useEffect(() => {
  //   setForm({
  //     highlight: shoutout?.categoryCode,
  //   })
  //
  //   setVideoId(shoutout?.videoId)
  //   setBrandId(shoutout?.brandId)
  // }, [shoutout])

  const { mutate: updateShoutout } = useMutation({
    mutationFn: apiUpdateShoutout,
    onSuccess: () => {
      // onSubmitVideoSetting()
      queryClient.invalidateQueries({ queryKey: [`/v1/shoutout`], exact: false })
      closeModal()
      alert('저장되었습니다')
    },
  })

  const updateForm = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const key = e.target.name
    const v = e.target.value
    const value = v ? v : undefined

    setForm({ ...form, [key]: value })
  }

  const onClickSave = () => {
    if (!brandId) {
      alert('브랜드를 선택해주세요')
      return
    }
    if (!videoId) {
      alert('비디오를 선택해주세요')
      return
    }

    const payload = {
      shoutoutId: shoutout?.shoutoutId,
      brandId: brandId,
      channelId: Number(channelId!!),
      videoId: videoId,
      highlight: form.highlight,
    }

    updateShoutout(payload)
  }

  return (
    <>
      <Modal title="샤라웃 등록" onClose={closeModal}>
        <div>
          <div style={{ margin: '2rem' }}>
            <EuiForm
              component="form"
              style={{ display: 'flex', flexDirection: 'column', gap: '.75rem' }}
            >
              <EuiFormRow label="동영상" display="columnCompressed">
                <Group spacing={'4'}>
                  {video && (
                    <div style={{ display: 'flex', gap: 'var(--space-4)', alignItems: 'center' }}>
                      <img src={video?.thumbnailUrl} style={{ width: '100px' }} alt="" />
                      <span style={{ fontWeight: 'bold' }}>{video?.title}</span>
                    </div>
                  )}
                  {!video && (
                    <Button
                      variant="textAccent"
                      onClick={() => {
                        setShowVideoSelectModal(true)
                      }}
                    >
                      동영상 선택
                    </Button>
                  )}
                </Group>
              </EuiFormRow>
              <EuiFormRow
                label="시작 시간"
                display="columnCompressed"
                helpText={'재생 시 바로 해당 시간으로 이동합니다'}
              >
                <EuiFieldText
                  name="highlight"
                  value={form.highlight}
                  onChange={updateForm}
                  placeholder="00:00"
                />
              </EuiFormRow>
              <EuiFormRow label="대상 브랜드" display="columnCompressed">
                <Group spacing={'4'}>
                  {brand?.brandName && (
                    <Group
                      style={{
                        alignItems: 'center',
                      }}
                    >
                      {brand?.logoUrl && (
                        <Avatar variant="brand" size="lg" src={brand?.logoUrl} type={'space'} />
                      )}
                      <span>{brand.brandName}</span>
                    </Group>
                  )}
                  <Button
                    variant="textAccent"
                    onClick={() => {
                      setShowBrandSelectModal(true)
                    }}
                  >
                    브랜드 선택
                  </Button>
                </Group>
              </EuiFormRow>
            </EuiForm>
          </div>
        </div>
        <Group
          style={{
            justifyContent: 'flex-end',
          }}
        >
          <Button variant="secondary" size={'s'} onClick={closeModal}>
            닫기
          </Button>
          <Button variant="primary" size={'s'} type="submit" onClick={onClickSave}>
            저장
          </Button>
        </Group>
      </Modal>
      {showBrandSelectModal && (
        <BrandSelectModal
          onSelect={(brandId, brandName, brandLogoUrl) => {
            setBrandId(brandId)
          }}
          closeModal={() => {
            setShowBrandSelectModal(false)
          }}
          openBrandCreateModal={() => {
            setShowBrandCreateModal(true)
          }}
        />
      )}
      {showVideoSelectModal && (
        <VideoSelectModal
          onSelect={videoId => {
            setVideoId(videoId)
          }}
          closeModal={() => {
            setShowVideoSelectModal(false)
          }}
        />
      )}
      {showBrandCreateModal && (
        <BrandCreateModal
          onCreate={(brandId, brandName) => {
            setBrandId(brandId)
          }}
          closeModal={() => {
            setShowBrandCreateModal(false)
          }}
        />
      )}
    </>
  )
}

export default ShoutoutManageModal
