import { termsOfService20240701 } from './contents/termsOfService2024701'
import { privacyPolicy20240701 } from './contents/privacyPolicy20240701'

export const getTerms = (type: string, version: string | undefined) => {
  if (type === 'terms-of-service') {
    switch (version) {
      case '20240701':
        return termsOfService20240701
      default:
        return termsOfService20240701
    }
  }

  if (type === 'privacy-policy') {
    switch (version) {
      case '20240701':
        return privacyPolicy20240701
      default:
        return privacyPolicy20240701
    }
  }

  return ''
}
