import Box from '@mui/joy/Box'
import Typography from '@mui/joy/Typography'
import * as React from 'react'
import { useEffect } from 'react'
import Card from '@mui/joy/Card'
import { apiGetVideosInCollections, apiGetViewedChannels } from '../../../api/apis'
import Avatar from '../../../component/Avatar'
import { parseCount } from '../../../util/utils'
import { useQuery } from '@tanstack/react-query'
import VideoItem, { Dot } from '../../../component/VideoItem'
import Grid from '@mui/joy/Grid'
import Chip from '@mui/joy/Chip'
import useLoginAuth from '../../../hooks/useLoginAuth'
import { useSignModalContext } from '../../../context/SignModalContext'
import DashboardPageBody from '../../../layout/DashboardPageBody'
import { Stack } from '@mui/joy'
import { Link, useNavigate } from 'react-router-dom'
import { MetaTags } from '../../../util/MetaTags'

const Dashboard = () => {
  const navigate = useNavigate()
  const { isLogined } = useLoginAuth()
  const { actions } = useSignModalContext()

  const { data: viewedChannels } = useQuery({
    queryKey: ['/v1/channels/viewed'],
    queryFn: () => apiGetViewedChannels({ page: 0, pageSize: 4 }),
    enabled: isLogined,
  })

  const { data: videosInCollections } = useQuery({
    queryKey: ['/v1/videos/in-collections'],
    queryFn: () => apiGetVideosInCollections({ page: 0, pageSize: 4 }),
  })

  useEffect(() => {
    if (!isLogined) {
      console.log("actions.openSignIn('OTHER')")
      actions.openSignIn('OTHER')
    }
  }, [])

  return (
    <DashboardPageBody title={'대시보드'} breadcrumbs={[{ label: '대시보드', link: '/dashboard' }]}>
      <MetaTags title={`대시보드 · 콜라블`} description={description()} keywords={keywords()} />
      {/*<BrandActivity />*/}
      <Box marginTop={'2rem'} marginBottom="2rem">
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography level="h4" component="h3" sx={{ mb: '1rem' }}>
            최근 조회한 유튜버
          </Typography>
          {/*<ViewAllButton to={'/dashboard/viewed'} />*/}
        </Box>
        <Box>
          {/* {!isLogined && (
            <Box sx={{ padding: '1rem 0' }}>로그인 후 내가 열람했던 채널들을 볼 수 있습니다</Box>
          )} */}
          {viewedChannels && viewedChannels.channels && (
            <Grid container spacing={2}>
              {viewedChannels.channels.map(channel => {
                return (
                  <Grid xs={6} md={3} lg={2}>
                    <Link to={`/channels/${channel.channelId}`}>
                      <Card>
                        <Box
                          sx={{
                            display: 'flex',
                            gap: 1,
                          }}
                        >
                          <Avatar src={channel.thumbnailUrl} size="lg" />
                          <Box>
                            <b>{channel.name}</b>
                            <Typography level="body-md">
                              <span>구독자</span> <b>{parseCount(channel.followerCount)}명</b>
                            </Typography>
                          </Box>
                        </Box>
                      </Card>
                    </Link>
                  </Grid>
                )
              })}
            </Grid>
          )}
        </Box>
      </Box>
      <Stack gap={2}>
        <Box marginTop={'2rem'}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography level="h4" component="h3">
              내 컬렉션 채널들의 PPL
            </Typography>
            {/*<ViewAllButton to={'/dashboard/viewed'} />*/}
          </Box>
        </Box>
        <Grid container spacing={2}>
          {videosInCollections &&
            videosInCollections.videos &&
            videosInCollections.videos.map(video => {
              return (
                <Grid xs={12} md={4} lg={3}>
                  <VideoItem video={video} showChannelAvatar />
                </Grid>
              )
            })}
        </Grid>
      </Stack>
    </DashboardPageBody>
  )
}

const BrandActivity = () => {
  const isLogined = true
  return (
    <Box>
      <Box marginTop={'2rem'}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography level="h4" component="h3">
            브랜드 활동
          </Typography>
          {/*<ViewAllButton to={'/dashboard/viewed'} />*/}
        </Box>
      </Box>
      {!isLogined && (
        <Box sx={{ padding: '1rem 0' }}>
          브랜드 인증시 내 브랜드의 유료광고와 브랜드를 향한 샤라웃을 모아볼 수 있어요
        </Box>
      )}
      {isLogined && (
        <Grid container spacing={2}>
          <Grid xs={3} sx={{ maxWidth: '280px' }}>
            <Card>
              <Box>
                <Chip color="success">유료광고 추가됨</Chip>
                <Box
                  sx={{
                    display: 'flex',
                    gap: 1,
                    mt: 1,
                    alignItems: 'center',
                  }}
                >
                  <img
                    src={'https://i.ytimg.com/vi/MBbWdldVI5s/maxresdefault.jpg'}
                    style={{
                      width: '100px',
                      borderRadius: 'var(--radius)',
                    }}
                  />
                  <div>
                    <Avatar
                      src="https://yt3.ggpht.com/CC3lKLV6DIJabtrU96HjtyXK4f8ajDUwTvlApdYS8QA_HniNBX9h0ICqNEDbOZs6La7FRwWE=s240-c-k-c0x00ffffff-no-rj"
                      size={'sm'}
                    />
                    <span>숏박스</span>
                    <div>
                      <div
                        style={{
                          display: '-webkit-box',
                          overflow: 'hidden',
                          WebkitBoxOrient: 'vertical',
                          WebkitLineClamp: 2,
                        }}
                      >
                        유다연, 상파울루에는 가 본 적 없는 브라질리언ㅣ노빠꾸탁재훈 시즌3 EP.08
                      </div>
                    </div>
                  </div>
                </Box>
              </Box>
            </Card>
          </Grid>
          <Grid xs={3} sx={{ maxWidth: '280px' }}>
            <Card>
              <Box>
                <Chip color="danger">샤라웃 받음</Chip>
                <Box
                  sx={{
                    display: 'flex',
                    gap: 1,
                    mt: 1,
                    alignItems: 'center',
                  }}
                >
                  <Avatar src="" name="" size={'md'} />
                  <span>
                    구독자 100만
                    <Dot />
                    남자
                    <Dot />
                    30대
                  </span>
                </Box>
              </Box>
            </Card>
          </Grid>
        </Grid>
      )}
    </Box>
  )
}

const description = () => {
  return ``
}

const keywords = () => {
  return ``
}

export default Dashboard
