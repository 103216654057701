import { parseCount, parseRatio } from '../../../util/utils'
import { EuiAvatar, EuiButtonGroup } from '@elastic/eui'
import { SearchChannelResponseItem, VideoSummaryResponseItem } from '../../../api/types'
import Group from 'component/Group'
import Tile from 'component/Tile'
import CustomizedSectionHead from 'component/CustomizedSectionHead'
import lodash from 'lodash'
import { Link } from 'react-router-dom'
import { useState } from 'react'
import { VideoStatisticsItem } from 'component/VideoStatisticsItem'

interface Props {
  channelData?: SearchChannelResponseItem
  channelVideos?: VideoSummaryResponseItem[]
}

const ChannelPPLSummary = ({ channelData, channelVideos }: Props) => {
  const [statisticsBase, setStatisticsBase] = useState<'all' | 'onlyAd' | string>('all')

  // TODO ppl 브랜드가 많은 채널을 위한 ui 정돈.
  const PPLBrands = lodash
    .chain(channelVideos)
    .filter(item => !!item.brandId)
    .groupBy('brandId')
    .map(group => ({ ...group[0], count: group.length }))
    // .map(item => {lodash.pick(item, ['brandId', 'brandLogoUrl', 'brandName']))
    .value()

  const calcStats = () => {
    const all = statisticsBase === 'all'

    const contentsCountLabel = all ? '전체 영상 업로드' : 'PPL 영상 업로드'
    const contentsCount = all ? channelData?.recentContentCount : channelData?.recentAdContentCount
    const pplRate = all
      ? ''
      : `3개월 영상 중 ${parseRatio((channelData?.recentAdContentCount || 0) / (channelData?.recentContentCount || 1))}`
    const viewCount = all ? channelData?.avgViewCount : channelData?.avgAdViewCount
    const viewRate = all ? channelData?.avgViewRate : channelData?.avgAdViewRate
    const likeCount = all ? channelData?.avgLikeCount : channelData?.avgAdLikeCount
    const likeRate = all ? channelData?.avgLikeRate : channelData?.avgAdLikeRate
    const commentCount = all ? channelData?.avgCommentCount : channelData?.avgAdCommentCount
    const commentRate = all ? channelData?.avgCommentRate : channelData?.avgAdCommentRate

    return {
      contentsCountLabel,
      contentsCount,
      pplRate,
      viewCount,
      viewRate,
      likeCount,
      likeRate,
      commentCount,
      commentRate,
    }
  }

  const stats = calcStats()

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '1.25rem', marginTop: 'var(--space-4)' }}>
      <div style={{ width: '100%' }}>
        <div style={{ display: 'flex', gap: '1.25rem' }}>
          <CustomizedSectionHead title={`최근 3개월 성과`} />
          <EuiButtonGroup
            legend=""
            options={[
              {
                label: '전체 성과',
                id: 'all',
              },
              {
                label: 'PPL 성과',
                id: 'onlyAd',
              },
            ]}
            idSelected={statisticsBase}
            onChange={value => setStatisticsBase(value)}
            style={{ maxWidth: 180 }}
          />
        </div>
        <Tile style={{ padding: '1rem', marginTop: '1rem', marginBottom: '1rem' }}>
          <Group spacing="4">
            <VideoStatisticsItem
              label={stats.contentsCountLabel}
              value={`${parseCount(stats.contentsCount)}개`}
              description={stats.pplRate}
            />
            <VideoStatisticsItem
              label="조회수 평균"
              value={`${parseCount(stats.viewCount)}회`}
              description={`구독자 대비 ${parseRatio(stats.viewRate)}`}
            />
            <VideoStatisticsItem
              label="좋아요 평균"
              value={`${parseCount(stats.likeCount)}개`}
              description={`시청수 대비 ${parseRatio(stats.likeRate)}`}
            />
            <VideoStatisticsItem
              label="코멘트 평균"
              value={`${parseCount(stats.commentCount)}개`}
              description={`시청수 대비 ${parseRatio(stats.commentRate)}`}
            />
          </Group>
        </Tile>
        <CustomizedSectionHead title={`최근 3개월 유료광고`} />
        <Tile style={{ padding: '2rem' }}>
          <Group spacing="4" style={{ gap: '2.5rem', justifyContent: 'center' }}>
            {PPLBrands.map(item => {
              const brandName = item.brandName || ''
              return (
                <Link to={`/brands/${item.brandId}`}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      gap: 'var(--space-2)',
                      color: 'var(--color-text)',
                    }}
                  >
                    <EuiAvatar
                      size="xl"
                      name={brandName}
                      imageUrl={item.brandLogoUrl || ''}
                      type="space"
                      style={{ border: 'var(--border)' }}
                    />
                    <b>{brandName}</b>
                    <b style={{ fontSize: '1.125rem', color: 'var(--color-text-light)' }}>
                      {item.count}회
                    </b>
                  </div>
                </Link>
              )
            })}
          </Group>
        </Tile>
      </div>
    </div>
  )
}

export default ChannelPPLSummary

const genderData = [
  {
    id: 'male',
    name: '남',
    amt: 58.9,
  },
  {
    id: 'female',
    name: '여',
    amt: 41.1,
  },
]

function Meter() {
  return (
    <div className="meter">
      <style jsx>
        {`
          .meter-graph {
            display: flex;
            gap: 0.125rem;
            background: var(--color-background-weak);
            border-radius: var(--radius);
            position: relative;
          }

          .meter-graph-bar {
            height: 1rem;
            border-radius: var(--radius);
          }

          .meter-graph:before {
            content: '';
            display: block;
            padding: var(--space-1);
            border-radius: var(--radius);
            background: var(--color-background-weak);
            position: absolute;
            left: var(--space-1-minus);
            right: var(--space-1-minus);
            top: var(--space-1-minus);
            bottom: var(--space-1-minus);
            z-index: -1;
          }
        `}
      </style>
      <div className="meter-graph">
        {genderData.map(item => (
          <div
            key={item.name}
            className="meter-graph-bar"
            data-status={item.id}
            style={{
              width: `${item.amt}%`,
              background: item.id === 'male' ? 'skyblue' : 'crimson',
            }}
          ></div>
        ))}
      </div>
      <div className="meter-legend">
        {genderData.map(item => {
          return (
            <div style={{ display: 'flex' }} data-status={item.id} key={item.name}>
              <Badge color={item.id === 'male' ? 'skyblue' : 'crimson'}></Badge>
              <p>{item.name}</p>
              <b>{item.amt}%</b>
              <small>51515회</small>
            </div>
          )
        })}
      </div>
    </div>
  )
}

function Badge({ color }: { color: string }) {
  return (
    <i
      style={{
        display: 'block',
        width: '12px',
        height: '12px',
        borderRadius: '50%',
        margin: '0 0.5rem 0 0',
        position: 'relative',
        background: color || 'none',
      }}
    />
  )
}
