import CustomizedPageBody from '../../../component/CustomizedPageBody'
import Button from '../../../component/button/Button'
import { EuiBasicTable, EuiBasicTableColumn } from '@elastic/eui'
import React, { useState } from 'react'
import { Criteria } from '@elastic/eui/src/components/basic_table/basic_table'
import { useSearchParams } from 'react-router-dom'
import ShoutoutManageModal from './ShoutoutManageModal'
import { useQuery } from '@tanstack/react-query'
import { apiMyGetShoutout, ShoutoutResponseItem } from '../../../api/apis'
import dayjs from 'dayjs'

interface Props {
  channelId: number | undefined
}

const ManageShoutout = ({ channelId }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [showShoutoutModal, setShowShoutoutModal] = useState(false)

  const page: number = Number(searchParams.get('page') || '1')
  const keyword = searchParams.get('keyword')
  const pageSize = 10

  const shoutoutQueryParams = {
    channelId: Number(channelId!!),
    keyword: keyword,
    page: page - 1,
    pageSize,
  }

  const { data: shoutouts, isLoading } = useQuery({
    queryKey: [`/v1/shoutout`, shoutoutQueryParams],
    queryFn: () => apiMyGetShoutout(shoutoutQueryParams),
    placeholderData: (previousData, _) => previousData,
  })

  const onTableChange = ({ page, sort }: Criteria<any>) => {
    if (page) {
      const { index: pageIndex } = page
      setSearchParams({ page: `${pageIndex + 1}` })
    }
    // if (sort) {
    //   const { field: sortField, direction: sortDirection } = sort;
    //   nextParams.sort = sortField as string|undefined
    // }
  }

  const pagination = {
    pageSize,
    pageIndex: page - 1,
    totalItemCount: shoutouts?.totalCount || 0,
    showPerPageOptions: false,
  }
  return (
    <>
      <CustomizedPageBody>
        <div style={{ maxWidth: 'var(--layout-width-regular)', margin: '0 auto' }}>
          <Button size={'s'} onClick={() => setShowShoutoutModal(true)}>
            등록하기
          </Button>
          <EuiBasicTable
            items={shoutouts?.items || []}
            columns={columns}
            pagination={pagination}
            // sorting={sorting}
            onChange={onTableChange}
            loading={isLoading}
            compressed
            width={'100%'}
          />
        </div>
      </CustomizedPageBody>
      {showShoutoutModal && <ShoutoutManageModal closeModal={() => setShowShoutoutModal(false)} />}
    </>
  )
}

const columns: Array<EuiBasicTableColumn<ShoutoutResponseItem>> = [
  {
    field: 'thumbnailUrl',
    name: '',
    sortable: false,
    truncateText: false,
    width: '100px',
    render: (_, video: ShoutoutResponseItem) => {
      const t = video.highlight ? `&t=${video.highlight}` : ''
      return (
        <a
          href={`https://www.youtube.com/watch?v=${video.videoKey}${t}`}
          rel="noreferrer"
          target="_blank"
          style={{ color: 'inherit' }}
        >
          <img src={video.thumbnailUrl} style={{ width: '100px' }} alt="" />
        </a>
      )
    },
  },
  {
    field: 'title',
    name: '영상',
    sortable: true,
    width: '400px',
    truncateText: true,
    render: (_, video: ShoutoutResponseItem) => (
      <div
        style={{
          padding: '0 1rem',
          overflow: 'hidden',
          lineHeight: '1.1rem',
          maxHeight: '2.2rem',
          wordWrap: 'break-word',
          whiteSpace: 'normal',
        }}
      >
        {video.title}
      </div>
    ),
  },
  {
    field: 'highlight',
    name: '시작시간',
    width: '90px',
    render: (highlight: number) =>
      highlight ? `${Math.floor(highlight / 60)}:${highlight % 60}` : '',
  },
  {
    field: 'brandName',
    name: '샤라웃 브랜드',
    width: '80px',
  },
  {
    field: 'createdAt',
    name: '등록일',
    width: '80px',
    render: (createdAt: Date) => dayjs(createdAt).format('YYYY-MM-DD'),
  },
]

export default ManageShoutout
