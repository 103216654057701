import { EuiCheckboxGroup } from '@elastic/eui'
import * as React from 'react'
import { useRef, useState } from 'react'
import { apiSignupYoutuber } from '../../api/apis'
import CustomizedPageBody from 'component/CustomizedPageBody'
import Title from 'component/texts/Text'
import Button from 'component/button/Button'
import { useSignModalContext } from '../../context/SignModalContext'
import { useMutation } from '@tanstack/react-query'
import { Box, Stack } from '@mui/joy'
import TextField from 'component/TextField'
import { MetaTags } from '../../util/MetaTags'

const SignupYoutuber = () => {
  const { actions } = useSignModalContext()
  const { mutate: signupMutate } = useMutation({
    mutationFn: apiSignupYoutuber,
    onSuccess: () => {
      actions.openSignUpVerification()
    },
  })

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [repassword, setRepassword] = useState('')
  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')

  const emailRef = useRef<HTMLInputElement>(null)

  const checkboxes = [
    {
      id: 'agree1',
      label: (
        <div style={{ display: 'flex', gap: '0.5rem', fontSize: '12px' }}>
          <span>(필수) 서비스 이용 약관에 동의합니다.</span>
          <a href="/terms-of-service" target="_blank">
            보기
          </a>
        </div>
      ),
    },
    {
      id: 'agree2',
      label: (
        <div style={{ display: 'flex', gap: '0.5rem', fontSize: '12px' }}>
          <span>(선택) 마케팅 정보 및 혜택 안내 수신에 동의합니다.</span>
          <a href="/privacy-policy" target="_blank">
            보기
          </a>
        </div>
      ),
    },
  ]

  const [checkboxIdToSelectedMap, setCheckboxIdToSelectedMap] = useState<{
    [key: string]: boolean
  }>({
    agree1: false,
    agree2: false,
  })

  const onCheckboxChange = (optionId: string) => {
    const newCheckboxIdToSelectedMap = {
      ...checkboxIdToSelectedMap,
      ...{
        [optionId]: !checkboxIdToSelectedMap[optionId],
      },
    }

    setCheckboxIdToSelectedMap(newCheckboxIdToSelectedMap)
  }

  const onClickSubmit = () => {
    if (!verifySubmitData()) {
      return
    }

    const { agree1, agree2 } = checkboxIdToSelectedMap
    const payload = {
      email,
      password,
      name,
      phone,
      agree1,
      agree2,
    }

    signupMutate(payload)
  }

  const verifySubmitData = () => {
    if (!email) {
      window.alert('이메일을 입력해주세요')
      emailRef?.current?.focus()
      return false
    }
    if (!password) {
      window.alert('비밀번호를 입력해주세요')
      return false
    }
    if (!repassword) {
      window.alert('비밀번호 확인을 입력해주세요')
      return false
    }
    if (password !== repassword) {
      window.alert('비밀번호 두 값이 일치하지 않습니다')
      return false
    }
    if (!phone) {
      window.alert('휴대전화번호를 입력해주세요')
      return false
    }
    if (!checkboxIdToSelectedMap['agree1']) {
      window.alert('서비스 이용약관에 동의해야 가입이 가능합니다')
      return false
    }

    return true
  }

  return (
    <CustomizedPageBody>
      <MetaTags
        title={`유튜버로 가입하기 · 콜라블`}
        description={description()}
        keywords={keywords()}
      />
      <div
        style={{
          maxWidth: 'var(--layout-width-wide)',
          margin: '0 auto',
          padding: '4rem var(--grid-margin)',
        }}
      >
        <Box sx={{ mb: '.75rem' }}>
          <Title level="2">유튜버로 가입하기</Title>
        </Box>
        {/* TODO TAB? 으로 제공하던가 광고주로 가입하기로 보낼 수 있는 링크는 제공하면 좋음. */}
        <Stack direction="column" spacing={1}>
          <TextField
            label="이메일"
            name="email"
            value={email}
            onChange={(e: any) => {
              setEmail(e.target.value)
            }}
            inputRef={emailRef}
          />
          <TextField
            label="비밀번호"
            name="password"
            value={password}
            onChange={(e: any) => {
              setPassword(e.target.value)
            }}
            type="password"
            placeholder="8자 이상"
          />

          <TextField
            name="repassword"
            value={repassword}
            onChange={(e: any) => {
              setRepassword(e.target.value)
            }}
            type="password"
            placeholder="8자 이상"
            label="비밀번호 확인"
          />

          <TextField
            label="이름"
            name="name"
            value={name}
            onChange={(e: any) => {
              setName(e.target.value)
            }}
            placeholder="이름 입력"
          />

          <TextField
            label="휴대전화 번호"
            name="phone"
            value={phone}
            onChange={(e: any) => {
              setPhone(e.target.value)
            }}
            placeholder="- 없이 입력"
          />

          <EuiCheckboxGroup
            options={checkboxes}
            idToSelectedMap={checkboxIdToSelectedMap}
            onChange={onCheckboxChange}
            legend={{
              children: undefined,
            }}
          />

          <Button variant="primary" fullWidth onClick={onClickSubmit}>
            가입하기
          </Button>
        </Stack>
      </div>
    </CustomizedPageBody>
  )
}

const description = () => {
  return ``
}

const keywords = () => {
  return ``
}

export default SignupYoutuber
