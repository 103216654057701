import { BrandResponse, VideoSummaryResponseItem } from '../../../api/types'
import Title from 'component/texts/Text'
import Avatar from 'component/Avatar'
import VideoItem, { Dot } from '../../../component/VideoItem'
import { Link } from 'react-router-dom'
import { parseCount } from '../../../util/utils'
import _ from 'lodash'
import Group from 'component/Group'
import Tile from 'component/Tile'
import { IconArrowNarrowRight } from '@tabler/icons-react'

interface BrandItemProps {
  brandId: number
  brandName: string
  logoUrl?: string
  videos?: VideoSummaryResponseItem[]
}

interface Props {
  brand: BrandItemProps
  rank?: number
}
const BrandDashboardItem = ({ brand, rank }: Props) => {
  return (
    <>
      <Tile size="large" style={{ boxShadow: 'var(--shadow)', marginTop: '1.5rem' }}>
        <Link to={`/brands/${brand.brandId}`} style={{ color: 'inherit' }}>
          <div style={{ display: 'flex', alignItems: 'center', gap: 'var(--space-3)' }}>
            {rank && (
              <Title
                level="2"
                style={{ padding: '0 var(--space-1)', color: 'var(--color-text-light)' }}
              >
                {rank}
              </Title>
            )}
            <Avatar variant="brand" size="lg" src={brand.logoUrl} type={'space'} />
            <div>
              <b>{brand.brandName}</b>
              {brand.videos && (
                <Group
                  style={{
                    alignItems: 'center',
                    gap: 'var(--space-1)',
                    marginTop: 'var(--space-2)',
                  }}
                >
                  <span>{brand.videos?.length}회</span>
                  <Dot />
                  {/* MEMO: 전체 조회수 vs 평균 조회수 */}
                  <span>
                    총 조회수 {parseCount(_.sumBy(brand.videos, video => video.viewCount))}
                  </span>
                </Group>
              )}
            </div>
            <div style={{ marginLeft: 'auto' }}>
              <IconArrowNarrowRight />
            </div>
          </div>
        </Link>
      </Tile>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 'var(--space-4)',
        }}
      >
        {_.take(brand.videos, 2).map(video => {
          return (
            <VideoItem video={video} showChannelAvatar showPPL={false} alignment="horizontal" />
          )
        })}
      </div>
    </>
  )
}

export default BrandDashboardItem
