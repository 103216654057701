import { SxProps } from '@mui/material'
import { Avatar as MuiAvatar } from '@mui/joy'
import { CSSProperties } from 'react'

interface Props {
  size?: 'sm' | 'md' | 'lg'
  src: string | undefined
  onClick?: any
  type?: 'space' | undefined
  style?: CSSProperties | undefined
  variant?: 'channel' | 'brand'
  name?: string
  sx?: SxProps
}

const BASE_SIZE = 'lg'

const Avatar = ({ name, variant, size, src, onClick, sx }: Props) => {
  return (
    <MuiAvatar
      size={size || BASE_SIZE}
      alt={name || ''}
      src={src || ''}
      onClick={onClick}
      sx={{
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: '100%',
        borderRadius: variant === 'brand' ? 'var(--radius)' : '50%',
        ...sx,
      }}
    />
  )
}

export default Avatar

// const sizes = {
//   s: 'var(--size-s)',
//   m: 'var(--size-s)',
//   l: 'var(--size-s)',
// }

// function Avatar(props: AvatarProps) {
//   return (
//     <figure
//       style={{
//         display: 'flex',
//         alignItems: 'center',
//         justifyContent: 'center',
//         width: sizes[props.size],
//         height: sizes[props.size],
//         borderRadius: '13px',
//         background: '#ebf2fd',
//       }}
//     >
//       {props.children && props.children}
//     </figure>
//   )
// }
