import Avatar from './Avatar'
import { parseCount } from '../util/utils'
import { Box, Typography } from '@mui/joy'

interface SearchChannelProps {
  thumbnailUrl: string
  name: string
  followerCount?: number
}

interface Props {
  channel: SearchChannelProps
  avatarSize?: 'sm' | 'md' | 'lg'
}

export function ChannelItem({ channel, avatarSize }: Props) {
  return (
    <Box display="flex" gap={1}>
      <Avatar src={channel?.thumbnailUrl} size={avatarSize} />
      <Box>
        <Typography level="title-md">{channel?.name}</Typography>
        {channel?.followerCount && (
          <Typography level="body-sm">{parseCount(channel?.followerCount)}명</Typography>
        )}
      </Box>
    </Box>
  )
}
