import { GetBookmarkGroupRequest, SearchVideoParams } from './apis'

const queryKeys = {
  myChannelSettings: (channelId: number) => [`/v1/channels/${channelId}/settings`],
  getChannelCompareDetails: (channelIds: string[]) => ['/v1/channels/compare', channelIds],
  videoSearch: (params: SearchVideoParams) => ['/v1/videos', params],
  getBookmarkGroups: (params?: GetBookmarkGroupRequest) => ['/v1/bookmark-groups', params],
}

export default queryKeys
