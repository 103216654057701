import { TempTypes } from '../util/consts'
import React from 'react'

export const CategoryButton = ({
  category,
  selected,
  onClick,
  style,
}: {
  category: TempTypes
  selected: boolean
  onClick?: (event: React.MouseEvent<HTMLElement>) => void
  style?: React.CSSProperties
}) => {
  return (
    <button
      key={category.categoryCode}
      value={category.categoryCode}
      onClick={onClick}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '32px',
        // gap: 'var(--space-1)',
        minInlineSize: 'auto',
        borderRadius: '16px',
        padding: 'var(--space-1) 1rem var(--space-1) var(--space-1)',
        borderColor: selected ? '#333' : 'var(--border-color)',
        background: selected ? 'var(--color-text)' : 'var(--color-background-weak)',
        boxSizing: 'border-box',
        color: selected ? '#fff' : 'var(--color-text)',
        ...style,
      }}
    >
      {category.categoryCode === '' || (
        <figure
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '32px',
            height: '32px',
            borderRadius: '13px',
          }}
        >
          <span className="tossface">{category?.emoji || ''}</span>
          {/* <img
            src={category?.imageSrc}
            style={{ width: '1.5rem', marginTop: category?.adjust }}
            alt=""
          /> */}
        </figure>
      )}
      <span
        style={{
          marginLeft: category.categoryCode === '' ? 'var(--space-3)' : 0,
          fontWeight: '500',
        }}
      >
        {category.name}
      </span>
    </button>
  )
}
