import { useEffect, useState } from 'react'
import Tile from '../../../component/Tile'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { useSignModalContext } from '../../../context/SignModalContext'
import { apiGetMe } from '../../../api/apis'
import axios from 'axios'
import AuthorizationHelper from '../../../util/LoginAuthHelper'
import PasswordResetModal from './PasswordResetModal'
import DashboardPageBody from '../../../layout/DashboardPageBody'
import { useQuery } from '@tanstack/react-query'
import { Button } from '@mui/joy'
import { MetaTags } from '../../../util/MetaTags'
import * as React from 'react'

const Mypage = () => {
  const navigate = useNavigate()
  const { actions } = useSignModalContext()
  const { data: me } = useQuery({
    queryKey: [`/v1/me`],
    queryFn: apiGetMe,
  })

  const [showPasswordResetModal, setShowPasswordResetModal] = useState(false)

  useEffect(() => {
    if (!me) {
      return
    }

    if (me.status === 'VERIFYING_EMAIL') {
      actions.openSignUpVerification()
    }
  }, [me])

  const logout = () => {
    axios.defaults.headers.Authorization = null
    AuthorizationHelper.removeToken()
    navigate('/')
  }

  const userData = [
    {
      title: '이메일 주소',
      description: me?.email,
    },
    {
      title: '비밀번호',
      description: me && (
        <RouterLink to={''} onClick={() => setShowPasswordResetModal(true)}>
          비밀번호 변경
        </RouterLink>
      ),
    },
    {
      title: '이름',
      description: me?.name,
    },
    {
      title: '휴대전화번호',
      description: me?.phone,
    },
  ]

  return (
    <DashboardPageBody title="설정" breadcrumbs={[{ link: '/channels/manage', label: 'Settings' }]}>
      <MetaTags title={`설정 · 콜라블`} />
      <div style={{ maxWidth: 'var(--layout-width-narrow)' }}>
        <Tile style={{ padding: '2rem' }}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: ' 1.5rem' }}>
            {userData.map((item, index) => {
              return (
                <div key={index} style={{ display: 'flex', gap: '1rem' }}>
                  <div style={{ width: '80px' }}>{item.title}</div>
                  <div>
                    <b>{item.description}</b>
                  </div>
                </div>
              )
            })}
          </div>
        </Tile>
        <Button onClick={logout}>로그아웃</Button>
      </div>

      {showPasswordResetModal && (
        <PasswordResetModal closeModal={() => setShowPasswordResetModal(false)} />
      )}
    </DashboardPageBody>
  )
}

export default Mypage
