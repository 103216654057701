import { EuiFilePicker, EuiIcon, useGeneratedHtmlId } from '@elastic/eui'
import { ChangeEvent, LegacyRef, useEffect, useRef, useState } from 'react'
import CustomizedSelect from '../../../component/CustomizedSelect'
import { ChannelCategories } from '../../../util/consts'
import {
  apiUploadAudienceImage,
  ChannelAudienceRequestStatus,
  ChannelUpdateRequest,
  useMyChannelSettings,
  useUpdateChannelSettings,
} from '../../../api/apis'
import { useMutation } from '@tanstack/react-query'
import _ from 'lodash'
import Button from 'component/button/Button'
import { Box, FormLabel } from '@mui/joy'
import TextField from 'component/TextField'

interface ChannelManagerForm {
  email: string
  mcn: string
  insta: string
  tiktok: string
  chzzk: string
  afreeca: string
  twitter: string
  category: string
  heroGender: string
  heroAge: string
}

interface ChannelSnsUpdate {
  type: string
  alias: string
}

interface Props {
  channelId: number
}

const ChannelManageTab = ({ channelId }: Props) => {
  const { data: channelSettings } = useMyChannelSettings(channelId)
  const { mutate: updateChannelSettings } = useUpdateChannelSettings(channelId)
  const { mutate: uploadAudience } = useMutation({
    mutationFn: (file: File) => apiUploadAudienceImage(channelId, file),
    onSuccess: data => {
      setAudienceUrl(data.fileUrl)
    },
  })

  const filePickerRef = useRef() as LegacyRef<EuiFilePicker>
  const filePicker = useGeneratedHtmlId({ prefix: 'filePicker' })
  const [form, setForm] = useState<ChannelManagerForm>({
    email: '',
    mcn: '',
    insta: '',
    tiktok: '',
    chzzk: '',
    afreeca: '',
    twitter: '',
    category: '',
    heroGender: '',
    heroAge: '',
  })
  const [audienceUrl, setAudienceUrl] = useState<string>()

  useEffect(() => {
    if (!channelSettings) return

    const sns = _.keyBy(channelSettings.sns, 'snsType')

    setForm({
      email: channelSettings.email || '',
      mcn: channelSettings.mcn || '',
      insta: sns['INSTAGRAM']?.snsAlias || '',
      tiktok: sns['TIKTOK']?.snsAlias || '',
      chzzk: sns['CHZZK']?.snsAlias || '',
      afreeca: sns['AFREECA']?.snsAlias || '',
      twitter: sns['TWITTER']?.snsAlias || '',
      category: channelSettings.category || '',
      heroGender: channelSettings.heroGender || '',
      heroAge: channelSettings.heroAge ? `${channelSettings.heroAge}` : '',
    })
  }, [channelSettings])

  const updateForm = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const key = e.target.name
    const value = e.target.value
    setForm({ ...form, [key]: value })
  }

  const onFileSelected = async (files: FileList | null) => {
    const file = !!files && files[0]
    if (!file) {
      return
    }

    uploadAudience(file)
  }

  const categoryOptions = [
    { value: '', text: '-' },
    ...ChannelCategories.map(type => ({ value: type.code, text: type.kor })),
  ]

  const onClickSave = () => {
    const payload = {
      email: form.email,
      mcn: form.mcn,
      audienceUrl: audienceUrl,
      category: form.category,
      heroGender: form.heroGender,
      heroAge: form.heroAge,
      sns: [] as ChannelSnsUpdate[],
    } as ChannelUpdateRequest

    if (form.insta) {
      payload.sns.push({ type: 'INSTAGRAM', alias: form.insta })
    }
    if (form.tiktok) {
      payload.sns.push({ type: 'TIKTOK', alias: form.tiktok })
    }
    if (form.chzzk) {
      payload.sns.push({ type: 'CHZZK', alias: form.chzzk })
    }
    if (form.afreeca) {
      payload.sns.push({ type: 'AFREECA', alias: form.afreeca })
    }
    if (form.twitter) {
      payload.sns.push({ type: 'TWITTER', alias: form.twitter })
    }

    updateChannelSettings(payload, {
      onSuccess: () => {
        alert('저장되었습니다')
      },
    })
  }

  return (
    <div style={{ maxWidth: '720px', margin: '0 auto' }}>
      {/* <CustomizedPageTitle>채널 관리</CustomizedPageTitle> */}

      <form>
        <TextField
          label={
            <>
              비즈니스 메일주소 <EuiIcon type={'check'} color="#1d9bf0" />
            </>
          }
          name="email"
          value={form.email}
          onChange={updateForm}
          placeholder=""
        />

        <div style={{ height: '1rem' }} />

        <Box>
          <FormLabel>
            주 콘텐츠 <EuiIcon type={'check'} color="#1d9bf0" />
          </FormLabel>
          <CustomizedSelect
            options={categoryOptions}
            name="category"
            value={form.category}
            onChange={updateForm}
          />
        </Box>

        <Box sx={{ my: '1rem' }}>
          <TextField label="소속사" name="mcn" value={form.mcn} onChange={updateForm} />
        </Box>

        <Box>
          <FormLabel>
            메인 출연자 <EuiIcon type={'check'} color="#1d9bf0" />
          </FormLabel>
          <div style={{ display: 'flex', gap: '.5rem', margin: '0 0 1rem' }}>
            <CustomizedSelect
              options={[
                { value: '', text: '성별' },
                { value: 'male', text: '남자' },
                { value: 'female', text: '여자' },
                { value: 'mixed', text: '혼성' },
              ]}
              name="heroGender"
              value={form.heroGender}
              onChange={updateForm}
            />
            <CustomizedSelect
              options={[
                { value: '', text: '나이대' },
                { value: '10', text: '10대' },
                { value: '20', text: '20대' },
                { value: '30', text: '30대' },
                { value: '40', text: '40대' },
                { value: '50', text: '50대' },
                { value: '60', text: '60대이상' },
              ]}
              name="heroAge"
              value={form.heroAge}
              onChange={updateForm}
            />
          </div>
        </Box>

        <div style={{ height: '1rem' }} />

        <Box>
          <FormLabel>
            시청자 데이터 <EuiIcon type={'check'} color="#1d9bf0" />
          </FormLabel>
          {channelSettings?.audienceRequest && (
            <p>{audienceRequestGuideMessage(channelSettings?.audienceRequest?.status)}</p>
          )}
          {!channelSettings?.audienceRequest && audienceUrl && (
            <img src={audienceUrl} height="200px" />
          )}
          {!channelSettings?.audienceRequest && !audienceUrl && (
            <EuiFilePicker
              ref={filePickerRef}
              id={filePicker}
              initialPromptText="크리에이터 스튜디오 캡쳐본"
              onChange={onFileSelected}
              display="large"
            />
          )}
        </Box>
        <div style={{ maxWidth: '200px', margin: '2rem 0 0' }}>
          <Button variant="primary" fullWidth onClick={onClickSave}>
            저장
          </Button>
        </div>
      </form>
    </div>
  )
}

const audienceRequestGuideMessage = (status: ChannelAudienceRequestStatus) => {
  switch (status) {
    case 'PENDING':
      return '업로드된 정보를 확인 중입니다'
    case 'ACCEPTED':
      return '정상 등록되었습니다'
    case 'REJECTED':
      return '시청자 데이터 등록이 거절되었습니다. 다시 업로드해주세요.'
  }
}

export default ChannelManageTab
