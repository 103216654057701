import Box from '@mui/joy/Box'
import * as React from 'react'
import Group from '../../../component/Group'
import { useSearchParams } from 'react-router-dom'
import { apiGetShoutout } from '../../../api/apis'
import { useQuery } from '@tanstack/react-query'
import SpinnerWithBox from '../../../component/SpinnerWithBox'
import CategoryFilter from '../../../component/CategoryFilter'
import DashboardPageBody from '../../../layout/DashboardPageBody'
import ShoutoutItem from './ShoutoutItem'
import { MetaTags } from '../../../util/MetaTags'

const ShoutoutFeed = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const categoryCode = searchParams.get('categoryCode') || '0100'

  const params = {
    categoryCode,
    page: 0,
  }

  const { data: shoutList, isLoading } = useQuery({
    queryKey: ['/v1/shoutout', params],
    queryFn: () => apiGetShoutout(params),
  })

  const updateQuery = (key: string, value: string) => {
    searchParams.set(key, value)
    setSearchParams(searchParams)
  }

  return (
    <DashboardPageBody title="샤라웃" breadcrumbs={[{ label: 'Shout out', link: '/shoutout' }]}>
      <MetaTags title={`샤라웃 · 콜라블`} description={description()} keywords={keywords()} />
      <Box>
        <div style={{ width: '100%', maxWidth: 'var(--layout-width-wide)' }}>
          <CategoryFilter
            selected={categoryCode}
            actionType="updateQuery"
            updateQuery={updateQuery}
            showAll={false}
          />
        </div>
      </Box>
      <Box marginTop="2rem">
        <div style={{ width: '100%', maxWidth: 'var(--layout-width-regular)' }}>
          <Group spacing="6" style={{ justifyContent: 'center' }}>
            {!shoutList && isLoading && <SpinnerWithBox height={'50vh'} />}
            {shoutList && !shoutList.items?.length && <span>데이터가 없습니다</span>}
            {shoutList &&
              shoutList.items?.map(item => <ShoutoutItem item={item} key={item.shoutoutId} />)}
          </Group>
        </div>
      </Box>
    </DashboardPageBody>
  )
}

const description = () => {
  return `Shout out to you. 유튜버들이 진심으로 좋아하는 브랜드와 제품에 대한 솔직한 언급을 한눈에 확인해보세요. 진정성 넘치는 브랜드 스토리와 새로운 콜라보 아이디어를 발견할 수 있습니다. 유튜브에서 사랑받는 브랜드의 비결을 함께 살펴보세요.`
}

const keywords = () => {
  return `샤라웃, 유튜버 추천, 브랜드 언급, 진정성 리뷰, 브랜드 팬덤, 유튜브 트렌드, 콜라보 아이디어, 호감도 높은 브랜드, 유튜브 마케팅, 소셜 프루프, 구전 효과, 콘텐츠 큐레이션, 브랜드 애정도`
}

export default ShoutoutFeed
