import { FormControl, FormLabel, Input, FormHelperText } from '@mui/joy'
import { HTMLInputTypeAttribute } from 'react'

interface Props {
  type?: HTMLInputTypeAttribute | 'text'
  size?: 'md' | 'sm'
  label: string | React.ReactNode
  name?: string
  value: string
  // onChange: (event: { target: { name: string; value: string } }) => void
  onChange: any // TODO
  onKeyDown?: any // TODO
  placeholder?: string
  sx?: any // TODO
  helperText?: string
  inputRef?: any
}

function TextField({
  type,
  size,
  label,
  name,
  value,
  onChange,
  onKeyDown,
  placeholder,
  helperText,
  inputRef,
  sx,
}: Props) {
  return (
    <FormControl sx={sx}>
      <FormLabel>{label}</FormLabel>
      <Input
        size={size || 'lg'}
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        slotProps={{
          input: {
            ref: inputRef,
          },
        }}
      />

      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  )
}

export default TextField
