import dayjs from 'dayjs'
import { padEnd, padStart } from 'lodash'

export const parseCount = (count: number | undefined) => {
  if (count === undefined || count === null) {
    return '0'
  }

  let number: number
  let unit: string
  let fix: number

  if (count >= 10000000000) {
    number = count / 100000000
    fix = 0
    unit = '억'
  } else if (count >= 100000000) {
    number = count / 100000000
    fix = 1
    unit = '억'
  } else if (count >= 100000) {
    number = count / 10000
    fix = 0
    unit = '만'
  } else if (count >= 10000) {
    number = count / 10000
    fix = 1
    unit = '만'
  } else if (count >= 1000) {
    number = count / 1000
    fix = 1
    unit = '천'
  } else {
    number = count
    fix = 0
    unit = ''
  }

  const fixed = number.toFixed(fix)
  const localized = Number(fixed).toLocaleString()
  const numberPart = localized.replace('.0', '')
  return numberPart + unit
}

export const parseRatio = (ratio: number | undefined) => {
  if (ratio === undefined || ratio === null) {
    return '0'
  }

  const r = ratio * 100
  let result: string
  if (r < 0.001) {
    result = '0%'
  } else if (r < 0.01) {
    result = r.toFixed(4) + '%'
  } else if (r < 0.1) {
    result = r.toFixed(3) + '%'
  } else if (r < 1) {
    result = r.toFixed(2) + '%'
  } else if (r < 100) {
    result = r.toFixed(1) + '%'
  } else {
    result = r.toFixed(0) + '%'
  }

  if (result.endsWith('.0%')) {
    return result.replace('.0%', '%')
  } else {
    return result
  }
}

export const numberWithCommas = (x: number) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

const YYYYMMDD = 'YYYY-MM-DD'

export const getPublishedAt = (period: '1W' | '1M' | '3M' | string) => {
  const endPublishedAt = dayjs().format('YYYY-MM-DD')
  if (period === '1W') {
    const startPublishedAt = dayjs().subtract(1, 'week').format(YYYYMMDD)
    return { startPublishedAt, endPublishedAt }
  } else if (period === '1M') {
    const startPublishedAt = dayjs().subtract(1, 'month').format(YYYYMMDD)
    return { startPublishedAt, endPublishedAt }
  } else if (period === '3M') {
    const startPublishedAt = dayjs().subtract(3, 'month').format(YYYYMMDD)
    return { startPublishedAt, endPublishedAt }
  }

  const startPublishedAt = dayjs().subtract(1, 'month').format(YYYYMMDD)
  return { startPublishedAt, endPublishedAt }
}

export const getCategoryDepth1 = (categoryCode: string) => {
  const depth1Code = categoryCode.slice(0, 2)
  return padEnd(depth1Code, categoryCode.length, '0')
}

export const parseSeconds = (seconds: number) => {
  const hour = Math.floor(seconds / 3600)
  const minute = Math.floor((seconds % 3600) / 60)
  const second = seconds % 60

  const padMinute = padStart(minute.toString(), 2, '0')
  const padSecond = padStart(second.toString(), 2, '0')

  if (hour === 0) {
    return `${padMinute}:${padSecond}`
  } else {
    return `${hour}:${padMinute}:${padSecond}`
  }
}
