import Box from '@mui/joy/Box'
import LoginAuthHelper from '../../../util/LoginAuthHelper'
import Button from '../../../component/button/Button'
import { IconPlus } from '@tabler/icons-react'
import Group from '../../../component/Group'
import BookmarkGroupCreateModal from '../../../modal/BookmarkGroupCreateModal'
import { useModal } from '../../../component/Modal'
import { useQuery } from '@tanstack/react-query'
import queryKeys from '../../../api/queryKeys'
import { apiGetBookmarkGroup, useDeleteBookmarkGroup } from '../../../api/apis'
import { Link as RouterLink } from 'react-router-dom'
import BookmarkGroupRow from './BookmarkGroupRow'
import { EmptyState } from './EmptyState'
import DashboardPageBody from '../../../layout/DashboardPageBody'
import { MetaTags } from '../../../util/MetaTags'
import * as React from 'react'

const Collection = () => {
  const { opened, toggleModal } = useModal(false)

  const params = { needChannelSize: 5 }
  const { data: bookmarkGroups } = useQuery({
    queryKey: queryKeys.getBookmarkGroups(params),
    queryFn: () => apiGetBookmarkGroup(params),
  })

  const { mutate: deleteBookmarkGroup } = useDeleteBookmarkGroup()

  const onClickDelete = (onClickDelete: number) => {
    if (!window.confirm('삭제하시겠습니까?')) {
      return
    }

    deleteBookmarkGroup(onClickDelete)
  }

  return (
    <DashboardPageBody title={'내 컬렉션'} breadcrumbs={[{ label: 'Collection' }]}>
      <MetaTags title={`내 컬렉션 · 콜라블`} description={description()} keywords={keywords()} />
      <Box>
        <div style={{ maxWidth: 'var(--layout-width-narrow)' }}>
          {!LoginAuthHelper.isLogined() && <EmptyState />}
          {LoginAuthHelper.isLogined() && (
            <>
              <Button variant="tertiary" onClick={toggleModal}>
                <IconPlus size={18} />
                컬렉션 만들기
              </Button>
              <Group direction="column" spacing="4" style={{ marginTop: 'var(--space-2)' }}>
                {bookmarkGroups?.privateGroups.map(group => (
                  <RouterLink
                    key={group.bookmarkGroupId}
                    to={`/collections/${group.bookmarkGroupId}`}
                    style={{ color: 'inherit' }}
                  >
                    <BookmarkGroupRow group={group} onDelete={onClickDelete} />
                  </RouterLink>
                ))}
              </Group>
            </>
          )}
        </div>
        {opened && <BookmarkGroupCreateModal closeModal={toggleModal} />}
      </Box>
    </DashboardPageBody>
  )
}

const description = () => {
  return `효과적인 유튜브 마케팅을 위한 채널 관리 도구. 주목할 만한 유튜브 채널을 저장하고 카테고리별로 정리하세요. 인플루언서 마케팅, 협업 기회 발굴, 경쟁사 분석에 활용할 수 있습니다. 체계적인 채널 관리로 유튜브 마케팅 전략을 최적화하세요.`
}

const keywords = () => {
  return `유튜브 마케팅, 인플루언서 마케팅, 채널 분석, 협업 기회, 경쟁사 분석, 유튜브 트렌드, 채널 관리 도구, 마케팅 전략 최적화, 유튜버 리스트, 콘텐츠 크리에이터 분석, 채널 큐레이션, 맞춤형 채널 관리`
}

export default Collection
