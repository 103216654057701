import Button from 'component/button/Button'
import { useSignModalContext } from '../../../context/SignModalContext'
import { Alert, Stack, Typography } from '@mui/joy'
import Chip from '@mui/joy/Chip'

interface Props {
  isSample?: boolean
}

const SampleCallout = ({ isSample }: Props) => {
  const { actions } = useSignModalContext()
  if (isSample !== true) return

  return (
    <Alert
      variant={'soft'}
      color="warning"
      sx={{
        display: 'flex',
        alignItems: 'center',
        padding: '.25rem 1rem',
        borderRadius: 'var(--radius)',
        marginTop: '1.5rem',
      }}
    >
      <Stack direction={'row'} gap={1} alignItems={'center'}>
        <Chip sx={{ bgcolor: '#e5953a', fontWeight: 'bold', color: '#fff' }} size="md">
          무료
        </Chip>
        <Typography level="title-md" sx={{ color: 'inherit' }}>
          로그인 후 데이터를 보실 수 있습니다.
        </Typography>
        <Button
          variant="text"
          onClick={() => {
            actions.openSignIn('CHANNEL_DETAIL')
          }}
        >
          로그인하기
        </Button>
      </Stack>
    </Alert>
  )
}

export default SampleCallout
