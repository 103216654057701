import Button from '../../../component/button/Button'
import { useSignModalContext } from '../../../context/SignModalContext'
import LoginAuthHelper from '../../../util/LoginAuthHelper'
import { Alert, Stack } from '@mui/joy'

interface Props {
  isQuotaExceeded?: boolean
}

const QuotaExceededCallout = ({ isQuotaExceeded }: Props) => {
  const { actions } = useSignModalContext()
  if (isQuotaExceeded !== true) return

  return (
    <Alert
      variant={'soft'}
      color="warning"
      sx={{
        display: 'flex',
        alignItems: 'center',
        padding: '.25rem 1rem',
        borderRadius: 'var(--radius)',
        marginTop: '1.5rem',
      }}
    >
      <Stack direction={'row'} gap={1} alignItems={'center'}>
        {LoginAuthHelper.isLogined() && <div>열람 한도를 초과하였습니다.</div>}
        {!LoginAuthHelper.isLogined() && (
          <>
            <span>비회원 1일 열람 한도를 초과하였습니다.</span>
            <Button
              variant="text"
              onClick={() => {
                actions.openSignIn('CHANNEL_DETAIL')
              }}
            >
              로그인 후 열람하기
            </Button>
          </>
        )}
      </Stack>
    </Alert>
  )
}

export default QuotaExceededCallout
