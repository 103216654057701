import React, { ReactNode, useCallback, useState } from 'react'
import { IconPlus } from '@tabler/icons-react'
import Title from 'component/texts/Text'

// TODO global?
declare module 'react' {
  interface StyleHTMLAttributes<T> extends HTMLAttributes<T> {
    jsx?: boolean
    global?: boolean
  }
}

type FAQTypes = { id: string; question: string; answer: ReactNode | string }

function FAQs() {
  const [accordions, setAccordions] = useState<string[]>([])

  const handleAccordionClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      const { name } = event.currentTarget

      if (accordions.includes(name)) {
        const filtered = accordions.filter(item => item !== name)
        setAccordions(filtered)
        return
      }

      setAccordions(prevStates => [...prevStates, name])
    },
    [accordions],
  )
  return (
    <>
      <div style={{ padding: '3rem 0', textAlign: 'center' }}>
        <Title level="1">자주 묻는 질문</Title>
      </div>
      <div className="faq-container">
        {FAQData.map(item => {
          return (
            <div key={item.id} className="faq-item">
              <button name={item.id} onClick={handleAccordionClick} className="faq-item-question">
                <h3 style={{ fontSize: '1.25rem', fontWeight: 'bold' }}>{item.question}</h3>
                <div
                  className="faq-item-question-icon"
                  style={{
                    transform: accordions.includes(item.id) ? 'rotate(45deg)' : 'rotate(0)',
                  }}
                >
                  <IconPlus size={16} stroke={3} />
                </div>
              </button>
              <div
                style={{
                  display: accordions.includes(item.id) ? 'block' : 'none',
                  padding: 'var(--space-4) 0',
                  color: 'var(--color-text-light)',
                }}
              >
                {item.answer}
              </div>
            </div>
          )
        })}
      </div>

      <style jsx>{`
        .faq-container {
          display: flex;
          flex-direction: column;
          width: 100%;
          max-width: 640px;
          margin: 0 auto;
          border-top: var(--border);
        }
        .faq-item {
          padding: var(--space-3) 0;
          border-bottom: var(--border);
        }
        .faq-item-question {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          line-height: 32px;
          text-align: left;
        }
        .faq-item-question-icon {
          display: flex;
          align-items: center;
          justify-content: center;
          transition: transform 0.2s;
          transform-origin: center;
        }
      `}</style>
    </>
  )
}

const FAQData: FAQTypes[] = [
  {
    id: '1',
    question: '서비스에서 어떤 가치를 제공하나요?',
    answer: (
      <p>
        콜라블은 유튜브 채널의 성과를 데이터로 시각화하여 인플루언서 마케팅을 최적화합니다.
        <br />
        <br />
        <mark>인게이지먼트, 시청자층, 영상 속 PPL 제품 정보</mark>를 제공하여, 브랜드와 크리에이터
        모두에게 인사이트를 제공합니다.
      </p>
    ),
  },
  {
    id: '2',
    question: '어떤 데이터를 제공하나요?',
    answer: (
      <p>
        유튜브 채널들의{' '}
        <mark>
          최근(3개월 기준) 인게이지먼트 데이터(조회수, 좋아요, 댓글수)와 시청자층(연령, 성별)
        </mark>
        를 제공합니다. <br />
        <br />
        <mark>유튜브 영상에서 PPL된 제품 및 브랜드 정보</mark>를 추출하여, 각 채널이 진행했던 협찬
        내용과 성과를 종합적으로 보여줍니다.
      </p>
    ),
  },
  {
    id: '3',
    question: '데이터 업데이트 주기는 어떻게 되나요?',
    answer: (
      <p>
        주요 인게이지먼트 통계는 매일 자정 업데이트됩니다. <br />
        동영상 데이터는 채널의 규모에 따라 업데이트 주기가 다르며, 구독자수 10만 이상 채널은 1일 1회
        업데이트 됩니다.
      </p>
    ),
  },
  {
    id: '4',
    question: '서비스는 유료인가요?',
    answer: (
      <p>
        콜라블은 현재 베타 기간으로 무료로 제공되며, 추후 유료화 계획이 있습니다. <br />
        <br />
        모든 데이터는 회원가입 한 사용자에게만 제공되며, 각 플랜별 열람횟수 제한이 존재합니다.
        <br />
        (베타기간 동안은 월 100회의 열람 횟수 제한이 존재합니다)
      </p>
    ),
  },
  {
    id: '5',
    question: '다른 문의는 어디로 해야하나요?',
    answer: (
      <p>
        서비스에 관한 문의 및 제안 등은 아래 설문 링크를 통해 남겨주시면 감사하겠습니다.
        <br />
        <br />
        <a href={'https://collable.featurebase.app/kr'} target={'_blank'}>
          서비스 기능 제안
        </a>
        <br />
        제휴/제안: help@collable.co
      </p>
    ),
  },
  {
    id: '6',
    question: '채널 노출 기준은 어떻게 되나요?',
    answer: (
      <p>
        채널의 구독자가 최소 규모(1만명) 이상 및 최근 6개월 내 업로드 영상이 존재하는 채널에 대하여
        데이터를 제공합니다.
      </p>
    ),
  },
]

export default FAQs
