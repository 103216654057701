import Avatar from '../Avatar'
import dayjs from 'dayjs'
import { parseCount } from '../../util/utils'
import { VideoSummaryResponseItem } from '../../api/types'
import './VideoTooltip.scss'
import { Typography } from '@mui/joy'

const VideoTooltip = ({ video }: { video: VideoSummaryResponseItem }) => {
  return (
    <div className="video-tooltip">
      <div className="row1">
        <Avatar src={video.thumbnailUrl} size="sm" />
        <Typography level="title-md" sx={{ maxHeight: '1.4rem', overflow: 'hidden', flex: 1 }}>
          <b>{video?.channelName}</b>
        </Typography>
        <Typography level="body-sm">{dayjs(video.publishedAt).format('YYYY-MM-DD')}</Typography>
      </div>
      <div className="row2">
        <img src={video.thumbnailUrl} />
        <div>
          <div className="title">{video.title}</div>
          <div className="view-count">{parseCount(video.viewCount)}</div>
        </div>
      </div>
      <div className="row3">
        <Avatar variant="brand" src={video.brandLogoUrl} size="sm" type="space" />
        {video.productName}
      </div>
    </div>
  )
}

export default VideoTooltip
