import { apiFetchSimilarChannels } from '../../../api/apis'
import { EuiBasicTable } from '@elastic/eui'
import React from 'react'
import { useQuery } from '@tanstack/react-query'
import { channelListColumns } from './channelListColumns'

const SimilarChannels = ({ channelId, permitted }: { channelId: number; permitted: boolean }) => {
  const { data: similarChannels, isLoading } = useQuery({
    queryKey: ['/v1/channels/', channelId, 'similar'],
    queryFn: () => apiFetchSimilarChannels(channelId),
    enabled: permitted,
  })
  return (
    <EuiBasicTable
      tableCaption="채널 데이터"
      items={similarChannels || []}
      columns={channelListColumns}
      tableLayout={'fixed'}
      loading={isLoading}
      noItemsMessage={<div style={{ minHeight: '200px' }}>No data</div>}
    />
  )
}

export default SimilarChannels
