import { useNavigate, useSearchParams } from 'react-router-dom'
import { KeyboardEventHandler, useEffect, useState } from 'react'
import Group from './Group'
import { Search } from '@mui/icons-material'

interface Props {
  onSearch?: (keyword: string) => void
}

const MobileHeaderSearchInput = ({ onSearch }: Props) => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [searchKeyword, setSearchKeyword] = useState(searchParams.get('keyword') || '')

  useEffect(() => {
    setSearchKeyword(searchParams.get('keyword') || '')
  }, [searchParams])

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = e => {
    if (e.key === 'Enter') {
      if (onSearch) {
        onSearch(searchKeyword)
      }
      navigate(`/search?keyword=${searchKeyword}`)
      e.stopPropagation()
      e.preventDefault()
    }
  }

  return (
    <Group
      direction={'row'}
      spacing={'3'}
      style={{
        flexWrap: 'nowrap',
        alignItems: 'center',
        borderRadius: 'var(--radius)',
        background: 'var(--color-background)',
        padding: '0.25rem 0.75rem',
        border: '1px solid var(--color-border)',
      }}
    >
      <Search />
      <input
        className="global-search-input"
        placeholder="채널, 브랜드, 비디오 검색"
        value={searchKeyword}
        onChange={e => setSearchKeyword(e.target.value)}
        type={'search'}
        onKeyDown={handleKeyDown}
        style={{
          height: '32px',
          width: '100%',
          boxShadow: 'none',
          border: 'none',
          outline: '0px',
          background: 'var(--color-background)',
        }}
      />
    </Group>
  )
}

export default MobileHeaderSearchInput
