import * as React from 'react'
import GlobalStyles from '@mui/joy/GlobalStyles'
import Box from '@mui/joy/Box'
import IconButton from '@mui/joy/IconButton'
import List from '@mui/joy/List'
import ListItem from '@mui/joy/ListItem'
import ListItemButton, { listItemButtonClasses } from '@mui/joy/ListItemButton'
import ListItemContent from '@mui/joy/ListItemContent'
import Typography from '@mui/joy/Typography'
import Sheet from '@mui/joy/Sheet'
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded'

import ColorSchemeToggle from './ColorSchemeToggle'
import { closeSidebar } from './utils'
import Divider from '@mui/joy/Divider'
import Avatar from '@mui/joy/Avatar'
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded'
import {
  AutoAwesomeRounded,
  BookmarkRounded,
  DashboardRounded,
  PlayCircleRounded,
  SearchRounded,
  SwapVerticalCircleRounded,
} from '@mui/icons-material'
import { useLocation, useNavigate } from 'react-router-dom'
import useLoginAuth from '../hooks/useLoginAuth'
import useVerifyLogined from '../hooks/useVerifyLogined'
import Button from '@mui/joy/Button'
import { useSignModalContext } from '../context/SignModalContext'
import Logo from '../component/Logo'
import { apiGetMe } from '../api/apis'
import { useQuery } from '@tanstack/react-query'

const DashboardSidebar = () => {
  const navigate = useNavigate()
  const { actions } = useSignModalContext()
  const { isLogined, role } = useLoginAuth()
  const { signOut } = useLoginAuth()
  const { verifyLogined } = useVerifyLogined()

  const location = useLocation()
  const path = location.pathname

  const { data: me } = useQuery({
    queryKey: [`/v1/me`],
    queryFn: apiGetMe,
    enabled: isLogined,
  })

  const isDashboard = path === '/dashboard'
  const isChannelManage = path.includes('/channels/manage')
  const isChannelExplore = path.includes('/channels') && !isChannelManage
  const isChannelCompare = path === '/channel-compare'
  const isShoutoutManage = path === '/shoutout'
  const isBrand = path.includes('/brands')
  const isShoutoutFeed = path.includes('/shoutout/feeds')
  const isVideo = path.includes('/videos')
  const isCollection = path.includes('/collections')

  return (
    <Sheet
      className="Sidebar"
      sx={{
        position: { xs: 'fixed', md: 'sticky' },
        transform: {
          xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))',
          md: 'none',
        },
        transition: 'transform 0.4s, width 0.4s',
        zIndex: 5000,
        height: '100dvh',
        width: 'var(--Sidebar-width)',
        top: 0,
        p: 2,
        flexShrink: 0,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        borderRight: '1px solid',
        borderColor: 'divider',
      }}
    >
      <GlobalStyles
        styles={theme => ({
          ':root': {
            '--Sidebar-width': '220px',
            [theme.breakpoints.up('lg')]: {
              '--Sidebar-width': '240px',
            },
          },
        })}
      />
      <Box
        className="Sidebar-overlay"
        sx={{
          position: 'fixed',
          zIndex: 9998,
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          opacity: 'var(--SideNavigation-slideIn)',
          backgroundColor: 'var(--joy-palette-background-backdrop)',
          transition: 'opacity 0.4s',
          transform: {
            xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--Sidebar-width, 0px)))',
            lg: 'translateX(-100%)',
          },
        }}
        onClick={() => closeSidebar()}
      />
      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
        <Logo />
        <ColorSchemeToggle sx={{ ml: 'auto' }} />
      </Box>
      <Divider />
      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
        <Avatar variant="outlined" size="sm" src="" />
        {isLogined && (
          <>
            <Box sx={{ minWidth: 0, flex: 1 }}>
              <Typography level="title-sm">{me?.name}</Typography>
              <Typography level="body-xs">{me?.email}</Typography>
            </Box>
            <IconButton
              size="sm"
              variant="plain"
              color="neutral"
              onClick={() => {
                signOut()
                window.location.href = '/'
              }}
            >
              <LogoutRoundedIcon />
            </IconButton>
          </>
        )}
        {!isLogined && (
          <Box sx={{ minWidth: 0, flex: 1, display: 'flex', alignItems: 'center' }}>
            <Typography level="title-sm">유튜브 협업 관리</Typography>
            <Button
              variant="plain"
              size="sm"
              onClick={() => {
                actions.openSignIn('OTHER')
              }}
            >
              시작하기
            </Button>
          </Box>
        )}
      </Box>
      <Divider />
      <Box
        sx={{
          minHeight: 0,
          overflow: 'hidden auto',
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          [`& .${listItemButtonClasses.root}`]: {
            gap: 1.5,
          },
        }}
      >
        <List
          size="sm"
          sx={{
            gap: 1,
            '--List-nestedInsetStart': '30px',
            '--ListItem-radius': theme => theme.vars.radius.sm,
          }}
        >
          <ListItem sx={{ opacity: isLogined ? undefined : '60%' }}>
            <ListItemButton
              selected={isDashboard}
              onClick={e => {
                if (!verifyLogined(e)) {
                  return
                }
                navigate(`/dashboard`)
              }}
            >
              <DashboardRounded />
              <ListItemContent>
                <Typography level="title-sm">대시보드</Typography>
              </ListItemContent>
            </ListItemButton>
          </ListItem>

          {/*<ListItem sx={{ opacity: isLogined ? undefined : '60%' }}>*/}
          {/*  <ListItemButton*/}
          {/*    selected={isShoutoutManage}*/}
          {/*    onClick={e => {*/}
          {/*      if (!verifyLogined(e)) {*/}
          {/*        return*/}
          {/*      }*/}
          {/*      navigate(`/shoutout`)*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    <AutoAwesomeRounded />*/}
          {/*    <ListItemContent>*/}
          {/*      <Typography level="title-sm">받은 샤라웃</Typography>*/}
          {/*    </ListItemContent>*/}
          {/*    {isLogined && (*/}
          {/*      <Chip size="sm" color="primary" variant="solid">*/}
          {/*        4*/}
          {/*      </Chip>*/}
          {/*    )}*/}
          {/*  </ListItemButton>*/}
          {/*</ListItem>*/}

          {/*<ListItem>*/}
          {/*  <ListItemButton>*/}
          {/*    <AssessmentRounded />*/}
          {/*    <ListItemContent>*/}
          {/*      <Typography level="title-sm">PPL 모니터링</Typography>*/}
          {/*    </ListItemContent>*/}
          {/*  </ListItemButton>*/}
          {/*</ListItem>*/}

          {role === 'INFLUENCER' && (
            <ListItem sx={{ opacity: isLogined ? undefined : '60%' }}>
              <ListItemButton
                selected={isChannelManage}
                onClick={e => {
                  navigate(`/channels/manage`)
                }}
              >
                <AutoAwesomeRounded />
                <ListItemContent>
                  <Typography level="title-sm">내 채널 관리</Typography>
                </ListItemContent>
              </ListItemButton>
            </ListItem>
          )}

          <ListItem>
            <ListItemButton
              selected={isChannelExplore}
              onClick={() => {
                navigate(`/channels`)
              }}
            >
              <SearchRounded />
              <ListItemContent>
                <Typography level="title-sm">채널 찾기</Typography>
              </ListItemContent>
            </ListItemButton>
          </ListItem>

          <ListItem>
            <ListItemButton
              selected={isChannelCompare}
              onClick={() => {
                navigate(`/channel-compare`)
              }}
            >
              <SwapVerticalCircleRounded />
              <ListItemContent>
                <Typography level="title-sm">채널 비교</Typography>
              </ListItemContent>
            </ListItemButton>
          </ListItem>

          <ListItem>
            <ListItemButton
              selected={isVideo}
              onClick={() => {
                navigate(`/videos`)
              }}
            >
              <PlayCircleRounded />
              <ListItemContent>
                <Typography level="title-sm">유료광고 트렌드</Typography>
              </ListItemContent>
            </ListItemButton>
          </ListItem>

          <ListItem>
            <ListItemButton
              selected={isBrand}
              onClick={() => {
                navigate(`/brands`)
              }}
            >
              <PlayCircleRounded sx={{ color: '#ffffff00' }} />
              <ListItemContent>
                <Typography level="title-sm">브랜드별 유료광고</Typography>
              </ListItemContent>
            </ListItemButton>
          </ListItem>

          <ListItem>
            <ListItemButton
              selected={isShoutoutFeed}
              onClick={() => {
                navigate(`/shoutout/feeds`)
              }}
            >
              <AutoAwesomeRounded />
              <ListItemContent>
                <Typography level="title-sm">샤라웃</Typography>
              </ListItemContent>
            </ListItemButton>
          </ListItem>

          <ListItem sx={{ opacity: isLogined ? undefined : '60%' }}>
            <ListItemButton selected={isCollection} onClick={() => navigate(`/collections`)}>
              <BookmarkRounded />
              <ListItemContent>
                <Typography level="title-sm">컬렉션</Typography>
              </ListItemContent>
            </ListItemButton>
          </ListItem>

          {/*<ListItem nested>*/}
          {/*  <Toggler*/}
          {/*    renderToggle={({ open, setOpen }) => (*/}
          {/*      <ListItemButton selected={isCollaboration} onClick={() => setOpen(!open)}>*/}
          {/*        <WorkspacesRounded />*/}
          {/*        <ListItemContent>*/}
          {/*          <Typography level="title-sm">협업</Typography>*/}
          {/*        </ListItemContent>*/}
          {/*        <KeyboardArrowDownIcon*/}
          {/*          sx={[*/}
          {/*            open*/}
          {/*              ? {*/}
          {/*                  transform: 'rotate(180deg)',*/}
          {/*                }*/}
          {/*              : {*/}
          {/*                  transform: 'none',*/}
          {/*                },*/}
          {/*          ]}*/}
          {/*        />*/}
          {/*      </ListItemButton>*/}
          {/*    )}*/}
          {/*  >*/}
          {/*    <List sx={{ gap: 0.5 }}>*/}
          {/*      <ListItem sx={{ mt: 0.5 }}>*/}
          {/*        <ListItemButton>인사이트</ListItemButton>*/}
          {/*      </ListItem>*/}
          {/*      <ListItem>*/}
          {/*        <ListItemButton>Editor's pick</ListItemButton>*/}
          {/*      </ListItem>*/}
          {/*      <ListItem>*/}
          {/*        <ListItemButton>MCN</ListItemButton>*/}
          {/*      </ListItem>*/}
          {/*      <ListItem>*/}
          {/*        <ListItemButton>마케팅대행사</ListItemButton>*/}
          {/*      </ListItem>*/}
          {/*    </List>*/}
          {/*  </Toggler>*/}
          {/*</ListItem>*/}
        </List>
        <List
          size="sm"
          sx={{
            mt: 'auto',
            flexGrow: 0,
            '--ListItem-radius': theme => theme.vars.radius.sm,
            '--List-gap': '8px',
            mb: 2,
          }}
        >
          <ListItem sx={{ opacity: isLogined ? undefined : '60%' }}>
            <ListItemButton
              onClick={() => {
                navigate(`/mypage`)
              }}
            >
              <SettingsRoundedIcon />
              설정
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton
              onClick={() => {
                navigate(`/`)
              }}
            >
              <LogoutRoundedIcon />홈 화면으로 가기
            </ListItemButton>
          </ListItem>
        </List>
        {/*<Card invertedColors variant="soft" color="warning" size="sm" sx={{ boxShadow: 'none' }}>*/}
        {/*  <Stack direction="row" sx={{ justifyContent: 'space-between', alignItems: 'center' }}>*/}
        {/*    <Typography level="title-sm">열람 횟수 사용</Typography>*/}
        {/*    <IconButton size="sm">*/}
        {/*      <CloseRoundedIcon />*/}
        {/*    </IconButton>*/}
        {/*  </Stack>*/}
        {/*  <Typography level="body-xs">*/}
        {/*    무료 요금제에 사용 가능한 횟수의 80% 이상 사용하였습니다.*/}
        {/*  </Typography>*/}
        {/*  <LinearProgress variant="outlined" value={80} determinate sx={{ my: 1 }} />*/}
        {/*  <Button size="sm" variant="solid">*/}
        {/*    Upgrade plan*/}
        {/*  </Button>*/}
        {/*</Card>*/}
      </Box>
    </Sheet>
  )
}

function Toggler({
  defaultExpanded = false,
  renderToggle,
  children,
}: {
  defaultExpanded?: boolean
  children: React.ReactNode
  renderToggle: (params: {
    open: boolean
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
  }) => React.ReactNode
}) {
  const [open, setOpen] = React.useState(defaultExpanded)
  return (
    <React.Fragment>
      {renderToggle({ open, setOpen })}
      <Box
        sx={[
          {
            display: 'grid',
            transition: '0.2s ease',
            '& > *': {
              overflow: 'hidden',
            },
          },
          open ? { gridTemplateRows: '1fr' } : { gridTemplateRows: '0fr' },
        ]}
      >
        {children}
      </Box>
    </React.Fragment>
  )
}

export default DashboardSidebar
