import { useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import { apiChangePassword } from '../../../api/apis'
import Modal from 'component/Modal'
import TextField from 'component/TextField'
import { Stack } from '@mui/joy'

const PasswordResetModal = ({ closeModal }: { closeModal: VoidFunction }) => {
  const [prevPassword, setPrevPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('')

  const { mutate: changePassword } = useMutation({
    mutationFn: apiChangePassword,
    onSuccess: () => {
      window.alert('비밀번호가 변경되었습니다.')
      closeModal()
    },
  })

  const onClickSave = () => {
    if (!prevPassword) {
      window.alert('기존 비밀번호를 입력해주세요.')
      return
    }
    if (!newPassword) {
      window.alert('새 비밀번호를 입력해주세요.')
      return
    }
    if (!newPasswordConfirm) {
      window.alert('새 비밀번호 확인을 입력해주세요.')
      return
    }
    if (newPassword !== newPasswordConfirm) {
      window.alert('새 비밀번호가 일치하지 않습니다.')
      return
    }

    changePassword({ prevPassword, newPassword })
  }

  return (
    <Modal
      title="비밀번호 변경"
      onClose={closeModal}
      primaryAction={{ label: '변경', onClick: onClickSave }}
      secondaryAction={{ label: '닫기', onClick: closeModal }}
    >
      <Stack direction="column" spacing={1}>
        <TextField
          label="기존 비밀번호"
          name="prevPassword"
          type="password"
          value={prevPassword}
          onChange={(e: any) => setPrevPassword(e.target.value)}
        />
        <TextField
          label="새 비밀번호"
          name="newPassword"
          type="password"
          value={newPassword}
          onChange={(e: any) => setNewPassword(e.target.value)}
        />
        <TextField
          label="새 비밀번호(확인)"
          name="newPasswordConfirm"
          type="password"
          value={newPasswordConfirm}
          onChange={(e: any) => setNewPasswordConfirm(e.target.value)}
        />
      </Stack>
    </Modal>
  )
}

export default PasswordResetModal
