import Button from '../component/button/Button'
import React, { KeyboardEventHandler } from 'react'
import { useQuery } from '@tanstack/react-query'
import { apiGetBrands } from '../api/apis'
import Avatar from '../component/Avatar'
import Group from '../component/Group'
import SpinnerWithBox from '../component/SpinnerWithBox'
import Modal from 'component/Modal'
import { Typography } from '@mui/joy'
import TextField from 'component/TextField'
interface Props {
  onSelect: (brandId: number, brandName: string, brandLogoUrl: string | undefined) => void
  closeModal: VoidFunction
  openBrandCreateModal: (brandName: string) => void
}

const BrandSelectModal = ({ onSelect, closeModal, openBrandCreateModal }: Props) => {
  const [value, setValue] = React.useState('')

  const {
    data: brands,
    refetch,
    isLoading,
  } = useQuery({
    queryKey: ['searchBrand', value],
    queryFn: () => apiGetBrands({ keyword: value }),
    enabled: false,
  })

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = e => {
    if (e.key === 'Enter') {
      if (!value) {
        return
      }
      e.stopPropagation()
      e.preventDefault()

      refetch()
    }
  }

  return (
    <Modal title="브랜드 선택" onClose={closeModal}>
      <TextField
        placeholder="브랜드 이름 검색"
        name="value"
        value={value}
        onChange={(e: any) => setValue(e.target.value)}
        onKeyDown={handleKeyDown}
        label={undefined}
      />
      <div style={{ height: 'var(--space-6)' }}></div>
      <Group direction={'column'} spacing={'4'}>
        {isLoading && <SpinnerWithBox />}
        {brands?.brands?.map(brand => {
          return (
            <Button
              variant={'text'}
              onClick={() => {
                onSelect(brand.brandId, brand.brandName, brand.logoUrl)
                closeModal()
              }}
            >
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 'var(--space-2)',
                }}
                key={brand.brandId}
              >
                <Avatar
                  name={brand.brandName}
                  variant="brand"
                  size="lg"
                  src={brand.logoUrl}
                  type={'space'}
                />
                <Typography level="title-sm">{brand.brandName}</Typography>
              </div>
            </Button>
          )
        })}
        {!!brands?.brands && brands.brands.length === 0 && (
          <Group
            style={{
              alignItems: 'center',
              marginTop: 'var(--space-4)',
            }}
          >
            <Typography level="body-sm">브랜드 검색 결과가 없습니다.</Typography>
            <Button
              variant={'tertiary'}
              size={'s'}
              onClick={() => {
                closeModal()
                openBrandCreateModal(value)
              }}
            >
              추가하기
            </Button>
          </Group>
        )}
        {!!brands?.brands && brands.brands.length > 0 && (
          <Group
            style={{
              alignItems: 'center',
              marginTop: 'var(--space-4)',
            }}
          >
            <Typography level="body-sm">원하는 브랜드가 없다면 추가해주세요.</Typography>
            <Button
              variant={'tertiary'}
              size={'s'}
              onClick={() => {
                closeModal()
                openBrandCreateModal(value)
              }}
            >
              추가하기
            </Button>
          </Group>
        )}
      </Group>
    </Modal>
  )
}

export default BrandSelectModal
