import useLoginAuth from './useLoginAuth'
import { useSignModalContext } from '../context/SignModalContext'

const useVerifyLogined = () => {
  const { isLogined } = useLoginAuth()
  const { actions } = useSignModalContext()

  const verifyLogined = (e: React.UIEvent) => {
    if (!isLogined) {
      e.preventDefault()
      e.stopPropagation()
      actions.openSignIn('OTHER')
      return false
    }

    return true
  }

  return { verifyLogined }
}

export default useVerifyLogined
