import { BookmarkGroupItem } from '../../../api/types'
import Tile from '../../../component/Tile'
import Title from '../../../component/texts/Text'
import Button from '../../../component/button/Button'
import { IconTrash } from '@tabler/icons-react'
import Group from '../../../component/Group'
import Avatar from '../../../component/Avatar'

export const BookmarkGroupRow = ({
  group,
  onDelete,
}: {
  group: BookmarkGroupItem
  onDelete: (bookmarkGroupId: number) => void
}) => {
  return (
    <Tile size="large">
      <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
        <Title level="3">{group.name}</Title>
        <div style={{ marginLeft: 'auto' }}>
          {group.owner && (
            <Button
              variant="tertiary"
              size="s"
              onClick={(e: React.MouseEvent) => {
                e.preventDefault()
                e.stopPropagation()
                onDelete(group.bookmarkGroupId)
              }}
            >
              {/* TODO Icon container component */}
              <IconTrash size={18} />
            </Button>
          )}
        </div>
      </div>

      {group?.channels.length > 0 && (
        <Group style={{ alignItems: 'center', margin: 'var(--space-4) 0 0 0' }}>
          {group?.channels.map((item, index) => {
            return (
              <Avatar
                key={item.channelId}
                size="sm"
                src={item.thumbnailUrl}
                style={{
                  marginLeft: index === 0 ? '' : '-1rem',
                  outline: '2px solid var(--color-background)',
                }}
              />
            )
          })}
          <p>{`${group.channels[0]?.name} 외 ${group.count - 1}개 채널`}</p>
        </Group>
      )}
    </Tile>
  )
}

export default BookmarkGroupRow
