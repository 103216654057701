import Box from '@mui/joy/Box'
import * as React from 'react'
import DashboardPageBody from '../../../layout/DashboardPageBody'

const ShoutoutManage = () => {
  return (
    <DashboardPageBody title="받은 샤라웃" breadcrumbs={[{ label: 'Shout out' }]}>
      <Box>아직 받은 샤라웃이 없어요.. 프리미엄을 표시하면 더 많은 샤라웃을 받을 수 있습니다.</Box>
    </DashboardPageBody>
  )
}

export default ShoutoutManage
