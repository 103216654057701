export const sampleChannelId = 20123

export const channelFilteringOptions = [
  {
    value: 'all',
    text: '구독자수 전체',
  },
  {
    value: '5000000',
    text: '구독자수 500만 이상',
  },
  {
    value: '3000000',
    text: '구독자수 300만 ~ 500만',
  },
  {
    value: '1000000',
    text: '구독자수 100만 ~ 300만',
  },
  {
    value: '500000',
    text: '구독자수 50만 ~ 100만',
  },
  {
    value: '300000',
    text: '구독자수 30만 ~ 50만',
  },
  {
    value: '100000',
    text: '구독자수 10만 ~ 30만',
  },
  {
    value: '50000',
    text: '구독자수 5만 ~ 10만',
  },
  {
    value: '30000',
    text: '구독자수 3만 ~ 5만',
  },
  {
    value: '10000',
    text: '구독자수 1만 ~ 3만',
  },
]

export const getFollowerMinMax = (follower: string) => {
  switch (follower) {
    case '5000000':
      return [5000000, 100000000]
    case '3000000':
      return [3000000, 5000000]
    case '1000000':
      return [1000000, 3000000]
    case '500000':
      return [500000, 1000000]
    case '300000':
      return [300000, 500000]
    case '100000':
      return [100000, 300000]
    case '50000':
      return [50000, 100000]
    case '30000':
      return [30000, 50000]
    case '10000':
      return [10000, 30000]
    default:
      return [undefined, undefined]
  }
}

export const videoSorts = [
  { value: 'latest', text: '최신순' },
  { value: 'view', text: '조회수순' },
  { value: 'like', text: '좋아요순' },
  { value: 'comment', text: '댓글많은순' },
]

export const channelSorts = [
  { value: 'view', text: '조회수' },
  { value: 'follower', text: '구독자' },
  { value: 'like', text: '좋아요수' },
  { value: 'comment', text: '댓글수' },
]

// ID, Code
export type TempTypes = {
  categoryId: number
  categoryCode: string
  color: string
  name: string
  imageSrc?: string
  size?: string
  adjust?: string
  emoji?: string
}

export const categoryAssets: TempTypes[] = [
  {
    categoryId: 0,
    categoryCode: '',
    name: '전체 카테고리',
    color: '#ffe0ea',
    imageSrc: undefined,
    emoji: undefined,
  },
  {
    categoryId: 1,
    categoryCode: '0100',
    name: '뷰티',
    color: '#ffe0ea',
    imageSrc: '/image/lotion_bottle_3d.png',
    emoji: '👄',
  },
  {
    categoryId: 2,
    categoryCode: '0200',
    name: '패션',
    color: '#fbdfff',
    imageSrc: '/image/sunglasses_3d.png',
    emoji: '🎀',
  },
  {
    categoryId: 3,
    categoryCode: '0300',
    name: '식음료',
    color: '#ffa8a8',
    imageSrc: '/image/red_apple_3d.png',
    emoji: '🍚',
  },
  {
    categoryId: 4,
    categoryCode: '0400',
    name: '앱서비스',
    color: 'crimson',
    imageSrc: '/image/balloon_3d.png',
    emoji: '📱',
  },
  {
    categoryId: 5,
    categoryCode: '0500',
    name: '게임',
    color: '#ffd3d3',
    imageSrc: '/image/joystick_3d.png',
    emoji: '🕹️',
  },
  {
    categoryId: 6,
    categoryCode: '0600',
    name: '테크',
    color: '#8ce4ef',
    imageSrc: '/image/desktop_computer_3d.png',
    emoji: '🖥️',
  },
  {
    categoryId: 7,
    categoryCode: '0700',
    name: '리빙',
    color: '#fee3d9',
    imageSrc: '/image/door_3d.png',
    emoji: '🏠',
  },
  {
    categoryId: 8,
    categoryCode: '0800',
    name: '문화취미',
    color: '#d4f8ff',
    imageSrc: '/image/tennis_3d.png',
    emoji: '🎞️',
  },
  {
    categoryId: 9,
    categoryCode: '0900',
    name: '건강운동',
    color: '#d8f8a3',
    imageSrc: '/image/woman_in_lotus_position_3d_default.png',
    emoji: '💪',
  },
  {
    categoryId: 10,
    categoryCode: '1000',
    name: '교육기술',
    color: '#e8b4bf',
    imageSrc: '/image/balloon_3d.png',
    emoji: '🏫',
  },
  {
    categoryId: 11,
    categoryCode: '1100',
    name: '여행',
    color: '#e8b4bf',
    imageSrc: '/image/world_map_3d.png',
    emoji: '🧳',
  },
  {
    categoryId: 12,
    categoryCode: '1200',
    name: '반려동물',
    color: '#ffebd1',
    imageSrc: '/image/dog_face_3d.png',
    emoji: '🐶',
  },
  {
    categoryId: 13,
    categoryCode: '1300',
    name: '금융재테크',
    color: '#fff6c9',
    imageSrc: '/image/money_bag_3d.png',
    emoji: '💰',
  },
  {
    categoryId: 14,
    categoryCode: '1400',
    name: '자동차',
    color: '#d7e0ff',
    imageSrc: '/image/oncoming_automobile_3d.png',
    emoji: '🚘',
    // adjust: '-6px',
  },
]

export interface ChannelCategory {
  code: string
  kor: string
  eng: string
}

export const ChannelCategories = [
  { code: 'PEOPLE', kor: 'VLOG·개인방송', eng: '' },
  { code: 'FASHION', kor: '패션·뷰티', eng: '' },
  { code: 'GAMING', kor: '게임', eng: '' },
  { code: 'HOBBY', kor: '취미', eng: '' },
  { code: 'REVIEW', kor: '상품리뷰', eng: '' },
  { code: 'SCIENCE', kor: '테크·과학', eng: '' },
  { code: 'STYLE', kor: '교육·지식·노하우', eng: '' },
  { code: 'PET', kor: '반려동물·동물', eng: '' },
  { code: 'NEWS', kor: '뉴스·정치', eng: '' },
  { code: 'SPORTS', kor: '스포츠', eng: '' },
  { code: 'AUTOS', kor: '자동차·교통', eng: '' },
  { code: 'ENTERTAINMENT', kor: '엔터테인먼트·방송', eng: '' },
  { code: 'TRAVEL', kor: '여행·캠핑', eng: '' },
  { code: 'FILM', kor: '영화·애니메이션', eng: '' },
  { code: 'MUSIC', kor: '음악', eng: '' },
  { code: 'COMEDY', kor: '코미디', eng: '' },
  { code: 'HEALTH', kor: '운동·건강', eng: '' },
  { code: 'FOOD', kor: '음식·요리', eng: '' },
  { code: 'ECONOMICS', kor: '경제·재테크', eng: '' },
  { code: 'HUMANITIES', kor: '문화·예술·종교·역사', eng: '' },
  { code: 'IMPROVEMENT', kor: '자기계발·독서', eng: '' },
] as ChannelCategory[]
