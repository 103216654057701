import React, { Suspense, useEffect } from 'react'
import { BrowserRouter, Outlet, Route, Routes, useLocation } from 'react-router-dom'
import 'dayjs/locale/ko'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { CssVarsProvider } from '@mui/joy/styles'
import CssBaseline from '@mui/joy/CssBaseline'
import Box from '@mui/joy/Box'
import theme from './theme'

import GoogleTagManager from './util/GoogleTagManager'
import { MetaTags } from './util/MetaTags'
import HomeFooter from 'layout/HomeFooter'
import HomeHeader from './layout/HomeHeader'
import DashboardSidebar from './layout/DashboardSidebar'
import DashboardHeader from './layout/DashboardHeader'
import SignUpModal from './modal/SiginUpModal'
import SignInModal from './modal/SiginInModal'
import SignUpCompleteModal from './modal/SignUpCompleteModal'

import Home from 'page/Home/page'
import Terms from 'page/Terms/page'
import SignupYoutuber from 'page/Signup/page'
import ChannelExplore from 'page/Dashboard/ChannelExplore/page'
import ChannelDetail from 'page/Dashboard/ChannelDetail/page'
import VideoExplore from 'page/Dashboard/VideoExplore/page'
import ShoutoutManage from 'page/Dashboard/ShoutoutManage/page'
import ShoutoutFeed from 'page/Dashboard/ShoutoutFeed/page'
import BrandExplore from 'page/Dashboard/BrandExplore/page'
import Collection from 'page/Dashboard/Collection/page'
import Dashboard from 'page/Dashboard/Dashboard/page'
import BrandDetail from 'page/Dashboard/BrandDetail/page'
import CollectionDetail from 'page/Dashboard/CollectionDetail/page'
import DashboardMypage from 'page/Dashboard/Mypage/page'
import ChannelCompare from 'page/Dashboard/ChannelCompare/page'
import VideoDetail from './page/Dashboard/VideoDetail/page'
import ChannelManage from 'page/Dashboard/ChannelManage/page'
import ChannelManageList from './page/Dashboard/ChannelManageList/page'

dayjs.extend(relativeTime)
dayjs.locale('ko')

function ScrollToTop() {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}

// TODO :: 라우터에 suspense를 주어야 카테고리 클릭 시 오류가 발생하지 않음. 하지만 상위 suspense의 fallback을 그리는 문제.
function App() {
  return (
    <Suspense fallback={null}>
      <GoogleTagManager />
      <CssVarsProvider disableTransitionOnChange theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <ScrollToTop />
          <Routes>
            <Route
              element={
                <>
                  <MetaTags />
                  <HomeHeader />
                  <Outlet />
                  <HomeFooter />
                  <SignInModal />
                  <SignUpModal />
                  <SignUpCompleteModal />
                </>
              }
            >
              <Route path="/" element={<Home />} />
              <Route path="/signup-youtuber" element={<SignupYoutuber />} />
            </Route>

            <Route
              element={
                <Box
                  sx={{
                    display: 'flex',
                    minHeight: '100dvh',
                    fontFamily: "'Pretendard', -apple-system, BlinkMacSystemFont, sans-serif",
                  }}
                >
                  <MetaTags />
                  <DashboardHeader />
                  <DashboardSidebar />
                  <Outlet />
                  <SignInModal />
                  <SignUpModal />
                  <SignUpCompleteModal />
                </Box>
              }
            >
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/channels" element={<ChannelExplore />} />
              <Route path="/channels/:channelId" element={<ChannelDetail />} />
              <Route path="/channel-compare" element={<ChannelCompare />} />
              <Route path="/channels/manage" element={<ChannelManageList />} />
              <Route path="/channels/manage/:channelId" element={<ChannelManage />} />
              <Route path="/videos" element={<VideoExplore />} />
              <Route path="/videos/:videoId" element={<VideoDetail />} />
              <Route path="/shoutout" element={<ShoutoutManage />} />
              <Route path="/shoutout/feeds" element={<ShoutoutFeed />} />
              <Route path="/brands" element={<BrandExplore />} />
              <Route path="/brands/:brandId" element={<BrandDetail />} />
              <Route path="/collections" element={<Collection />} />
              <Route path="/collections/:collectionId" element={<CollectionDetail />} />
              <Route path="/mypage" element={<DashboardMypage />} />
            </Route>

            <Route path="/terms-of-service" element={<Terms />} />
            <Route path="/privacy-policy" element={<Terms />} />
          </Routes>
        </BrowserRouter>
      </CssVarsProvider>
    </Suspense>
  )
}

export default App
