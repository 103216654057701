import axios from 'axios'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  BookmarkGroupItem,
  BookmarkGroupResponse,
  BrandResponse,
  CategoryDashboardResponse,
  ChannelSearchResponse,
  GetBookmarksResponse,
  GetCategoriesResponse,
  SearchChannelResponseItem,
  VideoSummaryResponseItem,
} from './types'
import queryKeys from './queryKeys'

export const apiFetchChannel = async (channelId: number) => {
  const res = await axios.get<SearchChannelResponseItem>(`/v1/channels/${channelId}`)
  return res.data
}

export const useFetchChannel = (channelId: number) => {
  return useQuery({
    queryKey: ['/v1/channels/', channelId],
    queryFn: () => apiFetchChannel(channelId),
    enabled: !!channelId,
  })
}

export const apiFetchSimilarChannels = async (channelId: number) => {
  const res = await axios.get<SearchChannelResponseItem[]>(`/v1/channels/${channelId}/similar`)
  return res.data
}

interface SignupAsAdvertiserRequest {
  email: string
  password: string
  corpName: string
  name: string
  phone: string
  agree1: boolean
  agree2: boolean
}

interface SignupAsYoutuberRequest {
  email: string
  password: string
  name: string
  phone: string
  agree1: boolean
  agree2: boolean
}

interface SignupOAuth2Request {
  type: string
  corpName: string
  name: string
  phone: string
  agree1: boolean
  agree2: boolean
}

export const apiOAuth2GoogleCallback = async (code: string) => {
  const res = await axios.post(`/v1/oauth2/google/callback`, {
    code,
  })
  return res.data
}

export const apiSignupAdvertiser = async (payload: SignupAsAdvertiserRequest) => {
  const res = await axios.post<{ memberId: number }>(`/v1/advertiser/signup`, payload)
  return res.data
}

export const useSignupAdvertiser = () => {
  return useMutation({ mutationFn: apiSignupAdvertiser })
}

export const apiSignupYoutuber = async (payload: SignupAsYoutuberRequest) => {
  const res = await axios.post<{ memberId: number }>(`/v1/youtuber/signup`, payload)
  return res.data
}

export const apiSignupOAuth2 = async (payload: SignupOAuth2Request) => {
  const res = await axios.post(`/v1/oauth2/signup`, payload)
  return res.data
}

export const useSignupOAuth2 = () => {
  return useMutation({ mutationFn: apiSignupOAuth2 })
}

interface SigninRequest {
  email: string
  password: string
}

export const apiSignin = async (payload: SigninRequest) => {
  const res = await axios.post<{ token: string }>(`/v1/signin`, payload).then(res => {
    return res
  })
  return res.data
}

export const useSignin = () => {
  return useMutation({ mutationFn: apiSignin })
}

const apiGetCategories = async () => {
  const res = await axios.get<GetCategoriesResponse>(`/v1/categories`)
  return res.data
}

export const useCategories = () => {
  return useQuery({ queryKey: [`/v1/categories`], queryFn: apiGetCategories })
}

export const apiSearchChannel = async (params: SearchChannelParams) => {
  const res = await axios.get<ChannelSearchResponse>(`/v1/channels`, { params })
  return res.data
}

export interface SearchChannelParams {
  search: string | undefined
  channelCategory?: string
  minFollower?: number
  maxFollower?: number
  adOnly: boolean
  sort: string
  page: number
}

export const useCategoryChannelData = (params: SearchChannelParams) => {
  return useQuery({
    queryKey: ['/v1/channels', params],
    queryFn: () => apiSearchChannel(params),
  })
}

export interface SearchVideoParams {
  categoryCode: string
  channelId?: number
  adOnly?: boolean
  brandId?: number
  startPublishedAt?: string
  endPublishedAt?: string
  sort?: string
  page?: number
  pageSize?: number
  forCompare?: boolean
  keyword?: string
}

export const apiSearchVideo = async (params: SearchVideoParams) => {
  const res = await axios.get<CategoryDashboardResponse>(`/v1/videos`, { params })
  return res.data
}

export interface GetBookmarkGroupRequest {
  channelId?: number
  needChannelSize?: number
}

export const apiGetBookmarkGroup = async (params?: GetBookmarkGroupRequest) => {
  const res = await axios.get<BookmarkGroupResponse>(`/v1/bookmark-groups`, { params })
  return res.data
}

export const useGetBookmarkGroup = (params?: GetBookmarkGroupRequest) => {
  return useQuery({
    queryKey: queryKeys.getBookmarkGroups(params),
    queryFn: () => apiGetBookmarkGroup(params),
  })
}

export const apiGetBookmarkGroupDetail = async (bookmarkGroupId: string) => {
  const res = await axios.get<BookmarkGroupItem>(`/v1/bookmark-groups/${bookmarkGroupId}`)
  return res.data
}

export const createKeyBookmarkGroupDetail = (bookmarkGroupId: string) => [
  `/v1/bookmark-groups/${bookmarkGroupId}`,
]

export const useGetBookmarkGroupDetail = (bookmarkGroupId: string | undefined) => {
  return useQuery({
    queryKey: createKeyBookmarkGroupDetail(bookmarkGroupId!!),
    queryFn: () => apiGetBookmarkGroupDetail(bookmarkGroupId!!),
    enabled: !!bookmarkGroupId,
  })
}

export const apiGetBookmarks = async (params: {
  bookmarkGroupId: string
  sort: SortType | string
}) => {
  const res = await axios.get<GetBookmarksResponse>(`/v1/bookmarks`, { params })
  return res.data
}

export enum SortType {
  'latest',
  'follower',
  'view',
  'like',
  'count',
}

export const useGetBookmarks = (bookmarkGroupId: string | undefined, sort: SortType | string) => {
  return useQuery({
    queryKey: [`/v1/bookmarks`, bookmarkGroupId],
    queryFn: () =>
      apiGetBookmarks({
        bookmarkGroupId: bookmarkGroupId!!,
        sort,
      }),
    enabled: !!bookmarkGroupId,
  })
}

export interface PostBookmarkGroupRequest {
  bookmarkGroupId?: number
  name: string
  shareType: 'PRIVATE' | 'PUBLIC' | 'TEAM' | string
}

export const apiPostBookmarkGroup = async (payload: PostBookmarkGroupRequest) => {
  const res = await axios.post(`/v1/bookmark-groups`, payload)
  return res.data
}

export const apiDeleteBookmarkGroup = async (bookmarkGroupId: number) => {
  const res = await axios.delete(`/v1/bookmark-groups/${bookmarkGroupId}`)
  return res.data
}

export const useDeleteBookmarkGroup = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: apiDeleteBookmarkGroup,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.getBookmarkGroups() })
    },
  })
}

export const apiPutBookmark = async (payload: { channelId: number; groupIds: number[] }) => {
  const res = await axios.put(`/v1/bookmarks`, payload)
  return res.data
}

export const usePutBookmark = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: apiPutBookmark,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.getBookmarkGroups() })
    },
  })
}

export const apiBrandDetail = async (brandId: number) => {
  const res = await axios.get<BrandResponse>(`/v1/brands/${brandId}`)
  return res.data
}

export const useBrandDetail = (brandId: string | undefined) => {
  return useQuery({
    queryKey: [`/v1/brands/${brandId}`],
    queryFn: () => apiBrandDetail(Number(brandId!!)),
    enabled: !!brandId,
  })
}

export const apiPPLBrands = async (params: GetBrandPPLRequest) => {
  const res = await axios.get<BrandResponse[]>(`/v1/brands/ppl`, { params })
  return res.data
}

export interface GetBrandPPLRequest {
  search?: string
  categoryCode?: String
  page?: number
  pageSize?: number
}

export const apiGetBrands = async (params: { keyword: string }) => {
  const res = await axios.get<{ brands: BrandResponse[] }>(`/v1/brands`, { params })
  return res.data
}

interface UserGetMeResponse {
  status: 'VERIFYING_EMAIL' | 'ACTIVATED' | 'DEACTIVATED'
  verify: 'NEED_VERIFY' | 'PENDING' | 'VERIFIED' | 'REJECTED'
  email: string
  name: string
  phone: string
  type: 'ADVERTISER' | 'INFLUENCER'
  corpName?: string
  corpPosition?: string
}

export const apiGetMe = async () => {
  const res = await axios.get<UserGetMeResponse>(`/v1/me`)
  return res.data
}

const apiMyChannels = async () => {
  const res = await axios.get<RequestChannelOwnershipResponse[]>(`/v1/channel-ownerships`)
  return res.data
}

export const useMyChannels = () => {
  return useQuery({
    queryKey: [`/v1/channel-owners`],
    queryFn: apiMyChannels,
  })
}

const apiGetMyChannelSettings = async (channelId: number) => {
  const res = await axios.get<ChannelSettingResponse>(`/v1/channels/${channelId}/settings`)
  return res.data
}

export const useMyChannelSettings = (channelId: number) => {
  return useQuery({
    queryKey: queryKeys.myChannelSettings(channelId),
    queryFn: () => apiGetMyChannelSettings(channelId),
  })
}

export interface ChannelOffers {
  pplOffer?: boolean
  pplPrice?: string
  ppplOffer?: boolean
  ppplPrice?: string
  brandedOffer?: boolean
  brandedPrice?: string
}

interface ChannelSettingResponse {
  channelKey: string
  name: string
  country?: string
  category?: string
  channelType?: string
  customUrl?: string
  thumbnailUrl: string
  email?: string
  mcn?: string
  heroGender?: string
  heroAge?: number
  sns: ChannelSettingSns[]
  audienceRequest?: ChannelSettingAudienceRequest | null
  offers?: ChannelOffers
}

export type ChannelAudienceRequestStatus = 'PENDING' | 'ACCEPTED' | 'REJECTED'

interface ChannelSettingAudienceRequest {
  imageUrl: string
  status: ChannelAudienceRequestStatus
}

export interface ChannelSettingSns {
  snsType: string
  snsAlias: string
}

const apiUpdateChannelSettings = async (channelId: number, payload: ChannelUpdateRequest) => {
  const res = await axios.put(`/v1/channels/${channelId}/settings`, payload)
  return res.data
}

export const useUpdateChannelSettings = (channelId: number) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (payload: ChannelUpdateRequest) => apiUpdateChannelSettings(channelId, payload),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.myChannelSettings(channelId) })
    },
  })
}

export type ChannelUpdateRequest = {
  email: string
  mcn: string
  audienceUrl: string | null
  category: string | null
  heroGender: string | null
  heroAge: string | null
  sns: ChannelSnsUpdate[]
}

type ChannelSnsUpdate = {
  type: string
  alias: string
}

export const apiUploadAudienceImage = async (channelId: number, file: File) => {
  const formData = new FormData()
  formData.append('file', file)
  const res = await axios.post<{ fileUrl: string }>(
    `/v1/channels/${channelId}/settings/audience`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  )
  return res.data
}

interface RequestChannelOwnershipRequest {
  channelUrl: string
  email: string
}

export type ChannelOwnershipStatus =
  | 'REQUESTED'
  | 'VERIFIED'
  | 'ACTIVATED'
  | 'REJECTED'
  | 'CANCELED'

export interface RequestChannelOwnershipResponse {
  ownershipRequestId: number
  status: ChannelOwnershipStatus
  email: string
  channelUrl: string
  rejectReason: string | null
  channelId?: number
  channelThumbnail?: string
  channelName?: string
}

export const apiChannelOwnershipRequest = async (payload: RequestChannelOwnershipRequest) => {
  const res = await axios.post<RequestChannelOwnershipResponse>(`/v1/channel-ownerships`, payload)
  return res.data
}

export interface VerifyChannelOwnerRequest {
  otp: string
}

export const apiVerifyChannelOwnership = async (
  ownerRequestId: number,
  payload: VerifyChannelOwnerRequest,
) => {
  const res = await axios.post(`/v1/channel-ownerships/${ownerRequestId}/verify`, payload)
  return res.data
}

export const apiChangePassword = async (payload: { prevPassword: string; newPassword: string }) => {
  const res = await axios.put(`/v1/me/password`, payload)
  return res.data
}

export const apiUpdateChannelOffers = async (channelId: number, payload: ChannelOffers) => {
  const res = await axios.put(`/v1/channels/${channelId}/settings/offers`, payload)
  return res.data
}

export const apiGetChannelCompareDetails = async (channelIds: string[]) => {
  const res = await axios.get<SearchChannelResponseItem[]>(
    `/v1/channels/compare?channelIds=${channelIds.join(',')}`,
  )
  return res.data
}

export const apiVerifySignupEmail = async (payload: { secret: string }) => {
  const res = await axios.post(`/v1/signup/verification`, payload)
  return res.data
}

export const apiResendSignupEmail = async () => {
  const res = await axios.post(`/v1/signup/resend-verification`)
  return res.data
}

export interface SearchParams {
  types: SearchType[]
  keyword: string
  page: number
  pageSize?: number
}

export type SearchType = 'CHANNEL' | 'VIDEO' | 'BRAND'

export const apiSearch = async (params: SearchParams) => {
  const payload = {
    ...params,
    types: params.types.join(','),
  }
  const res = await axios.get<SearchResponse>(`/v1/search`, { params: payload })
  return res.data
}

interface SearchResponse {
  channels: SearchResponseItemWrap<ChannelSearchResponseItem> | null
  videos: SearchResponseItemWrap<VideoSearchResponseItem> | null
  brands: SearchResponseItemWrap<BrandSearchResponseItem> | null
}

interface SearchResponseItemWrap<T> {
  totalElements: number
  items: T[]
}

interface VideoSearchResponseItem {
  videoId: number
  channelId: number
  title: string
  videoType?: string
  adType?: string
  channelName?: string
  channelThumbnailUrl?: string
  categoryCode?: string
  categoryName?: string
  brandName?: string
  brandLogoUrl?: string
  productName?: string
  viewCount: number
  likeCount: number
  commentCount: number
  publishedAt?: Date
  duration?: string
  thumbnailUrl: string
}

interface ChannelSearchResponseItem {
  channelId: number
  name: string
  thumbnailUrl: string
  category?: string
  followerCount: number
  contentsCount: number
  country?: string
}

interface BrandSearchResponseItem {
  brandId: number
  brandName: string
  logoUrl?: string
}

export const apiHomeVideos = async () => {
  const res = await axios.get<CategoryDashboardResponse>(`/v1/home/videos`)
  return res.data
}

export const apiUpdateVideoSettings = async (videoId: number, payload: VideoUpdateRequest) => {
  const res = await axios.put(`/v1/videos/${videoId}/settings`, payload)
  return res.data
}

export interface VideoUpdateRequest {
  highlight: string
  brandId?: number
  brandName?: string
  productName?: string
  adType: undefined | 'AD' | 'PPL' | 'BRANDED'
}

export const apiMyGetShoutout = async (params: GetMyShoutoutListRequest) => {
  const res = await axios.get<ShoutoutResponse>(`/v1/shoutout`, { params })
  return res.data
}

interface GetMyShoutoutListRequest {
  channelId: number
  page: number
  pageSize: number
}

interface ShoutoutResponse {
  items: ShoutoutResponseItem[]
  totalCount: number
  totalPage: number
}

export const apiUpdateShoutout = async (payload: ShoutoutUpdateRequest) => {
  const res = await axios.put(`/v1/shoutout`, payload)
  return res.data
}

export interface ShoutoutUpdateRequest {
  shoutoutId?: number
  brandId: number
  channelId: number
  videoId: number
  highlight?: string
}

export const apiGetVideo = async (videoId: number) => {
  const res = await axios.get<VideoSummaryResponseItem>(`/v1/videos/${videoId}`)
  return res.data
}

export const apiGetBrand = async (brandId: number) => {
  const res = await axios.get<BrandResponse>(`/v1/brands/${brandId}`)
  return res.data
}

export const apiCreateBrand = async (payload: BrandCreateRequest) => {
  const res = await axios.post<BrandResponse>(`/v1/brands`, payload)
  return res.data
}

export interface BrandCreateRequest {
  brandNameKr: string
  brandNameEn: string
  categoryCode: string
  managerEmail: string
}

export interface ShoutoutResponseItem {
  shoutoutId: number
  brandId: number
  channelId: number
  channelName: string
  channelThumbnail: string
  videoId: number
  videoKey: string
  categoryCode?: string
  viewCount?: number
  likeCount?: number
  commentCount?: number
  highlight?: number
  brandName?: string
  brandLogoUrl?: string
  title: string
  thumbnailUrl?: string
  publishedAt?: Date
  createdAt?: Date
}

export const apiGetShoutout = async (params: GetShoutoutListRequest) => {
  const res = await axios.get<ShoutoutResponse>(`/v1/shoutout`, { params })
  return res.data
}

interface GetShoutoutListRequest {
  categoryCode?: string
  page: number
}

export const apiGetViewedChannels = async (params?: { page: number; pageSize: number }) => {
  const res = await axios.get<GetViewedChannelResponse>(`/v1/channels/viewed`, { params })
  return res.data
}

interface GetViewedChannelResponse {
  channels: ChannelSearchResponseItem[]
  totalCount: number
  totalPage: number
}

export const apiGetVideosInCollections = async (params?: {
  collectionId?: number
  page?: number
  pageSize?: number
}) => {
  const res = await axios.get<GetVideosInCollectionResponse>(`/v1/videos/in-collections`, {
    params,
  })
  return res.data
}

interface GetVideosInCollectionResponse {
  videos: VideoSummaryResponseItem[]
  totalCount: number
  totalPage: number
}
