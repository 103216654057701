import VideoPlaylist from '../../../component/VideoPlaylist'
import { apiSearchVideo, SearchVideoParams } from '../../../api/apis'
import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { EuiButtonGroup } from '@elastic/eui'
import { useInfiniteQuery } from '@tanstack/react-query'
import Group from 'component/Group'
import Button from 'component/button/Button'

// utils videoSorts / channelSorts
// EuiButtonGroup type 때문에 id를 사용해야 함.
const sortingOptions = [
  {
    label: '최신순',
    id: 'latest',
  },
  {
    label: '인기순',
    id: `like`,
  },
]

const filteringOptions = [
  {
    label: '전체 영상',
    id: 'false',
  },
  {
    label: 'PPL 영상만',
    id: 'true',
  },
]

const ChannelVideo = ({ channelId, permitted }: { channelId: number; permitted: boolean }) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const categoryCode = searchParams.get('categoryCode') || ''
  const sort = searchParams.get('sort') || 'latest'
  const adOnly = searchParams.get('adOnly') === 'true'

  const searchVideoQueryParams: SearchVideoParams = useMemo(() => {
    return {
      channelId: Number(channelId),
      categoryCode,
      adOnly,
      sort,
    }
  }, [channelId, categoryCode, sort, adOnly])

  const {
    data: videos,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: ['/v1/videos', searchVideoQueryParams],
    queryFn: ({ pageParam }) => apiSearchVideo({ ...searchVideoQueryParams, page: pageParam }),
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages) => {
      return lastPage.totalPage === allPages.length ? undefined : allPages.length
    },
    enabled: permitted,
  })

  const fetchMore = () => {
    if (!isFetchingNextPage) {
      fetchNextPage()
    }
  }

  const updateQuery = (key: keyof SearchVideoParams, value: string) => {
    searchParams.set(key, value)
    setSearchParams(searchParams, { replace: true })
  }

  return (
    <>
      <Group style={{ marginBottom: 'var(--space-4)' }}>
        <EuiButtonGroup
          legend=""
          options={filteringOptions}
          idSelected={String(adOnly)}
          onChange={value => updateQuery('adOnly', value)}
          style={{ maxWidth: 180 }}
          // buttonSize="compressed"
          // isFullWidth
        />
        <EuiButtonGroup
          legend=""
          options={sortingOptions}
          idSelected={sort}
          onChange={value => updateQuery('sort', value)}
          style={{ maxWidth: 180 }}
        />
      </Group>
      <VideoPlaylist
        showChannelAvatar={false}
        videos={videos?.pages?.flatMap(item => item.videos)}
      />
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
        {hasNextPage && (
          // TODO isLoading
          // <Button variant="secondary" onClick={fetchMore} isLoading={isFetchingNextPage}>
          <Button variant="secondary" onClick={fetchMore}>
            더 보기
          </Button>
        )}
      </div>
    </>
  )
}

export default ChannelVideo
