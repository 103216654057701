import Box from '@mui/joy/Box'
import * as React from 'react'
import { KeyboardEventHandler, useState } from 'react'
import { ChannelItem } from '../../../component/ChannelItem'
import Stat from '../../../component/Stat'
import { parseCount } from '../../../util/utils'
import { Link as RouterLink, useSearchParams } from 'react-router-dom'
import { channelFilteringOptions, channelSorts, getFollowerMinMax } from '../../../util/consts'
import { SearchChannelParams, useCategoryChannelData } from '../../../api/apis'
import FormControl from '@mui/joy/FormControl'
import FormLabel from '@mui/joy/FormLabel'
import Select from '@mui/joy/Select'
import Option from '@mui/joy/Option'
import Input from '@mui/joy/Input'
import SearchIcon from '@mui/icons-material/Search'
import { EuiPagination } from '@elastic/eui'
import SpinnerWithBox from '../../../component/SpinnerWithBox'
import { Card, Grid, Stack } from '@mui/joy'
import DashboardPageBody from '../../../layout/DashboardPageBody'
import { MetaTags } from '../../../util/MetaTags'

const ChannelExplore = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const search = searchParams.get('search') || ''
  const channelCategory = searchParams.get('category') || ''
  const follower = searchParams.get('follower') || 'all'
  const adOnly = searchParams.get('adOnly') || (!!search ? 'false' : 'true')
  const sort = searchParams.get('sort') || 'view'
  const page = Number(searchParams.get('page') || 1)

  const [searchInput, setSearchInput] = useState(search)

  const [minFollower, maxFollower] = getFollowerMinMax(follower)

  const params = {
    search: search,
    channelCategory,
    minFollower,
    maxFollower,
    adOnly: adOnly === 'true',
    sort,
    page: page - 1,
  } as SearchChannelParams

  const { data: channelData, isLoading } = useCategoryChannelData(params)

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = e => {
    if (e.key === 'Enter') {
      e.stopPropagation()
      e.preventDefault()
      updateSearchParams('search', searchInput)
    }
  }

  const updateSearchParams = (key: string, value: string | undefined | null) => {
    if (value === undefined || value === null) {
      searchParams.delete(key)
    } else {
      searchParams.set(key, value)
    }
    setSearchParams(searchParams)
  }

  const renderFilters = () => (
    <React.Fragment>
      <FormControl sx={{}} size="sm">
        <FormLabel>채널명</FormLabel>
        <Input
          size="sm"
          placeholder="채널명 검색"
          startDecorator={<SearchIcon />}
          value={searchInput}
          onChange={e => setSearchInput(e.target.value)}
          onKeyDown={handleKeyDown}
        />
      </FormControl>
      <FormControl size="sm">
        <FormLabel>구독자수</FormLabel>
        <Select
          size="sm"
          placeholder={channelFilteringOptions[0].text}
          slotProps={{ button: { sx: { whiteSpace: 'nowrap' } } }}
          onChange={(_, value: string | null) => updateSearchParams('follower', value)}
        >
          {channelFilteringOptions.map(option => (
            <Option key={option.value} value={option.value}>
              {option.text}
            </Option>
          ))}
        </Select>
      </FormControl>
      <FormControl size="sm">
        <FormLabel>유료광고</FormLabel>
        <Select
          size="sm"
          placeholder="PPL 존재 채널만"
          name="adOnly"
          onChange={(_, value: string | null) => updateSearchParams('adOnly', value)}
        >
          <Option value="true">PPL 존재 채널만</Option>
          <Option value="false">전체 채널</Option>
        </Select>
      </FormControl>
      <FormControl size="sm">
        <FormLabel>정렬</FormLabel>
        <Select
          size="sm"
          placeholder="조회수"
          name="sort"
          value={sort}
          onChange={(_, value: string | null) => updateSearchParams('sort', value)}
        >
          {channelSorts.map(option => (
            <Option key={option.value} value={option.value}>
              {option.text}
            </Option>
          ))}
        </Select>
      </FormControl>
    </React.Fragment>
  )

  const renderChannels = () => {
    return channelData?.channels.map((channel, index) => {
      return (
        <Grid key={index} sm={12} md={4} lg={3}>
          <Card>
            <RouterLink to={`/channels/${channel.channelId}`} style={{ flex: 1 }}>
              <ChannelItem channel={channel} />
            </RouterLink>
            <Box>
              <div>
                <style jsx>
                  {`
                    .meter2 {
                      display: flex;
                      flex-direction: column;
                      gap: var(--space-1);
                    }
                    .meter2-track {
                      height: 0.25rem;
                      margin-top: var(--space-1);
                      background-color: var(--color-background-weak);
                      border-radius: var(--radius);
                      overflow: hidden;
                      flex: auto;
                    }
                    .meter2-thumb {
                      display: block;
                      width: 90%;
                      height: inherit;
                      background: skyblue;
                    }
                    .meter2-text {
                      font-size: 12px;
                    }
                  `}
                </style>
                <div className="meter2">
                  <div className="meter2-track">
                    <b
                      className="meter2-thumb"
                      style={{
                        width: `${(
                          (channel.recentAdContentCount / channel.recentContentCount) *
                          100
                        ).toFixed(2)}%`,
                      }}
                    />
                  </div>
                  <div>
                    <span className="meter2-text">전체 {channel.recentContentCount}개 중</span>
                    <span className="meter2-text"> PPL {channel.recentAdContentCount}개</span>
                  </div>
                </div>
              </div>
              <Stack direction="row" spacing={2} sx={{ mt: 1 }}>
                <Stat label="조회 평균" value={parseCount(channel.avgViewCount)} />
                <Stat label="좋아요 평균" value={parseCount(channel.avgLikeCount)} />
                <Stat label="댓글 평균" value={parseCount(channel.avgCommentCount)} />
              </Stack>
            </Box>
          </Card>
        </Grid>
      )
    })
  }

  return (
    <DashboardPageBody title="채널 찾기" breadcrumbs={[{ label: 'Channels' }]}>
      <MetaTags title={`채널 찾기 · 콜라블`} description={description()} keywords={keywords()} />
      <Box
        sx={{
          borderRadius: 'sm',
          py: 2,
          display: { xs: 'none', sm: 'flex' },
          flexWrap: 'wrap',
          gap: 1.5,
          '& > *': {
            minWidth: { xs: '120px', md: '160px' },
          },
        }}
      >
        {renderFilters()}
      </Box>
      <Grid container spacing={2}>
        {isLoading && <SpinnerWithBox height={'200px'} />}
        {renderChannels()}
      </Grid>
      <Box display={'flex'} justifyContent={'center'} padding={3}>
        <EuiPagination
          pageCount={channelData?.totalCount || 0}
          activePage={page - 1}
          onPageClick={activePage =>
            setSearchParams({ page: String(activePage + 1), follower: follower })
          }
        />
      </Box>
    </DashboardPageBody>
  )
}

const description = () => {
  return `유튜브 유료광고(PPL)를 위한 최적의 채널을 찾아보세요. 조회수, 구독자 수로 정렬된 다양한 채널 목록에서 검색 및 필터링 기능으로 원하는 채널을 쉽게 발견할 수 있습니다.`
}

const keywords = () => {
  return `유튜브 채널, PPL, 유료광고, 인플루언서 마케팅, 채널 검색, 유튜브 마케팅, 구독자 필터링, 조회수 정렬, 채널 분석, 콘텐츠 제작자`
}

export default ChannelExplore
