import Title from '../../../component/texts/Text'
import Tile from '../../../component/Tile'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { apiUpdateChannelOffers, ChannelOffers, useMyChannelSettings } from '../../../api/apis'
import queryKeys from '../../../api/queryKeys'
import Button from 'component/button/Button'
import { Box, FormControl, FormLabel, Switch } from '@mui/joy'
import TextField from 'component/TextField'

const OfferManage = ({ channelId }: { channelId: number }) => {
  const queryClient = useQueryClient()
  const { data: channelSettings } = useMyChannelSettings(channelId)

  const [pplOffer, setPplOffer] = useState(false)
  const [pplPrice, setPplPrice] = useState('')
  const [ppplOffer, setPpplOffer] = useState(false)
  const [ppplPrice, setPpplPrice] = useState('')
  const [brandedOffer, setBrandedOffer] = useState(false)
  const [brandedPrice, setBrandedPrice] = useState('')

  useEffect(() => {
    if (channelSettings) {
      setPplOffer(channelSettings.offers?.pplOffer || false)
      setPplPrice(channelSettings.offers?.pplPrice || '')
      setPpplOffer(channelSettings.offers?.ppplOffer || false)
      setPpplPrice(channelSettings.offers?.ppplPrice || '')
      setBrandedOffer(channelSettings.offers?.brandedOffer || false)
      setBrandedPrice(channelSettings.offers?.brandedPrice || '')
    }
  }, [channelSettings])

  const { mutate: updateOffer } = useMutation({
    mutationFn: (payload: ChannelOffers) => apiUpdateChannelOffers(channelId, payload),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.myChannelSettings(channelId) })
      alert('저장되었습니다')
    },
  })

  const pplOffers = [
    {
      title: 'PPL',
      descriptions: ['채널 내 콘텐츠 업로드', '제품 사용을 자연스레 노출'],
      accept: [pplOffer, setPplOffer],
      price: [pplPrice, setPplPrice],
    },
    {
      title: '기획 PPL',
      descriptions: ['채널 내 콘텐츠 업로드', '제품 1-2분 언급'],
      accept: [ppplOffer, setPpplOffer],
      price: [ppplPrice, setPpplPrice],
    },
    {
      title: '브랜디드 콘텐츠',
      descriptions: [
        '채널 내 콘텐츠 업로드',
        '제품과 관련된 콘텐츠 진행',
        '제품 관련 내용을 중심으로 콘텐츠 제작',
      ],
      accept: [brandedOffer, setBrandedOffer],
      price: [brandedPrice, setBrandedPrice],
    },
  ] as Offer[]

  const onClickSave = () => {
    updateOffer({
      pplOffer: pplOffer,
      pplPrice: pplPrice,
      ppplOffer: ppplOffer,
      ppplPrice: ppplPrice,
      brandedOffer: brandedOffer,
      brandedPrice: brandedPrice,
    })
  }

  return (
    <div style={{ maxWidth: '720px', margin: '0 auto' }}>
      <Box>
        {pplOffers.map(offer => (
          <OfferManageItem offer={offer} />
        ))}
      </Box>
      <div style={{ height: 'var(--space-5)' }} />
      <Button variant="primary" onClick={onClickSave}>
        저장
      </Button>
    </div>
  )
}

interface Offer {
  title: string
  descriptions: string[]
  accept: [boolean, Dispatch<SetStateAction<boolean>>]
  price: [string, Dispatch<SetStateAction<string>>]
}

const OfferManageItem = ({ offer }: { offer: Offer }) => {
  const [accept, setAccept] = offer.accept
  const [price, setPrice] = offer.price
  return (
    <Tile style={{ padding: '1rem' }}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Title level={'3'}>{offer.title}</Title>
        <div style={{ height: 'var(--space-3)' }} />
        <ul>
          {offer.descriptions.map((description, index) => (
            <li style={{ lineHeight: '1.5rem' }}>{description}</li>
          ))}
        </ul>
        <div style={{ height: 'var(--space-5)' }} />
        <FormControl>
          <FormLabel>제안 받기</FormLabel>
          <Switch checked={accept} onChange={e => setAccept(e.target.checked)} />
        </FormControl>
        <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
          <TextField
            // value={groupName}
            value={price}
            onChange={(e: any) => setPrice(e.target.value)}
            label="기준 금액"
            helperText={'기준 금액은 인증된 광고주에게만 보이며, 선택 입력 사항입니다'}
            sx={{ flex: 1, textAlign: 'right' }}
          />
          <div style={{ width: '50px' }}>만원</div>
        </div>
      </div>
    </Tile>
  )
}

export default OfferManage
