interface Props {
  // number ?
  marginBlock: number
}

function Divider({ marginBlock }: Props) {
  return (
    <div
      style={{
        width: '100%',
        height: '1px',
        backgroundColor: 'var(--color-background-weak)',
        marginBlock: marginBlock,
      }}
    />
  )
}

export default Divider
