// interface ButtonProps extends React.HTMLAttributes<HTMLButtonElement> {

type ButtonVariantTypes = 'primary' | 'secondary' | 'tertiary' | 'text' | 'textAccent'
interface ButtonProps {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
  className?: string
  variant?: ButtonVariantTypes
  type?: 'submit' | 'button'
  name?: string
  children: React.ReactNode
  isActive?: boolean
  isDisabled?: boolean
  href?: string
  size?: 'm' | 's'
  fullWidth?: boolean
}

const getStyles = (variant: ButtonVariantTypes) => {
  switch (variant) {
    case 'primary':
      return {
        background: 'var(--color-primary)',
        color: 'var(--color-text-white)',
      }
    case 'secondary':
      return {
        border: '1px solid var(--color-primary)',
        fontWeight: 'var(--font-weight-bold)',
        color: 'var(--color-text)',
      }
    case 'tertiary':
      return {
        border: 'none',
        background: 'var(--color-background-weak)',
        fontWeight: 'var(--font-weight-bold)',
      }
    case 'text':
      return {
        paddingLeft: '0',
        paddingRight: '0',
        border: 'none',
        backgroundColor: 'none',
      }
    case 'textAccent':
      return {
        paddingLeft: '0',
        paddingRight: '0',
        border: 'none',
        backgroundColor: 'none',
        color: 'var(--color-accent)',
      }
    default:
      return {}
  }
}

function Button({
  name,
  children,
  variant,
  type,
  isDisabled,
  fullWidth,
  size,
  href,
  onClick,
  className,
}: ButtonProps) {
  const containerStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // minWidth: '4rem',
    width: fullWidth ? '100%' : 'auto',
    // TODO variables
    height: size === 's' ? 'var(--size-medium)' : '48px',
    padding: '0 var(--space-4)',
    gap: 'var(--space-2)',
    borderRadius: 'var(--radius)',
    fontWeight: 'var(--font-weight-bold)',
    border: 'var(--border)',
    fontSize: '14px',
  }

  if (href) {
    return (
      <a
        href={href}
        className={className}
        style={{
          ...containerStyles,
          ...getStyles(variant || 'primary'),
        }}
        type={type || 'button'}
      >
        {children}
      </a>
    )
  }

  return (
    <button
      className={className}
      style={{
        ...containerStyles,
        ...getStyles(variant || 'primary'),
      }}
      type={type || 'button'}
      name={name}
      disabled={isDisabled}
      onClick={onClick}
    >
      {children}
    </button>
  )
}

export default Button
