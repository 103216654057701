import { ChangeEvent, useState } from 'react'
import { categoryAssets } from '../util/consts'
import Button from '../component/button/Button'
import CategorySelectModal from './CategorySelectModal'
import { useMutation } from '@tanstack/react-query'
import { apiCreateBrand } from '../api/apis'
import { CategoryButton } from 'component/CategoryButton'
import { Box, DialogContent, DialogTitle } from '@mui/joy'
import Modal from 'component/Modal'
import TextField from 'component/TextField'
import { InputLabel } from '@mui/material'

interface Props {
  onCreate: (brandId: number, brandName: string) => void
  closeModal: VoidFunction
}

const BrandCreateModal = ({ closeModal, onCreate }: Props) => {
  const [showCategorySelectModal, setShowCategorySelectModal] = useState(false)

  const [form, setForm] = useState({
    brandNameKr: '',
    brandNameEn: '',
    categoryCode: '',
    managerEmail: '',
  })

  const { mutate: createBrand } = useMutation({
    mutationFn: apiCreateBrand,
    onSuccess: res => {
      closeModal()
      onCreate(res.brandId, res.brandName)
    },
  })

  const updateForm = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const key = e.target.name
    const v = e.target.value
    const value = v ? v : undefined

    setForm({ ...form, [key]: value })
  }

  const onSave = () => {
    const payload = {
      brandNameKr: form.brandNameKr,
      brandNameEn: form.brandNameEn,
      categoryCode: form.categoryCode,
      managerEmail: form.managerEmail,
    }

    createBrand(payload)
  }

  const category = categoryAssets.find(category => category.categoryCode === form.categoryCode)

  return (
    <>
      <div style={{ height: 'var(--space-6)' }} />
      <Modal
        title="브랜드 등록"
        onClose={closeModal}
        primaryAction={{ label: '등록', onClick: onSave }}
        secondaryAction={{ label: '취소', onClick: closeModal }}
      >
        <TextField
          label="브랜드명 (한글)"
          name="brandNameKr"
          value={form.brandNameKr}
          onChange={updateForm}
          placeholder=""
        />
        <div style={{ height: 'var(--space-6)' }} />
        <TextField
          label="브랜드명 (영어)"
          name="brandNameEn"
          value={form.brandNameEn}
          onChange={updateForm}
          placeholder=""
        />
        <div style={{ height: 'var(--space-6)' }} />
        <Box>
          <InputLabel>브랜드 카테고리"</InputLabel>
          <>
            {category && category.categoryCode !== '' && (
              <CategoryButton
                category={category}
                selected={true}
                onClick={() => {
                  setShowCategorySelectModal(true)
                }}
              />
            )}
            {!form.categoryCode && (
              <Button
                variant={'secondary'}
                size={'s'}
                onClick={() => {
                  setShowCategorySelectModal(true)
                }}
              >
                선택
              </Button>
            )}
          </>
        </Box>

        <div style={{ height: 'var(--space-6)' }} />
        <TextField
          label="마케팅 담당자 이메일"
          name="managerEmail"
          value={form.managerEmail}
          onChange={updateForm}
          placeholder=""
          helperText={'마케팅 담당자의 이메일을 기재해주시면 내용을 전달해 드립니다'}
        />
      </Modal>
      {showCategorySelectModal && (
        <CategorySelectModal
          onSelect={categoryCode => {
            setForm({ ...form, categoryCode: categoryCode })
            setShowCategorySelectModal(false)
          }}
          closeModal={() => setShowCategorySelectModal(false)}
        />
      )}
    </>
  )
}

export default BrandCreateModal
