import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import { apiResendSignupEmail, apiVerifySignupEmail } from '../api/apis'
import Logo from '../component/Logo'
import Title from '../component/texts/Text'
import Button from '../component/button/Button'
import Tile from '../component/Tile'
import Modal2 from 'component/Modal2'
import { useSignModalContext } from '../context/SignModalContext'
import TextField from 'component/TextField'
import { Box } from '@mui/joy'

const SignUpCompleteModal = () => {
  const { signUpVerificationOpened, actions } = useSignModalContext()

  const [secret, setSecret] = useState('')

  const { mutate: verifySignupEmail } = useMutation({
    mutationFn: apiVerifySignupEmail,
    onSuccess: () => {
      window.alert('회원가입이 완료되었습니다!')
      actions.closeSignUpVerification()
    },
  })

  const { mutate: reSendSignupEmail } = useMutation({
    mutationFn: apiResendSignupEmail,
    onSuccess: () => {
      window.alert('인증 메일을 재전송했습니다.')
    },
  })

  if (!signUpVerificationOpened) {
    return null
  }

  const onConfirm = () => {
    verifySignupEmail({ secret })
  }

  const onClickRetry = () => {
    reSendSignupEmail()
  }

  return (
    <Modal2
      maxWidth="400px"
      onClose={() => {
        actions.closeSignUpVerification()
      }}
      secondaryAction={{
        label: '닫기',
      }}
    >
      <header
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 'var(--space-5)',
        }}
      >
        <Logo />
      </header>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginTop: '1rem',
        }}
      >
        <Title level="3">이메일 인증</Title>
        <Button variant="text" onClick={onClickRetry}>
          인증 메일 재전송
        </Button>
      </div>
      <Tile size="large">
        <p>가입하신 메일로 인증 메일을 보냈습니다.</p>
        <Box sx={{ margin: '1rem 0' }}>
          <TextField
            label="인증코드"
            name="secret"
            value={secret}
            onChange={(e: any) => setSecret(e.target.value)}
            placeholder="6자리"
          />
        </Box>
        <Button variant="primary" fullWidth onClick={onConfirm}>
          인증하기
        </Button>
      </Tile>
    </Modal2>
  )
}

export default SignUpCompleteModal
