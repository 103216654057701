import { extendTheme } from '@mui/joy/styles'

const theme = extendTheme({
  fontFamily: {
    display: "'Pretendard', -apple-system, BlinkMacSystemFont, sans-serif",
    body: "'Pretendard', -apple-system, BlinkMacSystemFont, sans-serif",
  },
})

export default theme
