import {
  EuiFieldText,
  EuiForm,
  EuiFormRow,
  EuiModal,
  EuiModalBody,
  EuiModalHeader,
  EuiModalHeaderTitle,
} from '@elastic/eui'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { VideoSummaryResponseItem } from '../../../api/types'
import { parseSeconds } from '../../../util/utils'
import Button from 'component/button/Button'
import BrandSelectModal from '../../../modal/BrandSelectModal'
import Avatar from '../../../component/Avatar'
import Group from '../../../component/Group'
import CustomizedSelect from '../../../component/CustomizedSelect'
import { useMutation } from '@tanstack/react-query'
import { apiUpdateVideoSettings, VideoUpdateRequest } from '../../../api/apis'
import BrandCreateModal from '../../../modal/BrandCreateModal'
import Modal from 'component/Modal'

interface Props {
  video: VideoSummaryResponseItem
  closeModal: VoidFunction
  onSubmitVideoSetting: VoidFunction
}

interface VideoUpdateForm {
  highlight: string
  brandId?: number
  brandName: string
  productName: string
  adType: string
}

const VideoManageModal = ({ video, closeModal, onSubmitVideoSetting }: Props) => {
  const [showBrandSelectModal, setShowBrandSelectModal] = useState(false)
  const [showBrandCreateModal, setShowBrandCreateModal] = useState(false)
  const [brandLogoUrl, setBrandLogoUrl] = useState<string | undefined>(undefined)
  const [form, setForm] = useState<VideoUpdateForm>({
    highlight: '',
    brandId: undefined,
    brandName: '',
    productName: '',
    adType: video.adType,
  })

  useEffect(() => {
    setForm({
      highlight: video.highlight ? parseSeconds(video.highlight) : '',
      brandId: video.brandId,
      brandName: video.brandName || '',
      productName: video.productName || '',
      adType: video.adType,
    })
    setBrandLogoUrl(video.brandLogoUrl)
  }, [video])

  const { mutate: updateVideoSettings } = useMutation({
    mutationFn: (payload: VideoUpdateRequest) => apiUpdateVideoSettings(video.videoId, payload),
    onSuccess: () => {
      onSubmitVideoSetting()
      closeModal()
      alert('저장되었습니다')
    },
  })

  const updateForm = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const key = e.target.name
    const v = e.target.value
    const value = v ? v : undefined

    setForm({ ...form, [key]: value })
  }

  const onClickSave = () => {
    const payload = {
      videoId: video.videoId,
      highlight: form.highlight,
      adType: form.adType,
      brandId: form.brandId,
      brandName: form.brandName,
      productName: form.productName,
    } as VideoUpdateRequest

    updateVideoSettings(payload)
  }

  return (
    <>
      <Modal
        title="콘텐츠 정보 편집"
        onClose={closeModal}
        primaryAction={{ label: '저장', onClick: onClickSave }}
        secondaryAction={{ label: '닫기', onClick: closeModal }}
      >
        <div>
          <div style={{ display: 'flex', gap: 'var(--space-4)', alignItems: 'center' }}>
            <img src={video.thumbnailUrl} style={{ width: '100px' }} alt="" />
            <span style={{ fontWeight: 'bold' }}>{video.title}</span>
          </div>
          <EuiForm component="form" style={{ margin: '2rem' }}>
            <EuiFormRow label="시작시간" display="columnCompressed">
              <EuiFieldText
                name="highlight"
                value={form.highlight}
                onChange={updateForm}
                placeholder="00:00"
              />
            </EuiFormRow>
            <EuiFormRow label="유료광고 타입" display="columnCompressed">
              <CustomizedSelect
                options={[
                  { value: '', text: '유료광고아님' },
                  { value: 'AD', text: '자사광고' },
                  { value: 'PPL', text: 'PPL' },
                  { value: 'BRANDED', text: '브랜디드PPL' },
                ]}
                name="adType"
                value={form.adType}
                onChange={updateForm}
              />
            </EuiFormRow>
            <EuiFormRow label="PPL브랜드" display="columnCompressed">
              <Group spacing={'4'}>
                {form.brandName && (
                  <Group
                    style={{
                      alignItems: 'center',
                      gap: 'var(--space-2)',
                    }}
                  >
                    {brandLogoUrl && (
                      <Avatar variant="brand" size="lg" src={brandLogoUrl} type={'space'} />
                    )}
                    <span>{form.brandName}</span>
                  </Group>
                )}
                <Button
                  variant="textAccent"
                  onClick={() => {
                    setShowBrandSelectModal(true)
                  }}
                >
                  브랜드 선택
                </Button>
              </Group>
            </EuiFormRow>
            <EuiFormRow label="PPL 대상" display="columnCompressed">
              <EuiFieldText
                name="productName"
                value={form.productName}
                onChange={updateForm}
                placeholder=""
              />
            </EuiFormRow>
          </EuiForm>
        </div>
      </Modal>
      {showBrandSelectModal && (
        <BrandSelectModal
          onSelect={(brandId, brandName, brandLogoUrl) => {
            setForm({ ...form, brandId, brandName })
            setBrandLogoUrl(brandLogoUrl)
          }}
          closeModal={() => {
            setShowBrandSelectModal(false)
          }}
          openBrandCreateModal={() => {
            setShowBrandCreateModal(true)
          }}
        />
      )}
      {showBrandCreateModal && (
        <BrandCreateModal
          onCreate={(brandId, brandName) => {
            setForm({ ...form, brandId, brandName })
          }}
          closeModal={() => setShowBrandCreateModal(false)}
        />
      )}
    </>
  )
}

export default VideoManageModal
