import { SearchChannelResponseItem } from '../../../api/types'
import Tile from '../../../component/Tile'
import { ChannelItem } from '../../../component/ChannelItem'
import Title from '../../../component/texts/Text'
import { apiSearchVideo, SearchVideoParams } from '../../../api/apis'
import VideoPlaylist from '../../../component/VideoPlaylist'
import { useQuery } from '@tanstack/react-query'
import queryKeys from '../../../api/queryKeys'
import dayjs from 'dayjs'

interface Props {
  channels: SearchChannelResponseItem[]
  adOnly: boolean
}

const ChannelCompareChannelInfo = ({ channels, adOnly }: Props) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
      {channels.map(channel => {
        return <ChannelCompareChannelInfoItem channel={channel} adOnly={adOnly} />
      })}
    </div>
  )
}

interface ItemProps {
  channel: SearchChannelResponseItem
  adOnly: boolean
}

const ChannelCompareChannelInfoItem = ({ channel, adOnly }: ItemProps) => {
  const param = {
    channelId: channel.channelId,
    forCompare: true,
    adOnly: adOnly,
    startPublishedAt: dayjs().subtract(3, 'month').format('YYYY-MM-DD'),
  } as SearchVideoParams

  const { data: videos } = useQuery({
    queryKey: queryKeys.videoSearch(param),
    queryFn: () => apiSearchVideo(param),
    placeholderData: (previousData, _) => previousData,
  })

  return (
    <Tile size="large">
      <ChannelItem channel={channel} />
      <div style={{ height: 'var(--space-3)' }} />
      <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
        <Title level={'3'}>최근 인기 콘텐츠</Title>
      </div>
      <div style={{ height: 'var(--space-5)' }} />
      <VideoPlaylist showChannelAvatar={false} videos={videos?.videos || []} />
    </Tile>
  )
}

export default ChannelCompareChannelInfo
