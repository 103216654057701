import Tile from '../../../component/Tile'
import Title from '../../../component/texts/Text'
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  LabelList,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { useFetchChannel } from '../../../api/apis'
import { OriginalAudience, SearchChannelResponseItem } from '../../../api/types'
import _ from 'lodash'
import { parseCount, parseRatio } from '../../../util/utils'
import { Grid } from '@mui/joy'

const ChannelAudience = ({ channelId }: { channelId: number }) => {
  const { data: channelData } = useFetchChannel(Number(channelId))
  const audience = channelData?.audience
  const mainAudience = getMainAudience(audience)

  const genderData = calcGenderData(audience)
  const ageData = calcAgeData(audience)

  const formatXAxis = (value: number) => parseRatio(value / 100)

  if (!audience) {
    return (
      <Tile size="large" style={{ textAlign: 'center', padding: '3rem' }}>
        입력된 데이터가 없습니다.
      </Tile>
    )
  }

  return (
    <Grid container spacing={2}>
      <Grid sm={6} md={4}>
        <Tile size="large">
          <Title level="3">주 시청자층</Title>
          <div style={{ height: 'var(--space-3)' }}></div>
          <div>
            <div style={{ display: 'flex', gap: 'var(--space-3)' }}>
              {_.take(mainAudience, 3).map((audience, index) => (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: 'var(--radius)',
                    padding: 'var(--space-3)',
                    border: 'var(--border)',
                    fontWeight: 'var(--font-weight-bold)',
                  }}
                >
                  {keyToLabel(audience.key)}
                </div>
              ))}
            </div>
            <div style={{ height: 'var(--space-4)' }}></div>
            <div style={{ height: 'var(--space-4)' }}></div>
            <Title level="3">예상 조회수</Title>
            <p
              style={{
                background: 'var(--color-background-weak)',
                padding: 'var(--space-3)',
                borderRadius: 'var(--radius)',
                margin: 'var(--space-3) 0',
                lineHeight: '1.6',
              }}
            >
              <span>
                해당 데이터는 채널의 최근 평균 조회수를 기반하여 예상한 수치이며, 실제 수치와 다를
                수 있습니다.
              </span>
            </p>
            <ExpectedView channel={channelData} />
          </div>
        </Tile>
      </Grid>
      <Grid sm={6} md={4}>
        <Tile size="large" style={{ height: '300px' }}>
          <Title level="3">성별 비율</Title>
          <ResponsiveContainer width="100%" height="100%">
            <ComposedChart
              layout="vertical"
              width={500}
              height={200}
              data={genderData}
              margin={{
                top: 20,
                right: 30,
                bottom: 20,
                left: 0,
              }}
            >
              <CartesianGrid stroke="#f5f5f5" />
              <XAxis type="number" tickFormatter={formatXAxis} />
              <YAxis
                dataKey="name"
                type="category"
                scale="band"
                tickLine={false}
                tick={{ fill: '#787878', dy: 48 }}
                width={20}
              />
              <Tooltip formatter={formatXAxis} />
              <Bar dataKey="amt" barSize={12} fill="rgba(53, 162, 235, 0.5)">
                <LabelList dataKey="amt" position="right" formatter={formatXAxis} />
              </Bar>
            </ComposedChart>
          </ResponsiveContainer>
        </Tile>
      </Grid>
      <Grid sm={6} md={4}>
        <Tile size="large" style={{ height: '300px' }}>
          <Title level="3">연령 비율</Title>

          <ResponsiveContainer width="100%" height="100%">
            <ComposedChart
              layout="vertical"
              width={500}
              height={200}
              data={ageData}
              margin={{
                top: 20,
                right: 30,
                bottom: 20,
                left: 0,
              }}
            >
              <CartesianGrid stroke="#f5f5f5" />
              <XAxis type="number" tickFormatter={formatXAxis} />
              <YAxis
                dataKey="name"
                type="category"
                scale="band"
                tickLine={false}
                tick={{ fill: '#787878', dy: 14 }}
                width={50}
              />
              <Tooltip formatter={formatXAxis} />
              <Bar dataKey="amt" barSize={12} fill="rgba(53, 162, 235, 0.5)">
                <LabelList dataKey="amt" position="right" formatter={formatXAxis} />
              </Bar>
            </ComposedChart>
          </ResponsiveContainer>
        </Tile>
      </Grid>
    </Grid>
  )
}

const ExpectedView = ({ channel }: { channel?: SearchChannelResponseItem }) => {
  if (!channel?.audience) return null

  const distributedAudience = distributeAudience(channel?.audience)!!

  const calc = (rate: number) => {
    return parseCount(rate * channel.avgViewCount)
  }

  return (
    <>
      <Meter2 />
      <table className={'audience-table'}>
        <thead>
          <tr>
            <th>연령</th>
            <th style={{ textAlign: 'right' }}>남성</th>
            <th style={{ textAlign: 'right' }}>여성</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>17세 이하</th>
            <td>{calc(distributedAudience.male13)}</td>
            <td>{calc(distributedAudience.female13)}</td>
          </tr>
          <tr>
            <th>18-24세</th>
            <td>{calc(distributedAudience.male18)}</td>
            <td>{calc(distributedAudience.female18)}</td>
          </tr>
          <tr>
            <th>25-34세</th>
            <td>{calc(distributedAudience.male25)}</td>
            <td>{calc(distributedAudience.female25)}</td>
          </tr>
          <tr>
            <th>35-44세</th>
            <td>{calc(distributedAudience.male35)}</td>
            <td>{calc(distributedAudience.female35)}</td>
          </tr>
          <tr>
            <th>45-54세</th>
            <td>{calc(distributedAudience.male45)}</td>
            <td>{calc(distributedAudience.female45)}</td>
          </tr>
          <tr>
            <th>55-64세</th>
            <td>{calc(distributedAudience.male55)}</td>
            <td>{calc(distributedAudience.female55)}</td>
          </tr>
          <tr>
            <th>64세 이상</th>
            <td>{calc(distributedAudience.male65)}</td>
            <td>{calc(distributedAudience.female65)}</td>
          </tr>
        </tbody>
      </table>

      <style jsx>{`
        .audience-table {
          width: 100%;
          margin-top: var(--space-2);
          thead th {
            color: var(--color-text-light);
          }

          tbody td {
            text-align: right;
          }
          th {
            text-align: left;
          }
          td,
          th {
            padding: var(--space-2) 0;
          }
        }
      `}</style>
    </>
  )
}

const distributeAudience = (audience?: OriginalAudience) => {
  if (!audience) return undefined

  const { org13, org18, org25, org35, org45, org55, org65, orgMale, orgFemale } = audience
  const male13 = org13 * orgMale
  const male18 = org18 * orgMale
  const male25 = org25 * orgMale
  const male35 = org35 * orgMale
  const male45 = org45 * orgMale
  const male55 = org55 * orgMale
  const male65 = org65 * orgMale
  const female13 = org13 * orgFemale
  const female18 = org18 * orgFemale
  const female25 = org25 * orgFemale
  const female35 = org35 * orgFemale
  const female45 = org45 * orgFemale
  const female55 = org55 * orgFemale
  const female65 = org65 * orgFemale
  return {
    male13,
    male18,
    male25,
    male35,
    male45,
    male55,
    male65,
    female13,
    female18,
    female25,
    female35,
    female45,
    female55,
    female65,
  }
}

const getMainAudience = (audience?: OriginalAudience) => {
  if (!audience) return []

  const distributedAudience = distributeAudience(audience)
  const array = _.map(distributedAudience, (value, key) => ({ key, value }))
  return _.sortBy(array, 'value').reverse()
}

const calcGenderData = (audience?: OriginalAudience) => {
  if (!audience) return undefined

  return [
    {
      name: '남',
      amt: audience.orgMale * 100,
    },
    {
      name: '여',
      amt: audience.orgFemale * 100,
    },
  ]
}

const calcAgeData = (audience?: OriginalAudience) => {
  if (!audience) return undefined

  return [
    {
      name: '17세 이하',
      amt: audience.org13 * 100,
    },
    {
      name: '18-24세',
      amt: audience.org18 * 100,
    },
    {
      name: '25-34세',
      amt: audience.org25 * 100,
    },
    {
      name: '35-44세',
      amt: audience.org35 * 100,
    },
    {
      name: '45-54세',
      amt: audience.org45 * 100,
    },
    {
      name: '55-64세',
      amt: audience.org55 * 100,
    },
    {
      name: '65세 이상',
      amt: audience.org65 * 100,
    },
  ]
}

const keyToLabel = (key: string) => {
  switch (key) {
    case 'male13':
      return '-17 남성'
    case 'male18':
      return '18-24 남성'
    case 'male25':
      return '25-34 남성'
    case 'male35':
      return '35-44 남성'
    case 'male45':
      return '45-54 남성'
    case 'male55':
      return '55-64 남성'
    case 'male65':
      return '65- 남성'
    case 'female13':
      return '-17 여성'
    case 'female18':
      return '18-24 여성'
    case 'female25':
      return '25-34 여성'
    case 'female35':
      return '35-44 여성'
    case 'female45':
      return '45-54 여성'
    case 'female55':
      return '55-64 여성'
    case 'female65':
      return '65- 여성'
    default:
      return key
  }
}

export default ChannelAudience

function Meter2() {
  return (
    <>
      <style jsx>
        {`
          .meter2 {
            display: flex;
            flex-direction: column;
            gap: var(--space-2);
          }
          .meter2-track {
            height: 0.5rem;
            background-color: var(--color-background-weak);
            border-radius: var(--radius);
            overflow: hidden;
            flex: auto;
          }
          .meter2-thumb {
            display: block;
            width: 90%;
            height: inherit;
            background: skyblue;
          }
          .meter2-text {
            display: block;
            font-size: 12px;
          }
        `}
      </style>
      <div className="meter2">
        <div className="meter2-track">
          <b className="meter2-thumb" style={{ width: '68%' }}></b>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span className="meter2-text">17세 이하 </span>
          <b className="meter2-text">2.8만 </b>
        </div>
      </div>
    </>
  )
}
