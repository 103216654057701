import { useState } from 'react'
import {
  apiPostBookmarkGroup,
  createKeyBookmarkGroupDetail,
  PostBookmarkGroupRequest,
} from '../api/apis'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import Modal from 'component/Modal'
import TextField from 'component/TextField'

const BookmarkGroupCreateModal = ({
  data,
  closeModal,
}: {
  data?: PostBookmarkGroupRequest
  closeModal: VoidFunction
}) => {
  const queryClient = useQueryClient()

  const [name, setName] = useState(data?.name || '')
  const [shareType, setShareType] = useState<string>(data?.shareType || 'PRIVATE')

  const { mutate: postBookmarkGroup } = useMutation({
    mutationFn: apiPostBookmarkGroup,
    onSuccess: () => {
      if (data?.bookmarkGroupId) {
        const queryKey = createKeyBookmarkGroupDetail(`${data.bookmarkGroupId}`)
        queryClient.invalidateQueries({ queryKey })
      }
    },
  })
  const onClickSave = () => {
    const payload = {
      bookmarkGroupId: data?.bookmarkGroupId,
      name,
      shareType,
    }

    postBookmarkGroup(payload, {
      onSuccess: () => {
        closeModal()
      },
    })
  }

  return (
    <Modal
      title="새 컬렉션 만들기"
      onClose={closeModal}
      primaryAction={{ label: '만들기', onClick: onClickSave }}
      secondaryAction={{ label: '닫기', onClick: closeModal }}
    >
      <TextField
        label="컬렉션 이름"
        name="name"
        value={name}
        onChange={(e: any) => setName(e.target.value)}
      />
    </Modal>
  )
}

export default BookmarkGroupCreateModal
