export const privacyPolicy20240701 = `
## 콜라블 개인정보취급방침
콜라블(이하 “회사”)는 개인정보보호 관한 법률을 준수하고 있으며, 이용자의 개인정보가 보호받을 수 있도록 최선을 다하고 있습니다. 이에 개인정보보호 제30조에 따라 개인정보 처리에 관한 절차 및 기준을 안내하고, 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립•공개합니다.
개인정보처리방침은 관련 법률 및 지침의 변경과 내부 운영방침의 변경에 따라 변경될 수 있습니다.

### 1. 개인정보 수집 및 이용 목적
회사는 이용자의 개인정보를 최소한으로 수집하며, 다음의 목적으로만 활용합니다.

가. 회원관리
- 회원 서비스 제공 및 본인 확인(14세 미만 아동의 경우 법정대리인 동의여부 확인)

나. 서비스 제공을 위한 계약 이행 및 정산
- 콘텐츠 제공, 요금 결제, 물품 배송, 리더 수익 정산
- 문의사항 처리

다. 서비스 개발 및 마케팅 활용
- 이용자의 관심 및 성향 추정에 따른 맞춤형 컨텐츠 추천, 신규 서비스 및 상품 개발, 이벤트 정보 및 광고성 정보 제공, 마케팅 프로모션, 접속 빈도 파악

### 2. 수집하는 개인정보의 항목 및 수집 항목, 방법 및 보유기간
회사는 이용자로부터 개인정보 수집 시에 동의 받은 개인정보 보유기간 내에서 개인정보를 처리 및 이용합니다.

#### 가. 각각의 개인정보 처리사항은 다음과 같습니다.
광고주회원
- 회원가입
    수집항목: 이메일, 비밀번호, 이름, 휴대전화번호, 기업명, CI, DI
    보유기간: 회원탈퇴(서비스 부정 이용 방지를 위하여 탈퇴일로부터 1년동안 암호화 하여 보관(DI) 후 파기) 또는 개인정보 유효기간 도래시 까지 보관
- 회원가입(구글)
    수집항목: 로그인 정보 식별 값, 이메일, 비밀번호, 이름, 휴대전화번호, 기업명, CI, DI
    보유기간: 회원탈퇴(서비스 부정 이용 방지를 위하여 탈퇴일로부터 1년동안 암호화 하여 보관(DI) 후 파기) 또는 개인정보 유효기간 도래시 까지 보관
- 본인 확인/성인 인증
    수집항목: 이름, 휴대전화번호, DI
    보유기간: 회원탈퇴(서비스 부정 이용 방지를 위하여 탈퇴일로부터 1년동안 암호화 하여 보관(DI) 후 파기) 또는 개인정보 유효기간 도래시 까지 보관
- 회원 정보 수정
    수집항목: 프로필 이미지, 이름, 휴대전화번호, 이메일
    보유기간: 회원탈퇴(서비스 부정 이용 방지를 위하여 탈퇴일로부터 1년동안 암호화 하여 보관(DI) 후 파기) 또는 개인정보 유효기간 도래시 까지 보관
- 상품 배송
    수집항목: 이름, 휴대전화번호, 배송지 주소
    보유기간: 재화·서비스 공급완료 및 요금결제·정산 완료시까지
- 상담 접수 시
    수집항목: 이메일, 휴대폰 번호, 단말 기기 OS 버전정보, 인터넷 환경에 대해 고객에 게 제공하는 정보
    보유기간: 3년

크리에이터회원
- 회원가입
    수집항목: 이메일, 비밀번호, 이름, 휴대전화번호, CI, DI
    보유기간: 회원탈퇴(서비스 부정 이용 방지를 위하여 탈퇴일로부터 1년동안 암호화 하여 보관(DI) 후 파기) 또는 개인정보 유효기간 도래시 까지 보관

※ 선택항목은 입력을 하지 않아도 서비스 이용에 제한이 없습니다.
※ 본인 확인 정보
(CI: Connecting Information - 본인확인기관에서 부여하는 개인식별정보)
(DI: Duplication Information - 중복가입을 방지하기 위해 개발된 개인식별정보)

#### 나. 허위 정보 입력 시 회사의 조치
- 이용자는 자신의 정보에 대해 정확성 및 적법성을 보장해야 합니다. 만약 이를 위반하여 타인의 정보를 도용하는 등 각종 방법으로 허위 정보를 입력할 경우 회사는 해당 이용자를 관계법령에 따라 신고할 수 있으며 강제 탈퇴를 시킬 수도 있습니다.

#### 다. 서비스 이용과정에서 수집될 수 있는 정보
- 이용자의 브라우저 종류 및 OS, 접속 IP 정보, 접속로그, 쿠키
- 구매/결제/취소/환불 내역

#### 라. 개인정보의 수집 방법
- 회원가입 및 서비스 이용 과정에서 이용자가 개인정보 수집에 대해 동의하고 입력하는 경우
- 고객센터 상담과정에서 수집
- 온/오프라인 이벤트에서 진행되는 이벤트
- ‘쿠키(cookie)’에 의한 정보 수집
- 외부 업체 서비스 등으로 제3자로부터 제공

### 3. 만 14세 미만 아동의 개인정보 처리에 관한 사항
- 서비스는 만 14세 미만 아동에게 제공되지 않습니다. 만 14세 미만의 아동이 개인정보를 제공하였다고 판단되는 경우, 해당 정보를 지체없이 개인정보를 파기합니다.

### 4. 개인정보의 보유-이용기간 및 파기
#### 가. 회사는 개인정보 처리목적 달성, 이용자의 서비스 해지 요청이 있을 경우 지체없이 해당 개인정보를 파기합니다.
#### 나. 단, 관계법령에 의한 일정기간 보관 의무를 부과하는 경우에는 해당 기간 동안 개인정보를 안전하게 보관합니다.
- 계약 또는 청약철회 등에 관한 기록 (전자상거래법 :5년)
- 대금결제 및 재화 등의 공급에 관한 기록 (전자상거래법 5년)
- 소비자의 불만 또는 분쟁처리에 관한 기록 (전자상거래법 3년)
- 표시/광고에 관한 기록 (전자상거래법 6개월)
- 전자금융 거래에 관한 기록 (전자금융거래법 5년)
- 서비스 방문 기록 (통신비밀보호법 3개월)

#### 다. 개인정보 파기방법은 아래와 같습니다.
- 전자적 파일 형태: 복구 및 재생할 수 없는 기술적 방법을 사용하여 안전하게 삭제
- 종이에 출력된 개인정보: 분쇄하거나 소각하여 파기

### 5. 개인정보의 제3자 제공 및 위탁 내용
#### 가. 회사는 이용자의 개인정보를 사전 동의 없이 제3자에게 제공하지 않습니다.
- 이용자의 개인정보 제공이 필요한 경우, 사전에 이용자에게 ‘개인정보를 제공받는 자, 제공목적, 제공하는 개인정보 항목, 제공받는 개인정보의 보유 및 이용기간’을 고지하고 이에 대해 별도 동의를 구합니다.
- 다만, 관련 법령에서 달리 정하는 경우에는 고객의 동의 없이 개인정보를 제공하는 것이 가능합니다.

#### 나. 회사는 이용자의 외부 제휴사 서비스 이용을 위해 개인정보를 제공하고 있으며, 편리하고 더 나은 서비스 제공을 위해 개인정보 처리 업무를 외부 전문 업체에 위탁하여 운영하고 있습니다.
- 위탁 체결 시 ‘개인정보 보호법’ 제26조에 따라 위탁업무 수행목적 외 개인정보 처리금지, 기술적·관리적 보호조치, 재 위탁 제한, 수탁자에 대한 관리·감독, 손해배상 등 책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다.
- 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본 개인정보 처리방침을 통하여 공개합니다.

#### 위탁 상세 정보
- 본인 인증
    수탁자: KCB(코리아 크레딧 뷰로)
- 신용카드 결제
    수탁자: 토스페이먼츠
- 간편 결제
    수탁자: 토스페이먼츠, ㈜카카오페이, 엔에이치엔페이코㈜, 네이버페이
- 메일 발송
    수탁자: 구글
- 클라우드 IT 인프라 제공
    수탁자: Amazon Web Services (AWS Kor), 네이버클라우드플랫폼
※ 개인정보의 보유 및 이용기간: 회원탈퇴 시 혹은 위탁 계약 종료 시까지
※ 단발성 및 단기간으로 진행되는 위탁에 대한 개인정보 관련 고지는 ‘개인정보처리 처리 위탁(단발성)’에 고지됩니다.

#### 다. 회사는 정부 관계부처가 합동으로 발표한 ‘긴급상황 시 개인정보 처리 및 보호수칙’에 따라 재난, 감염병, 급박한 생명·신체 위험을 초래하는 사건·사고, 급박한 재산 손실 등의 긴급상황이 발생하는 경우 정보주체의 동의 없이 관계기관에 개인정보를 제공할 수 있습니다. 자세한 사항은 개인정보보호 위원회의 가이드라인을 확인하시기 바랍니다.

### 6. 이용자 및 법정대리인의 권리와 그 행사방법
#### 가. 이용자의 권리
- 이용자는 회사에 대해 언제든지 개인정보 열람정정, 삭제처리,정지요구 등의 권리를 행사할 수 있습니다.
- 단, 만 14세 미만 아동에 관한 개인정보 열람 등 요구는 법정대리인이 직접 해야 하며, 만 14세 이상의 미성년자인 이용자는 이용자의 개인정보에 관하여 미성년자 본인이 권리를 행사하거나 법정대리인을 통하여 권리를 행사할 수도 있습니다.

### 7. 개인정보 자동수집 장치의 설치 운영 및 그 거부에 관한 사항
회사는 개인화된 서비스를 제공하기 위하여 ‘쿠키(cookie)’ 를 수집할 수 있습니다.

#### 가. 쿠키(cookie)란?
- 회사의 웹사이트를 운영하는데 이용되는 서버가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC컴퓨터내의 하드디스크에 저장되기도 합니다.
#### 나. 쿠키(Cookie) 사용 목적
- 이용자가의 취향과 관심분야를 파악하여 개인형 맞춤화 된 서비스를 제공하기 위해 사용됩니다.
- 이용자의 디바이스에 저장되어 있는 쿠키의 내용을 읽어 이용자의 환경설정을 유지하고 최적화된 서비스를 제공합니다.
#### 다. 쿠키(Cookie) 설치·운영 및 거부
- 이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서 이용자는 웹 브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다.
- 다만, 쿠키의 저장을 거부할 경우에는 서비스 제공에 어려움이 있을 수 있습니다.
#### 라. 이용자 피해 구제 방법
- 제10조(개인정보에 관한 민원 서비스)의 기재된 내용을 참조하셔서 문의할 수 있습니다.

### 8. 행태정보의 수집·이용·제공 및 거부 등에 관한 사항
#### 가. 회사는 서비스 이용과정에서 정보주체에게 맞춤서비스 제공이나 더 나은 환경의 서비스 및 광고를 제공하기 위하여 행태정보를 수집·이용하고 있습니다.
- 수집하는 행태정보의 항목
    서비스 방문이력, 사용이력, 구매 및 검색이력, 기기정보, 광고식별자, 기타 온라인상 활동정보
- 행태정보 수집 방법
    이용자가 웹사이트를 방문할 때 분석 툴을 통해 자동수집 및 전송
- 행태정보 수집 목적
    서비스 품질 개선을 위한 통계 분석, 맞춤형 서비스 및 혜택 제공, 온라인 맞춤형 광고 제공
- 보유·이용기간 및 이후 정보처리 방법
    최대 18개월 이내 파기(보유기간 이후 파기 또는 조회 불가 처리)

#### 나. 회사는 다음과 같이 온라인 맞춤형 광고 사업자가 행태정보를 수집·처리하도록 허용하고 있습니다.
- 행태정보를 수집 및 처리하려는 광고 사업자
    Google, Meta
- 행태정보 수집 방법
    이용자가 웹사이트를 방문할 때 분석 툴을 통해 자동수집 및 전송
- 수집·처리되는 행태정보 항목
    서비스 방문 이력, 사용이력, 접속 및 가입 경로, 기기 정보, 광고식별자, 기타 온라인상 활동 정보
- 보유·이용기간
    최대 18개월 이내 파기(보유기간 이후 파기 또는 조회 불가 처리)

#### 다. 회사는 온라인 맞춤형 광고 등에 필요한 최소한의 행태정보만을 수집하며, 사상, 신념, 가족 및 친인척관계, 학력·병력, 기타 사회활동 경력 등 개인의 권리·이익이나 사생활을 뚜렷하게 침해할 우려가 있는 민감한 행태정보를 수집하지 않습니다.
#### 라. 회사는 만 14세 미만임을 알고 있는 아동이나 만14세 미만의 아동을 주 이용자로 하는 온라인 서비스로부터 맞춤형 광고 목적의 행태정보를 수집하지 않고, 만 14세 미만임을 알고 있는 아동에게는 맞춤형 광고를 제공하지 않습니다.

### 9. 개인정보보호를 위한 안전성 확보조치
회사는 고객의 개인정보를 처리함에 있어 개인정보가 분실, 도난, 누출, 변조 훼손되지 않도록 안전성 확보를 위하여 기술적, 관리적, 물리적 대책을 강구하고 있습니다.
#### 가. 기술적 대책
- 이용자의 개인정보는 암호화된 통신구간을 이용하여 전송하고, 비밀번호 등 중요 정보는 암호화하여 보관하고 있습니다.
- 백신프로그램을 이용하여 컴퓨터 바이러스에 의해 피해를 방지하기 위한 조치를 취하고 있습니다.
- 해킹 등 외부 침입에 대비하여 취약점 분석 등을 이용하여 보안에 만전을 기하고 있습니다.
#### 나. 관리적 대책
- 개인정보에 대한 접근권한을 최소한의 인원으로 제한하고 있습니다. 또한, 해당 직원에게 보안서약서와 정기적인 교육 및 캠페인을 통하여 정보유출을 사전에 방지하고 있습니다.
- 개인정보의 안전한 처리를 위한 내부 관리계획을 수립하였으며, 이에 대한 이행사항 및 직원의 준수여부를 감시하기 위한 내부절차를 마련하고 있습니다.
#### 다. 물리적 대책
- 전산실 등의 접근 통제

### 10. 개인정보에 관한 민원 서비스
#### 가. 회사는 고객의 개인정보를 보호하고 개인정보와 관련한 불만사항 및 문의를 처리하기 위하여 아래와 같이 관련 부서 및 개인정보보호책임자를 지정하고 있습니다.
#### 나. 기타 개인정보침해에 대한 신고 또는 상담이 필요하신 경우에는 아래 기관으로 문의하시기 바랍니다.

### 11. 개인정보 처리방침의 변경
이 개인정보 처리방침은 2024. 07. 01부터 적용됩니다.
`
