import Box from '@mui/joy/Box'

import { useParams } from 'react-router-dom'
import { apiSearchVideo, SearchVideoParams, useFetchChannel } from '../../../api/apis'
import { getPublishedAt, parseCount } from '../../../util/utils'
import { useQuery } from '@tanstack/react-query'
import queryKeys from '../../../api/queryKeys'
import { MetaTags } from '../../../util/MetaTags'
import ChannelHeader from './ChannelHeader'
import SampleCallout from './SampleCallout'
import QuotaExceededCallout from './QuotaExceededCallout'
import Tabs, { TabPanel, useTab } from '../../../component/tab/Tab'
import ChannelPPLSummary from './ChannelPPLSummary'
import VideoPlaylist from '../../../component/VideoPlaylist'
import _ from 'lodash'
import CustomizedSectionHead from '../../../component/CustomizedSectionHead'
import SimilarChannels from './SimilarChannels'
import ChannelVideo from './ChannelVideo'
import ChannelAudience from './ChannelAudience'
import { useEffect } from 'react'
import { sampleChannelId } from '../../../util/consts'
import { useSignModalContext } from '../../../context/SignModalContext'
import { Option, SearchChannelResponseItem } from '../../../api/types'
import DashboardPageBody from '../../../layout/DashboardPageBody'
import * as React from 'react'

const tabOptions: Option[] = [
  { label: '요약', value: 'all' },
  { label: '동영상', value: 'contents' },
  { label: '시청자 데이터', value: 'audience' },
]

const ChannelDetail = () => {
  const { channelId } = useParams()
  const { actions } = useSignModalContext()
  const { data: channelData } = useFetchChannel(Number(channelId))

  const videoParams = {
    ...getPublishedAt('3M'),
    channelId: Number(channelId),
    sort: 'latest',
  } as SearchVideoParams

  const channelOpenPermitted =
    !!channelData && !channelData.isSample && !channelData.isQuotaExceeded

  const { data: channelVideos } = useQuery({
    queryKey: queryKeys.videoSearch(videoParams),
    queryFn: () => apiSearchVideo(videoParams),
    enabled: channelOpenPermitted,
  })

  useEffect(() => {
    if (!channelData) {
      return
    }

    if (
      (channelData.isSample || channelData.isQuotaExceeded) &&
      Number(channelId) !== sampleChannelId
    ) {
      actions.openSignIn('CHANNEL_DETAIL')
    }
  }, [channelData, actions])

  const [selectedTab, handleTabClick] = useTab(tabOptions[0].value)

  return (
    <DashboardPageBody breadcrumbs={[{ label: 'Channels' }, { label: channelData?.name || '' }]}>
      {channelData && (
        <MetaTags
          title={`채널정보 - ${channelData.name} · 콜라블`}
          description={description(channelData)}
          keywords={keywords(channelData)}
        />
      )}
      <div
        style={{
          maxWidth: 'var(--layout-width-wide)',
          padding: '0 0 4rem',
        }}
      >
        <ChannelHeader channelData={channelData} showFixedHeader />
        <div>
          <SampleCallout isSample={channelData?.isSample} />
          <QuotaExceededCallout isQuotaExceeded={channelData?.isQuotaExceeded} />
          <Tabs tabs={tabOptions} selected={selectedTab} onClick={handleTabClick} />
          <div style={{ height: '1rem' }} />
          <TabPanel value={tabOptions[0].value} selected={selectedTab}>
            <>
              <ChannelPPLSummary channelData={channelData} channelVideos={channelVideos?.videos} />
              <Box sx={{ my: '1rem' }}>
                <VideoPlaylist videos={_.take(channelVideos?.videos, 8)} showChannelAvatar={true} />
              </Box>
              <CustomizedSectionHead title="유사한 채널" />
              <SimilarChannels channelId={Number(channelId)} permitted={channelOpenPermitted} />
            </>
          </TabPanel>
          <TabPanel value={tabOptions[1].value} selected={selectedTab}>
            <ChannelVideo channelId={Number(channelId)} permitted={channelOpenPermitted} />
          </TabPanel>
          <TabPanel value={tabOptions[2].value} selected={selectedTab}>
            <Box sx={{ mt: '1rem' }}>
              <ChannelAudience channelId={Number(channelId)} />
            </Box>
          </TabPanel>
        </div>
      </div>
    </DashboardPageBody>
  )
}

const description = (channel: SearchChannelResponseItem) => {
  return `유튜브 채널 '${channel.name}'의 상세 분석. ${channel.category}의 인기 크리에이터. 구독자 ${parseCount(channel.followerCount)}명. 최근 인게이지먼트 통계, 유료광고 이력, PPL 상품 정보, 시청자 분석까지 제공. 콜라블에서 유튜브 마케팅 협업 채널을 찾아보세요.`
}

const keywords = (channel: SearchChannelResponseItem) => {
  return `${channel.name}, ${channel.category} 유튜버, 유튜브 채널 분석, 인게이지먼트 통계, 유료광고 이력, PPL 정보, 시청자 분석, 인플루언서 마케팅, 채널 성과, 브랜드 콜라보레이션, 유튜브 마케팅 인사이트, 콘텐츠 크리에이터 데이터`
}

export default ChannelDetail
