import { categoryAssets } from 'util/consts'
import Group from './Group'
import { useNavigate } from 'react-router-dom'
import { CategoryButton } from './CategoryButton'

interface Props {
  selected: string
  actionType: 'navigate' | 'updateQuery'
  updateQuery?: any // TODO
  showAll?: boolean
}

function CategoryFilter({ selected, actionType, updateQuery, showAll }: Props) {
  const navigate = useNavigate()

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    const { value } = event.currentTarget as HTMLButtonElement

    if (actionType === 'navigate') {
      navigate(`/videos?categoryCode=${value}`)
    } else {
      updateQuery('categoryCode', value)
    }
  }

  return (
    <Group>
      {categoryAssets.map(category => {
        if (category.categoryCode === '9900') return null
        if (showAll === false && category.categoryCode === '') return null
        return (
          <CategoryButton
            category={category}
            selected={selected === category.categoryCode}
            onClick={handleClick}
          />
        )
      })}
    </Group>
  )
}

export default CategoryFilter
