import Avatar from 'component/Avatar'
import { ChannelOwnershipStatus, RequestChannelOwnershipResponse } from '../../../api/apis'
import Button from '@mui/joy/Button'
import { Chip } from '@mui/joy'

interface Props {
  ownership?: RequestChannelOwnershipResponse
  showBadge: boolean
  showGoToChannelButton?: boolean
  onClick?: () => void
}

const ManagingChannelItem = ({ ownership, showBadge, showGoToChannelButton, onClick }: Props) => {
  const channelName = ownership?.channelName
  const channelUrl = ownership?.channelUrl
  const email = ownership?.email
  const status = ownership?.status
  const channelThumbnail = ownership?.channelThumbnail

  const background =
    status === 'ACTIVATED' ? 'var(--color-background)' : 'var(--color-background-weak)'
  const border = status === 'ACTIVATED' ? '1px solid var(--color-border)' : 'none'
  const cursor = onClick ? 'pointer' : undefined

  return (
    <div
      style={{
        display: 'flex',
        padding: '0.5rem 1rem',
        gap: '0.5rem',
        alignItems: 'center',
        background: background,
        border: border,
        borderRadius: '1rem',
        cursor: cursor,
      }}
      onClick={onClick}
    >
      <Avatar size="md" src={channelThumbnail} />
      {channelName && (
        <>
          <span style={{ fontWeight: 'bold' }}>{channelName}</span>
          {/*<VerifiedBadge notVerified={false} />*/}
        </>
      )}
      {!channelName && (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <span style={{ fontWeight: 'bold' }}>{channelUrl}</span>
          <span
            style={{ fontSize: '0.875rem', color: 'var(--color-text-weak)', marginTop: '0.25rem' }}
          >
            {email}
          </span>
        </div>
      )}
      {showGoToChannelButton && status === 'ACTIVATED' && (
        <a href={`/channels/${ownership?.channelId}`} target="_blank">
          <Button variant={'soft'}>내 채널 가기</Button>
        </a>
      )}
      {showBadge && status && <Chip sx={{ ml: 'auto' }}>{labelByStatus(status)}</Chip>}
    </div>
  )
}

const labelByStatus = (status: ChannelOwnershipStatus) => {
  switch (status) {
    case 'ACTIVATED':
      return '인증됨'
    case 'VERIFIED':
      return '승인대기중'
    case 'REJECTED':
      return '거절됨'
    default:
      return status
  }
}

export default ManagingChannelItem
