import Box from '@mui/joy/Box'
import Breadcrumbs from '@mui/joy/Breadcrumbs'
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded'
import Link from '@mui/joy/Link'
import HomeRoundedIcon from '@mui/icons-material/HomeRounded'
import Typography from '@mui/joy/Typography'
import * as React from 'react'
import { MetaTags } from '../../../util/MetaTags'
import Avatar from '../../../component/Avatar'
import Title from '../../../component/texts/Text'
import Group from '../../../component/Group'
import _ from 'lodash'
import ChannelCompareTop3 from './ChannelCompareTop3'
import Tile from '../../../component/Tile'
import BrandPPLCompareChart from './BrandPPLCompareChart'
import CustomizedSectionHead from '../../../component/CustomizedSectionHead'
import { parseCount } from '../../../util/utils'
import CustomizedSelect from '../../../component/CustomizedSelect'
import { videoSorts } from '../../../util/consts'
import VideoPlaylist from '../../../component/VideoPlaylist'
import { apiSearchVideo, SearchVideoParams, useBrandDetail } from '../../../api/apis'
import { useQuery } from '@tanstack/react-query'
import queryKeys from '../../../api/queryKeys'
import { useParams, useSearchParams } from 'react-router-dom'
import DashboardPageBody from '../../../layout/DashboardPageBody'
import { Chip } from '@mui/joy'
import { BrandResponse } from '../../../api/types'

const BrandDetail = () => {
  const { brandId } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()

  const sort = searchParams.get('sort') || 'view'

  const searchVideoQueryParams: SearchVideoParams = {
    categoryCode: '',
    brandId: Number(brandId),
    adOnly: true,
    page: 0,
    sort,
  }

  const { data: brand } = useBrandDetail(brandId)
  const { data: videos } = useQuery({
    queryKey: queryKeys.videoSearch(searchVideoQueryParams),
    queryFn: () => apiSearchVideo(searchVideoQueryParams),
  })

  const updateQuery = (key: string, value: string) => {
    searchParams.set(key, value)
    setSearchParams(searchParams)
  }

  return (
    <DashboardPageBody
      breadcrumbs={[{ label: 'Brands', link: '/brands' }, { label: brand?.brandName || '' }]}
    >
      {brand && (
        <MetaTags
          title={`유료광고 성과 - ${brand.brandName} · 콜라블`}
          description={description(brand)}
          keywords={keywords(brand)}
        />
      )}
      <div style={{ width: '100%', maxWidth: 'var(--layout-width-wide)' }}>
        <header
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            gap: '1rem',
            padding: '0 0 2rem 0',
            borderRadius: 'var(--radius)',
          }}
        >
          <Avatar variant="brand" size="lg" src={brand?.logoUrl || ''} type="space" />
          <Title level="1">{brand?.brandName || ''}</Title>
          <Group>
            {_.chain(videos?.videos)
              .map('category')
              .uniq()
              .value()
              .map(category => (
                <Chip>{category}</Chip>
              ))}
          </Group>
        </header>
        <Box sx={{ mb: '3rem' }}>
          <div>
            <ChannelCompareTop3 videos={videos?.videos} />
          </div>
          <Tile style={{ height: '300px', margin: '2rem 0 0', padding: '1rem 0' }}>
            <BrandPPLCompareChart videos={videos?.videos} />
          </Tile>
        </Box>
        <CustomizedSectionHead
          title={`최근 진행 PPL ${parseCount(videos?.videos.length)}건`}
          slot={
            <CustomizedSelect
              options={videoSorts}
              name="sort"
              value={sort}
              onChange={event => updateQuery(event.currentTarget.value, 'view')}
            />
          }
        />
        <VideoPlaylist showChannelAvatar videos={videos?.videos} />
      </div>
    </DashboardPageBody>
  )
}

const description = (brand: BrandResponse) => {
  return `${brand.brandName}의 유튜브 유료광고(PPL) 성과 분석. 유튜버 협업 현황, 비디오 리스트, 조회수 그래프를 확인할 수 있습니다. 최근 광고 상품과 인기 영상을 분석해보세요. 효과적인 유튜브 마케팅 전략 수립에 활용해보세요.`
}

const keywords = (brand: BrandResponse) => {
  return `${brand.brandName} 유료광고 성과, 유튜브 PPL 분석, 브랜드-유튜버 협업, 광고 비디오 리스트, 조회수 그래프, 유튜브 광고 상품, 인기 영상 분석, 유튜브 마케팅 전략, 인플루언서 마케팅, 브랜드 콜라보레이션, 광고 효과 측정, 콘텐츠 성과 분석, 광고 사례`
}

export default BrandDetail
